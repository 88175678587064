<template>
<div class="table-container">

    <div class="table-responsive datatable-vue">
        <b-table show-empty stacked="md" @change="emitChange" :items="state.tableData" :fields="state.fields">
            <template #cell(id)="row">
                {{ row.index+1 }}
            </template>

            <template #cell(name)="row">
                <!-- <input class="form-control" type="text" placeholder="Enter item" v-model="row.item.name"> -->
                <input class="form-control" type="text" placeholder="Enter item" list="quotation_items" @change="getItems(row.item.name, row.index)" v-model="row.item.name" />
            </template>

            <template #cell(description)="row">
                <input class="form-control" type="text" placeholder="Enter description" v-model="row.item.description">
            </template>

            <template #cell(action)="row">

                <button @click="removeItem(row.item.id)" class="btn btn-primary" type="submit">Delete</button>
            </template>
        </b-table>
    </div>

    <div>
        <b-button variant="primary" @click="addItem">Add Item</b-button>
    </div>
</div>
</template>

<script>
import {
    computed,
    defineComponent,
    reactive
} from "vue";

export default defineComponent({
    name: "item-table",

    props: ["tableData"],

    setup(props, {
        emit
    }) {
        const state = reactive({
            currentId: computed(() => state.tableData.length),
            tableData: props.tableData || [{
                id: 1,
                name: null,
                description: null,
           
            }, ],
            fields: [{
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: 'Item',
                    sortable: false
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: false
                },

                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            quotation_items: []
        });

       
      

        // Add item
        const addItem = () => {
            state.tableData.push({
                id: state.currentId + 1,
                name: null,
                description: null,

            });
        };

        // Get Item
        const getItems = (name, id) => {
            console.log(name)
            const temp = {}
            state.quotation_items.filter((item) => {
                if (item.name == name) {
                    state.tableData[id].name = item.name
                    state.tableData[id].description = item.description

                }
            });
            calculateId();
            emitChange();
        };

        // Remove Item
        const removeItem = (id) => {
            const temp = state.tableData.filter((item) => item.id !== id);
            state.tableData = temp;
            calculateId();
            emitChange();
        };

        // Calculate ID
        const calculateId = () => {
            let id = 0;
            state.tableData.map((item) => {
                id++;
                item.id = id;
                return item;
            });
        };

        // Emit change
        const emitChange = () => {
            // console.log(state.tableData)
            emit("itemsUpdated", state.tableData);
        };

        return {
            state,
            addItem,
            getItems,
            removeItem,
            emitChange,
        };
    },

    mounted() {
        this.$store.dispatch("item/fetchItem").then(result => {
            this.state.quotation_items = result.data
        })
    }
})
</script>

<style scoped>
.table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
</style>
