<template>
<div>
    <Breadcrumbs main="Careers" submain="Apply for Job" title="Job Details" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5">
                <div class="card p-3" style="width: 100%;">
                    <div class="task-board-task-scroll">
                        <div style="display: flex; flex-direction: column; height: 100%; overflow-y: auto; padding-right: 12px;">
                            <div>
                                <div style="display:flex; flex-direction: column; align-items: center; justify-content: center;" class="w-full">
                                    <img style="width: 100%; border-radius: 0.75rem; object-fit: cover; background-size: cover;" :src="host+job.banner_image" class="image-height">
                                </div>

                                <div style="margin-top: 25px; display:flex; flex-direction: column; align-items: start; justify-content: left; text-align: center;">
                                    <h5 style="text-align: left;">{{ job.title }}</h5>
                                    <h5 class="mt-2">
                                        <b-badge pill variant="success">{{ job.position }}</b-badge>
                                    </h5>
                                    <div class="mt-1 d-flex">
                                        <h5>
                                            <b-badge pill variant="primary">Vacancy: {{ job.vacancies }}</b-badge>
                                        </h5>
                                        <h5 style="margin-left: 12px;">
                                            <b-badge pill variant="info">Applied: {{ job.applied }}</b-badge>
                                        </h5>
                                    </div>
                                </div>

                                <div style="margin-top: 15px; display:flex; flex-direction: column; align-items: start; justify-content: left;; text-align: center; font-size: medium;">
                                    <h7>Post Date: {{ job.opening }}</h7>
                                    <h7>Deadline: <span style="color: red;">{{ job.deadline }}</span></h7>
                                </div>
                            </div>

                            <div style="margin-top: 20px;">
                                <div v-if="showDescription">
                                    <h7 v-if="job.description.length < 450">{{ job.description }}</h7>
                                    <h7 v-else>{{ job.description.substring(0, 450) }}</h7>
                                </div>
                                <div v-else class="pb-4 lg:pb-6">
                                    <div v-html="job.description"></div>
                                </div>
                                <div v-if="job.description.length > 450">
                                    <b-button v-if="showDescription" variant="primary" size="sm" @click="displayDescription" style="margin-top: 20px;">See More</b-button>
                                    <b-button v-else variant="primary" size="sm" @click="closeDescription">See Less</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="task-board-task-scroll">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label>First Name</label>
                                                <input type="text" class="form-control" id="name" v-model="form.first_name">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label>Last Name</label>
                                                <input type="text" class="form-control" id="name" v-model="form.last_name">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input type="email" class="form-control" id="name" v-model="form.email">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label>Phone</label>
                                                <input type="text" class="form-control" id="name" v-model="form.phone">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label>Portfolio link</label>
                                                <input type="text" class="form-control" id="name" v-model="form.portfolio" placeholder="https://wpportfolio.net/....">
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label>Facebook link</label>
                                                <input type="text" class="form-control" id="name" v-model="form.facebook" placeholder="https://www.facebook.com/....">
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label>LinkedIn link</label>
                                                <input type="text" class="form-control" id="name" v-model="form.linkedIn" placeholder="https://www.linkedin.com/....">
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="row">

                                        <div class="col-sm-12">
                                            <label>Upload CV</label>
                                            <div class="input-group mb-3">
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" @change="uploadFile()">
                                                </div>
                                            </div>
                                        </div>

                                    </div> -->
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Upload CV</label>
                                                <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." @change="uploadAttachFile"></b-form-file>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" @click="onSubmit()" class="btn btn-primary">Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
var primary = localStorage.getItem('primary_color') || '#FF0006';
var secondary = localStorage.getItem('secondary_color') || '#818284';
import {
    myHost
} from "../../../_helpers/host";
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
export default {
    data() {
        return {
            selected: 'a',
            host: myHost.host,
            options: [{
                    value: 'a',
                    text: 'First Option'
                },
                {
                    value: 'b',
                    text: 'Selected Option'
                },
                {
                    value: 'c',
                    text: 'Third Option'
                },
            ],
            form: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                portfolio: '',
                linkedIn: '',
                facebook: '',
                cv: null,
                job: '',
                // current_address: '',
                // current_company: '',
                // expected_salary: '',
                // current_salary: '',
            },

            search_text: '',
            selected_job: null,
            notes: '',
            tag_a_manager: null,

            managers: [{
                    value: null,
                    text: 'Tag a Manager'
                },
                {
                    value: 'a',
                    text: 'A'
                },
                {
                    value: 'b',
                    text: 'B'
                }
            ],

            showDescription: true
        };
    },

    methods: {
        onSubmit() {
            this.form.job = this.$route.params.id

            this.$store.dispatch("job/addApplicant", this.form).then(response => {
                // console.log(response)
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                        this.form.first_name = '',
                        this.form.last_name = '',
                        this.form.email = '',
                        this.form.phone = '',
                        this.form.portfolio = '',
                        this.form.linkedIn = '',
                        this.form.facebook = '',
                        this.form.cv = null,
                        this.form.job = ''

                        // setTimeout(() => {
                        //     this.$router.push({
                        //         name: 'home'
                        //     })
                        // }, 2000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
        uploadAttachFile(e) {
            this.form.cv = e.target.files[0]
        },
        displayDescription() {
            this.showDescription = false
        },

        closeDescription() {
            this.showDescription = true
        }
    },
    computed: {
        ...mapState({
            job: state => state.job.job_details,

        }),
    },
    mounted() {
        this.$store.dispatch("job/fetchJobDetails", this.$route.params.id)
    },
}
</script>

<style scoped>
.image-height {
    height: 310px;
}

.applicant-view {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 25px;
}

.task-board-task-scroll {
    position: relative;
    height: 75.5vh;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media screen and (max-width: 1680px) {
    .image-height {
        height: 270px;
    }

    .applicant-view {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}

@media screen and (max-width: 991px) {
    .image-height {
        height: 290px;
    }

    .applicant-view {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 20px;
    }

    .task-board-task-scroll {
        position: relative;
        height: 100%;
    }
}

@media screen and (max-width: 730px) {
    .applicant-view {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}

@media screen and (max-width: 645px) {
    .image-height {
        height: 260px;
    }
}

@media screen and (max-width: 430px) {
    .image-height {
        height: 240px;
    }

    .applicant-view {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 20px;
    }
}
</style>
