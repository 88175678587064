<template>
<div>
    <Breadcrumbs main="HR" title="Asset Create" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Asset Name</label>
                                        <input class="form-control" type="text" placeholder="Enter asset name" v-model="asset.name">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Amount</label>
                                        <input class="form-control" type="number" placeholder="Enter asset amount" v-model="asset.amount">
                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select class="form-control digits" v-model="asset.status">
                                            <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Manufacture Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="asset.manufacture_date"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Asset Type</label>

                                        <select class="form-control digits" v-model="asset.asset_type">
                                            <option v-for="(status, index) in type" :key="index" :value="status.id">{{ status.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Purchase Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="asset.purchase_date"></datepicker>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <!-- <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Warranty</label>
                                        <div class="input-group">

                                            <input class="form-control" type="number" v-model="asset.warranty">
                                            <select class="custom-select" v-model="asset.warranty_type" @change="calculateWarrantyEnd()">
                                                <option v-for="(warranty, index) in warrantyUnit" :key="index">{{ warranty }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Warranty End</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="asset.warranty_end"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Items</label>
                                        <ItemTable style="margin-bottom: 10px;"  @itemsUpdated="updateItems" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col">
                                    <div class="form-group">
                                        <label>Upload Photo</label>
                                        <div class="flex items-center">
                                            <!-- <img v-if="asset.image" style="height: 60px; width: 100px;" :src="asset.image"> -->
                                            <input :class="asset.image ? 'ml-0' : 'ml-0'" type="file" accept="image/*" @change="uploadFile">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Upload Attachment</label>
                                        <b-form-file v-model="asset.asset_attachment" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="uploadAttachment"></b-form-file>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Asset Details</label>
                                        <b-form-textarea id="textarea-auto-height" placeholder="Enter asset details..." v-model="asset.details" rows="4" max-rows="13"></b-form-textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                        <router-link to="/hr/asset-management/asset/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from "moment";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

import ItemTable from "./item-table.vue";

import {
    mapState
} from 'vuex';
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        ItemTable
    },

    data() {
        return {
            asset: {
                name: '',
                amount: null,
                purchase_date: null,
                // warranty: '',
                // warranty_type: 'YEAR',
                image: null,
                asset_type: null,
                details: '',
                manufacture_date: '',
                warranty_end: '',
                status: '',
                asset_attachment: null,
                specification: [],
            },

            warrantyUnit: ['YEAR', 'MONTH'],

            statuses: ['PENDING', 'APPROVED', 'RETURNED'],
        };
    },

    // watch: {
    //     'asset.purchase_date': function () {
    //         this.calculateWarrantyEnd();
    //     },

    //     'asset.warranty': function () {
    //         this.calculateWarrantyEnd();
    //     },

    //     'warrantyUnit': function () {
    //         this.calculateWarrantyEnd();
    //     }
    // },

    mounted() {
        this.$store.dispatch("asset/fetchAssetType")
    },

    computed: {
        ...mapState({
            type: state => state.asset.asset_type,
        })
    },

    methods: {
        updateItems(data) {
            if (data) {
                this.asset.specification = data;
            }
        },

        // calculateWarrantyEnd() {
        //     if (this.asset.purchase_date && this.asset.warranty !== '') {
        //         const purchaseDate = moment(this.asset.purchase_date);
        //         const years = this.asset.warranty_type === 'YEAR' ? this.asset.warranty : 0;
        //         const months = this.asset.warranty_type === 'MONTH' ? this.asset.warranty : 0;
        //         const warrantyEnd = purchaseDate.clone().add(years, 'YEAR').add(months, 'MONTH');
        //         this.asset.warranty_end = warrantyEnd.format('YYYY-MM-DD');
        //     }
        // },

        uploadAttachment(e) {
            this.asset.asset_attachment = e.target.files[0];
        },

        uploadFile(e) {
            this.asset.image = e.target.files[0];
        },

        onSubmit(event) {
            if (this.asset.warranty_end !== null) {
                this.asset.warranty_end = moment(String(this.asset.warranty_end)).format("YYYY-MM-DD");
            }
            if (this.asset.manufacture_date !== null) {
                this.asset.manufacture_date = moment(String(this.asset.manufacture_date)).format("YYYY-MM-DD");
            }
            if (this.asset.purchase_date !== null) {
                this.asset.purchase_date = moment(String(this.asset.purchase_date)).format("YYYY-MM-DD");
            }
            this.$store.dispatch("asset/addAsset", this.asset).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.asset.name = '',
                        this.asset.amount = '',
                        this.asset.purchase_date = null,
                        // this.asset.warranty = '',
                        this.asset.manufacture_date = null,
                        this.asset.warranty_end = null,
                        this.asset.status = '',
                        this.asset.image = null,
                        this.asset.asset_attachment = null,
                        setTimeout(() => {
                            this.$router.push('/hr/asset-management/asset/list/1/10')
                        }, 3000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

        }
    }
};
</script>
