<template>
<div>
    <Breadcrumbs main="HR" title="Designation Wise Permissions" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                  <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                              <h5>Designation: {{designation_detail.title }}</h5>

                              <p style="font-weight: bold">Department: {{designation_detail.department.name}} </p> <br>
                            </b-col>
                        </b-row>

                    <b-row>
                      <b-col xl="12">
                        <h5>Permissions</h5>
                        <div class="row">
                          <div class="col-3" ></div>
                          <div class="col-6" >
                             <div v-for="feature in designation_detail.feature">

                            <span style="font-weight: bold">
                              {{feature.title}} </span>

                            <div class="row" v-for="permission in feature.permissions" >
                              <br><br>
                              <div class="col-1"></div>
                              <div class="col-8">{{permission.title}}</div>
                              <div class="col-3">
                                <div class="media-body text-right icon-state switch-outline">
                                  <label class="switch">
                                    <input type="checkbox" :checked="permission.status"  @click="togglePermission(permission.id)"><span class="switch-state bg-primary"></span>
                                  </label>
                                </div>
<!--                                <b-badge pill :variant="permission.status?'success':'danger'" @click="togglePermission(permission.id)">{{permission.status?'Active':'Inactive'}}</b-badge>-->

                               </div>




                            </div>
                            <br>


                          </div>

                          </div>
                          <div class="col-3" ></div>
                        </div>




                      </b-col>
                    </b-row>



                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->


</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {
    mapState
} from 'vuex';
import designationDetails from "./designationDetails.vue";

export default {
    components: {

    },

    data() {
        return {

        };
    },

  computed: {


    ...mapState('designation', ["designation_detail", "designationLoading","designationError", "submitError", "submitSuccess", "submitLoading"]),

  },
    created() {
        this.$store.dispatch("designation/fetchDesignationDetails", this.$route.params.id).then(() => {

        });
    },

    methods: {
      togglePermission(permission_id){
        this.$store.dispatch("designation/toggleDesignationPermission", {designation:this.$route.params.id, permission:permission_id}).then(() => {

        });
      }


    }
};
</script>
