<template>
    <div>
        <Breadcrumbs main="Job" title="Candidate Details"/>

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card-container">

                        <div class="card p-3 card-one-three"> 
                            <div>
                                <div style="display:flex; flex-direction: column; align-items: center; justify-content: center;">
                                    <img style="height: 100px; width: 100px; border-radius: 100%;" :src="img1">
                                </div>

                                <div style="margin-top: 15px; display:flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                                    <h5>{{ candidate_details.user.first_name }} {{ candidate_details.user.last_name }}</h5>
                                    <h7>{{ candidate_details.job.title }} | {{ candidate_details.job.position }} | {{ candidate_details.job.job_type }}</h7>

                                    <div style="margin-top: 30px;">
                                        <div>
                                            <b-form-select variant="primary" v-model="selected" :options="options"></b-form-select>
                                        </div>
                                    </div>
                                    <!-- <button class="btn btn-primary" type="submit" style="margin-top: 25px;">Task Sent</button> -->
                                </div>
                            </div>

                            <div class="d-flex flex-column" style="gap: 20px; margin-top: 32px;">
                                <div class="d-flex" style="gap: 10px; align-items: center;">
                                    <div class="profileIcon">
                                        <b-icon icon="envelope-open-fill" style="margin: 10px"></b-icon>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <h7>Email Address</h7>
                                        <h7 style="font-weight: 600;">{{ candidate_details.user.email }}</h7>
                                    </div>
                                </div>

                                <div class="d-flex" style="gap: 10px; align-items: center;">
                                    <div class="profileIcon">
                                        <b-icon icon="telephone-fill" style="margin: 10px;"></b-icon>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <h7>Phone No.</h7>
                                        <h7 style="font-weight: 600;">{{ candidate_details.phone }}</h7>
                                    </div>
                                </div>

                                <div class="d-flex" style="gap: 10px; align-items: center;">
                                    <div class="profileIcon">
                                        <b-icon icon="calendar2-check-fill" style="margin: 10px;"></b-icon>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <h7>Date of Application</h7>
                                        <h7 style="font-weight: 600;">{{ candidate_details.apply_date }}</h7>
                                    </div>
                                </div>
                            </div>

                            <div style="margin-top: 32px; display:flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                                <div style="display:flex; flex-direction: row; gap: 15px; align-items: center;">
                                    <a :href="candidate_details.facebook" target="_blank">
                                        <div class="socialIcon">
                                            <b-icon icon="facebook" style="margin: 5px"></b-icon>
                                        </div>
                                    </a>

                                    <a :href="candidate_details.linkedIn" target="_blank">
                                        <div class="socialIcon">
                                            <b-icon icon="linkedin" style="margin: 5px;"></b-icon>
                                        </div>
                                    </a>

                                    <a :href="candidate_details.portfolio" target="_blank">
                                        <div class="socialIcon">
                                            <b-icon icon="globe" style="margin: 5px"></b-icon>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                      
                        <div class="card p-3 card-two-view card-two">
                            <div style="height: 1050px;"> 
                                <iframe style="width: 100%; height: 100%; border: none; border-radius: 0.5rem;" :src="pdfUrl" allowFullScreen></iframe>
                            </div>
                        </div>

                        <div class="card p-3 card-one-three">
                            <div>
                                <h5>Salary</h5>
                            </div>

                            <div class="d-flex flex-column" style="gap: 15px;">
                                <div class="salary d-flex flex-column">
                                    <h7>Current Salary</h7>
                                    <!-- <h7 style="color: blue; font-size: large; margin-top: 4px;">{{ applicant.current_salary}}</h7> -->
                                </div>

                                <div class="salary d-flex flex-column">
                                    <h7>Expected Salary</h7>
                                    <!-- <h7 style="color: blue; font-size: large; margin-top: 4px;">{{ applicant.expected_salary}}</h7> -->
                                </div>

                                <div style="margin-top: 20px;">
                                    <h5>Note</h5>
                                    <b-form-textarea
                                        style="margin-top: 4px;"
                                        v-model="notes"
                                        placeholder="Add a note here"
                                        rows="5"
                                    ></b-form-textarea>
                                </div>

                                <div class="form-group">
                                    <b-form-select v-model="tag_a_manager" :options="managers"></b-form-select>
                                </div>
                            </div>

                            <div style="gap:15px;display: flex; justify-content: end;">
                                <button type="button" class="btn btn-danger">Cancel</button>
                                <button type="button" class="btn btn-primary">Save</button>
                            </div>
                        </div>

                        <div class="card p-3 card-two-mobile-view card-two">
                            <div style="height: 550px;"> 
                                <iframe style="width: 100%; height: 100%; border: none; border-radius: 0.5rem;" :src="pdfUrl" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import img1 from "../../../assets/images/user/3.jpg";
var primary = localStorage.getItem('primary_color') || '#FF0006';
var secondary = localStorage.getItem('secondary_color') || '#818284';
import {
    mapState
} from 'vuex';

export default {
    data() {
      return {
            selected: 'a',
            host : "https://api-v2.smicee.com",
            options: [
                { value: 'a', text: 'First Option' },
                { value: 'b', text: 'Selected Option' },
                { value: 'c', text: 'Third Option' },
            ],

            img1: img1,
            
            search_text: '',
            selected_job: null,
            notes:'',
            tag_a_manager:null,

            managers: [
                { value: null, text:'Tag a Manager' },
                { value: 'a', text: 'A' },
                { value: 'b', text: 'B' }
            ]
        };
    },
    computed: {
        ...mapState({
            candidate_details: state => state.job.applicant_details,
            
        }),
        pdfUrl() {
            return this.host + this.candidate_details.cv;
        }
    },
    mounted() {
        this.$store.dispatch("job/fetchApplicantDetails", this.$route.params.id)
    },
}
</script>

<style scoped>
.card-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.card-one-three {
    width: 25%;
}

.card-two {
    width: 50%;
}

.card-two-view {
    display: flex;
}

.card-two-mobile-view {
    display: none;
}

.applicant-view {
    display: grid; 
    grid-template-columns: repeat(5, minmax(0, 1fr)); 
    gap: 25px;
}

.field-width {
    width: 260px;
}

.profileIcon {
    color: #5d80f1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7794f32f;
    border-radius: 9999px;
    height: 35px;
    width: 35px;
}

.socialIcon {
    color: #5d80f1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7794f32f;
    border-radius: 9999px;
    height: 40px;
    width: 40px;
    font-size: larger;
}

.salary {
    padding: 10px;
    color: black;
    background-color: #7794f32f;
    border:1px solid #eeeeee;
    border-radius: 5px;
    font-weight: 500;
}

@media screen and (max-width: 1536px) {
    .applicant-view {
        display: grid; 
        grid-template-columns: repeat(4, minmax(0, 1fr)); 
        gap: 25px;
    }
}

@media screen and (max-width: 1366px) {
    .applicant-view {
        display: grid; 
        grid-template-columns: repeat(3, minmax(0, 1fr)); 
        gap: 32px;
    }

    .card-container {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }

    .card-one-three {
        width: 100%;
    }

    .card-two {
        width: 100%;
    }

    .card-two-view {
        display: none;
    }

    .card-two-mobile-view {
        display: flex;
    }
}

@media screen and (max-width: 1198px) {
    .applicant-view {
        display: grid; 
        grid-template-columns: repeat(4, minmax(0, 1fr)); 
        gap: 20px;
    }
}

@media screen and (max-width: 1050px) {
    .applicant-view {
        display: grid; 
        grid-template-columns: repeat(3, minmax(0, 1fr)); 
        gap: 20px;
    }
}

@media screen and (max-width: 750px) {
    .field-width {
        width: 210px;
    }
}

@media screen and (max-width: 730px) {
    .applicant-view {
        display: grid; 
        grid-template-columns: repeat(2, minmax(0, 1fr)); 
        gap: 20px;
    }
}

@media screen and (max-width: 645px) {
    .field-width {
        width: 180px;
    }
}

@media screen and (max-width: 575px) {
    .field-width {
        width: 100%;
    }
}

@media screen and (max-width: 430px) {
    .applicant-view {
        display: grid; 
        grid-template-columns: repeat(1, minmax(0, 1fr)); 
        gap: 20px;
    }
}
</style>
  