<template>
<div>
    <Breadcrumbs main="Procurement" title="RFP Form" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;">
                            <div style="display: flex; align-items: center;">
                                <h5>RFP Details</h5>
                            </div>
                            <div>
                                <!-- <button class="btn btn-primary" type="submit">
                                    <router-link :to="'/procurement/rfp/edit/' + this.$route.params.id" style="display: flex; gap: 12px; align-items: center; color: white;">
                                        <i class="fa fa-light fa-pencil"></i>
                                        <h7>Edit RFP</h7>
                                    </router-link>
                                </button> -->
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">

                                <div slot="with-padding">
                                    <div class="form theme-form">

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input class="form-control" type="text" placeholder="Enter rfp title" v-model="rfp.title" disabled>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Category</label>
                                                    <select class="form-control digits" v-model="rfp.rfp_category" disabled>
                                                        <option v-for="(item, index) in items" :key="index" :value="item.id">{{ item.title }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Status</label>
                                                    <select class="form-control digits" v-model="rfp.status" disabled>
                                                        <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Deadline</label>
                                                    <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="rfp.deadline" disabled></datepicker>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <label>Details</label>
                                                    <textarea class="form-control" id="textarea-auto-height" placeholder="Enter short description..." rows="5" max-rows="8" v-html="rfp.details" disabled></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="row">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label>Short Description</label>
                                                    <ckeditor :editor="editor" placeholder="Enter short description..." v-model="rfp.details" disabled></ckeditor>

                                                </div>
                                            </div>
                                        </div> -->

                                     

                                    </div>
                                </div>

                                <div class="">
                                    <div class="d-flex justify-content-between ">
                                        <h6 class="my-2">Supporting Documents</h6>

                                    </div>

                                    <b-table sticky-header :items="this.rfp.rfp_attachment" :fields="Attachment" head-variant="light">
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>

                                        <template #cell(title)="data">
                                            <a :href="host+data.item.rfp_attachment" target="_blank">{{ data.item.rfp_attachment }}</a>
                                        </template>

                                    </b-table>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div>
                                    <h5>{{ form_data.form_name }}</h5>
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <div style="display: flex; flex-direction: column;">
                                                <div class="row">
                                                    <div class="col-md-12 grid-style">
                                                        <div v-for="(fo, index) in form_data.submission_form" :key="index">

                                                            <div>
                                                                <span>{{ fo.data_label }}</span>
                                                                <span>
                                                                    <div v-if="fo.data_type === 'filefield'">
                                                                        <div>
                                                                            <b-form-file placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="uploadFile($event, fo)"></b-form-file>
                                                                        </div>
                                                                    </div>
                                                                    <div v-else-if="fo.data_type === 'textfield'">
                                                                        <div>
                                                                            <b-form-input v-model="fo.data_value" type="text"></b-form-input>
                                                                        </div>
                                                                    </div>
                                                                    <div v-else-if="fo.data_type === 'paragraph'">
                                                                        <div>
                                                                            <textarea class="form-control" id="textarea-auto-height" rows="3" max-rows="8" v-model="fo.data_value"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row mt-4">
                                                    <div class="col">
                                                        <div class="form-group mb-0">
                                                            <button @click="onSubmit" class="btn btn-success mr-3">Submit</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    myHost
} from '../../../_helpers/host'
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data() {
        return {
            editor: ClassicEditor,
            form_id: null,
            host: myHost.host,
            rfp: {
                title: '',
                status: '',
                deadline: null,
                rfp_category: null,
                details: '',
                rfp_attachment: null
            },
            statuses: ['APPROVED', 'WORKING', 'COMPLETED'],

            form_data: {
                rfp: null,
                form_name: "",

                submission_form: [{
                    data_label: null,
                    data_type: null
                }]
            },

            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },

            ],

            options: [{
                    title: 'Text Field',
                    value: 'textfield'
                },
                {
                    title: 'File Field',
                    value: 'filefield'
                },
                {
                    title: 'Link Field',
                    value: 'textfield'
                },
                {
                    title: 'Paragraph',
                    value: 'paragraph'
                }
            ],
        }
    },

    computed: {
        ...mapState({
            detail: state => state.rfp.rfp_detail,
            items: state => state.rfp.rfp_category,

            form: state => state.rfp.form
        })
    },

    mounted() {
        this.$store.dispatch("rfp/fetchRFPCategory")
        this.$store.dispatch("rfp/fetchRFPDetails", this.$route.params.id)

        this.$store.dispatch("rfp/fetchForm", this.$route.params.id)
    },

    watch: {
        detail(oldValue, newValue) {
            this.rfp.title = this.detail.title
            this.rfp.status = this.detail.status
            this.rfp.deadline = this.detail.deadline
            this.rfp.rfp_category = this.detail.rfp_category.id
            this.rfp.details = this.detail.details
            this.rfp.rfp_attachment = this.detail.rfp_attach
        },

        form(newValue, oldValue) {
            if (this.form !== null) {
                this.form_id = this.form[0].id
                this.form_data.rfp = this.form[0].rfp
                this.form_data.form_name = this.form[0].form_name
                this.form_data.submission_form = this.form[0].submission_form
            }
        },
    },

    methods: {
        uploadFile(e, form) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                form.data_value = e.target.result;
            };
        },

        onSubmit() {
            this.form_data.rfp = this.$route.params.id

            this.$store.dispatch("rfp/submitForm", {
                form_data: this.form_data.submission_form,
                form: this.form_id,
                rfp: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    setTimeout(() => this.$router.push('/vendor/rfq'), 2000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
    }
};
</script>
