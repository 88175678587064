<template>
<div>
    <Breadcrumbs main="Job" title="Candidates" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex flex-column flex-sm-row" style="margin-bottom: 36px;">
                            <div class="mr-3 mr-md-4 field-width">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search" v-model="search_text" aria-label="Search" aria-describedby="basic-addon2">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">
                                            <b-icon icon="search"></b-icon>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex mt-3 mt-sm-0">
                                <div class="mr-3 mr-md-4 field-width">
                                    <b-form-select v-model="selected_job" :options="statuses"></b-form-select>
                                </div>
                                <button type="button" class="btn btn-primary">Reset</button>
                            </div>
                        </div>

                        <div class="applicant-view" v-if="!loading && !error">
                            <div v-for="applicant in applicants" :key="applicant.index">
                                <div style="height: 370px; display: flex; flex-direction: column; align-items: center; text-align: center; background-color: rgb(243 244 246); border-radius: 0.75rem; filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06)); padding: 20px;">
                                    <div style="width: 100%; display: flex; align-items: start;">
                                        <b-badge pill variant="success">{{ applicant.status }}</b-badge>
                                    </div>
                                    <img style="height: 100px; width: 100px; border-radius: 100%;" :src="img1">
                                    <div style="margin-top: 15px;">
                                        <h5>{{ applicant.user.first_name }} {{ applicant.user.last_name }}</h5>
                                        <h7>{{ applicant.job.position }} | {{ applicant.job.title }}</h7>
                                    </div>

                                    <div style="position: absolute; bottom: 25px; width: 100%;">
                                        <router-link :to="`/job/canditate/detail/${applicant.id}`">
                                            <b-button variant="primary">View Details</b-button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="loader-box">
                                        <div class="loader-10"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

  
<script>
import img1 from "../../../assets/images/user/3.jpg";
var primary = localStorage.getItem('primary_color') || '#FF0006';
var secondary = localStorage.getItem('secondary_color') || '#818284';
import {
    mapState
} from 'vuex';

export default {
    data() {
        return {
            img1: img1,

            search_text: '',
            selected_job: null,

            statuses: [{
                    value: null,
                    text: 'Select a Status'
                },
                {
                    value: 'a',
                    text: 'Applied'
                },
                {
                    value: 'b',
                    text: 'Pending'
                },
                {
                    value: 'c',
                    text: 'Selected'
                },
            ]
        };
    },
    computed: {
        ...mapState({
            applicants: state => state.job.applicant_list,
            loading: state => state.job.jobLoading,
            error: state => state.job.jobError,

        }),
    },
    mounted() {
        this.$store.dispatch("job/fetchApplicant")
    },
}
</script>

<style scoped>
.applicant-view {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 25px;
}

.field-width {
    width: 260px;
}

@media screen and (max-width: 1536px) {
    .applicant-view {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 25px;
    }
}

@media screen and (max-width: 1366px) {
    .applicant-view {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 32px;
    }
}

@media screen and (max-width: 1198px) {
    .applicant-view {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 20px;
    }
}

@media screen and (max-width: 1050px) {
    .applicant-view {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 20px;
    }
}

@media screen and (max-width: 750px) {
    .field-width {
        width: 210px;
    }
}

@media screen and (max-width: 730px) {
    .applicant-view {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}

@media screen and (max-width: 645px) {
    .field-width {
        width: 180px;
    }
}

@media screen and (max-width: 575px) {
    .field-width {
        width: 100%;
    }
}

@media screen and (max-width: 430px) {
    .applicant-view {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 20px;
    }
}
</style>
