<template>
<div>
    <div class="background d-flex justify-content-center align-items-center">
        <h4 class="text-center">Careers</h4>
    </div>
    <div class="container-fluid">
        <div class="row">
            <!-- <div class="col-md-12">
            <div class="d-flex flex-row justify-content-center align-content-center" style="gap:10px; margin:20px;">

                <div>
                    <b-dropdown id="dropdown-1" text="Category" class="m-md-2">
                        <b-dropdown-item>First Action</b-dropdown-item>
                        <b-dropdown-item>Second Action</b-dropdown-item>
                        <b-dropdown-item>Third Action</b-dropdown-item>

                    </b-dropdown>
                </div>
                <div>
                    <b-dropdown id="dropdown-1" text="Job Type" class="m-md-2">
                        <b-dropdown-item>First Action</b-dropdown-item>
                        <b-dropdown-item>Second Action</b-dropdown-item>
                        <b-dropdown-item>Third Action</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <div class="card" v-for="job in visibleJobs" :key="job.id" style="margin-bottom: 10px;">
                <div class="card-body">
                    <router-link :to="`/career/${job.id}`" style="text-decoration: none;">
                        <div class="d-flex flex-column flex-sm-row justify-content-sm-between" style="gap: 10px;">
                            <div class="d-flex flex-column">
                                <div style="display: flex; flex-direction: column; gap:10px" >
                                    <h7 style="font-weight: 500; font-size: 20px; color: black;">{{ job.title }}</h7>
                                    <h7 style="font-weight: 400; font-size: 15px; color: black;">{{ job.position }}</h7>
                                    <b-badge variant="dark" style="max-width: fit-content;">Vacancies: {{job.vacancies}}</b-badge>
                                </div>

                            </div>
                            <div class="d-flex flex-column" style="gap:10px">
                                <h7>Deadline: {{job.deadline}}</h7>
                                <h7>{{job.job_type}}</h7>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="d-flex flex-column " style="gap: 10px">
                <div class="d-flex justify-content-center ">
                    <h7>Showing {{ this.visibleJobCount }} of out {{ jobs.length }}</h7>
                </div>
                <div class="d-flex justify-content-center" v-if="showLoadMoreButton()" style="cursor: pointer;">
                    <h7 class="buttonStyle" @click="loadMore()">Load more jobs</h7>
                </div>
            </div>
        </div> -->

            <div class="col-md-4 sidebar">
                <div class="card sticky-sidebar">
                    <div class="card-body">
                        <b-input-group class="">
                            <b-form-input v-model="query" placeholder="Type to Search"></b-form-input>
                            <b-input-group-append>
                                <b-button :disabled="!query" @click="clearSearch = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>

                        <h5 class="mt-4">Category</h5>

                        <div class="mt-4">
                            <div class="pb-2" v-for="(category, index) in job_category_list" :key="index">
                                <div class="d-flex align-items-center" style="gap:5px">
                                    <input class="category-radio cursor-pointer d-flex align-items-center" type="radio" name="radio" :value="category.id" :id="category.id" v-model="selectedCategory" @click="sortByCategory(category.id)">
                                    <h7>{{ category.title }}</h7>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-8">
                <div class="row">
                    <div v-for="job in visibleJobs" :key="job.id" class="col-xl-6 col-lg-6">
                        <div class="card">
                            <div class="card-body">
                                <router-link :to="`/career/${job.id}`" style="text-decoration: none;">
                                    <div class="d-flex flex-column flex-sm-row justify-content-sm-between" style="gap: 10px;">
                                        <div class="d-flex flex-column">
                                            <div style="display: flex; flex-direction: column; gap:10px">
                                                <h7 style="font-weight: 500; font-size: 20px; color: black;">{{ job.title }}</h7>
                                                <h7 style="font-weight: 400; font-size: 15px; color: black;">{{ job.position }}</h7>
                                                <b-badge variant="dark" style="max-width: fit-content;">Vacancies: {{job.vacancies}}</b-badge>
                                            </div>

                                        </div>
                                        <div class="d-flex flex-column" style="gap:10px">
                                            <h7>Deadline: {{job.deadline}}</h7>
                                            <h7>{{job.job_type}}</h7>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="d-flex flex-column" style="gap: 10px">
                            <div class="d-flex justify-content-center ">
                                <h7>Showing {{ this.visibleJobCount }} of out {{ jobs.length }}</h7>
                            </div>
                            <div class="d-flex justify-content-center" v-if="showLoadMoreButton()" style="cursor: pointer;">
                                <h7 class="buttonStyle" @click="loadMore()">Load more jobs</h7>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
export default {
    data() {
        return {
            visibleJobCount: 2,
            activeSection: null,
            options: [{
                    text: 'Orange',
                    value: 'orange'
                },
                {
                    text: 'Apple',
                    value: 'apple'
                },
                {
                    text: 'Pineapple',
                    value: 'pineapple'
                },
                {
                    text: 'Grape',
                    value: 'grape'
                }
            ],

        };
    },
    computed: {
        ...mapState({
            jobs: state => state.job.job_list,
            job_category_list: state => state.job.job_categories,
        }),
        visibleJobs() {
            return this.jobs.slice(0, this.visibleJobCount);
        },
    },
    methods: {
        loadMore() {
            this.visibleJobCount += 1;
        },
        showLoadMoreButton() {
            return this.visibleJobs.length < this.jobs.length;
        },

    },

    mounted() {
        this.$store.dispatch("job/fetchJob");
        this.$store.dispatch("job/fetchJobCategory");

    },

};
</script>

<style scoped>
a {
    color: #000000;
}

a:hover {
    color: black;
}


/* .card:hover {
    border: 1px solid black;

} */

.buttonStyle {
    background-color: black;
    color: #ffffff;
    padding: 10px 15px;
    border-radius: 24px;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid black;
    transition: background-color 0.3s, color 0.3s;
}

.buttonStyle:hover {
    background-color: #ffffff;
    color: black;
}

.sticky-sidebar {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 20px;
    /* Adjust the top offset as needed */
}

.container-fluid {
    padding: 40px 60px;
}

.background {
    background-image: url("../../../assets/images/dashboard/bg-1.jpg");
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 390px;
    background-position: center center,

}

.category-radio {
    appearance: none;
    background-color: #fff;
    width: 15px;
    height: 15px;
    border: 2px solid #ccc;
    border-radius: 2px;
    display: inline-grid;
    place-content: center;
}

.category-radio::before {
    content: "";
    width: 10px;
    height: 10px;
    transform: scale(0);
    transform-origin: bottom left;
    background-color: #fff;
    clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

.category-radio:checked::before {
    transform: scale(1);
}

.category-radio:checked {
    background-color: #12685d;
    border: 2px solid #12685d;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media screen and (max-width: 600px) {
    .container-fluid {
        padding: 10px 20px;
    }
}
</style>
