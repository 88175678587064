<template>
  <a class="nav-link" target="_blank" href="https://pixelstrap.freshdesk.com/">
    <div>
      <i class="icon-support"></i>
    </div>
    <span>Support</span>
  </a>
</template>

<script>
  export default {
    name: 'Support',
  };
</script>
