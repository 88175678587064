<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Purchase Order Create" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Vendor<span><button class="btn-success ml-3" v-b-modal.modal-prevent-closing>Add New</button></span></label>

                                        <select class="form-control digits" v-model="purchase_order.vendor" @change="getVendor">
                                            <option v-for="(vendor, index) in vendors" :key="index" :value="vendor.user.id">{{ vendor.user.user_name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Project</label>
                                        <select class="form-control digits" v-model="purchase_order.project">
                                            <option v-for="(project, index) in projects" :key="index" :value="project.id">{{ project.title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input class="form-control" type="text" placeholder="Enter email address" v-model="purchase_order.email">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Currency</label>
                                        <select class="form-control digits" v-model="purchase_order.currency">
                                            <option v-for="(currency, index) in currencies" :key="index">{{ currency }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Vendor Address</label>
                                        <input class="form-control" type="text" placeholder="Enter vendor address" v-model="purchase_order.vendor_address">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Billing Address</label>
                                        <input class="form-control" type="text" placeholder="Enter billing address" v-model="purchase_order.billing_address">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Purchase Order Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="purchase_order.purchase_order_date"></datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Due Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="purchase_order.due_date"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Upload Attachment</label>
                                        <b-form-file v-model="purchase_order.purchase_order_attachment" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="uploadFile"></b-form-file>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Items</label>
                                        <ItemTable style="margin-bottom: 10px;" id="item-table" @itemsUpdated="updateItems" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <!-- Total -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Total</label>
                                        <input class="form-control" type="number" placeholder="Enter total amount" disabled v-model="purchase_order.total" />
                                    </div>

                                    <!-- Vat -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Vat (%)</label>
                                        <div class="d-flex w-100" style="gap:16px">
                                            <input class="form-control" type="number" placeholder="Enter vat (%)" v-model="purchase_order.vat" />
                                            <input class="form-control" type="number" placeholder="Vat Amount" v-model="vatAmount" disabled />
                                        </div>
                                    </div>

                                    <!-- Tax -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Tax (%)</label>
                                        <div class="d-flex w-100" style="gap:16px">
                                            <input class="form-control" type="number" placeholder="Enter tax (%)" v-model="purchase_order.tax" />
                                            <input class="form-control" type="number" placeholder="Tax Amount" v-model="taxAmount" disabled />
                                        </div>
                                    </div>

                                    <!-- Discount -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Discount (%)</label>
                                        <div class="d-flex w-100" style="gap:16px">
                                            <input class="form-control" type="number" placeholder="Enter discount (%)" v-model="purchase_order.discount" />
                                            <input class="form-control" type="number" placeholder="Tax Amount" v-model="discountAmount" disabled/>
                                        </div>
                                    </div>

                                    <!-- Grand Total -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Grand Total ({{ purchase_order.currency ? purchase_order.currency : "BDT" }})</label>
                                        <input class="form-control" type="number" placeholder="Enter grand total" disabled v-model="purchase_order.subtotal" />
                                    </div>

                                    <!-- In Words -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">In Words</label>
                                        <input class="form-control" type="text" placeholder="Enter grand total in words" v-model="purchase_order.in_words" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Remarks</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter remarks..." rows="2" v-model="purchase_order.remarks"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                        <router-link to="/finance-and-accounts/finance/purchase-order/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Vendor Create -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add New Vendor" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="First Name" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="item.first_name" placeholder="Enter first name"></b-form-input>
            </b-form-group>
            <b-form-group label="Last Name" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="item.last_name" placeholder="Enter last name"></b-form-input>
            </b-form-group>

            <div class="form-group">
                <label>Category</label>
                <multiselect v-model="category" tag-placeholder="Add Category" placeholder="Search or add a category" label="title" :max="vendor_categories.length" track-by="id" :options="vendor_categories" :multiple="true" :taggable="true"></multiselect>
            </div>

            <b-form-group label="Email" label-for="name-input" invalid-feedback="Email is required">
                <b-form-input id="name-input" v-model="item.email" placeholder="Enter email address"></b-form-input>
            </b-form-group>
            <b-form-group label="Phone No" label-for="name-input" invalid-feedback="Phone no is required">
                <b-form-input id="name-input" v-model="item.phone" placeholder="Enter phone number"></b-form-input>
            </b-form-group>
            <b-form-group label="Company" label-for="name-input" invalid-feedback="Company is required">
                <b-form-input id="name-input" v-model="item.company" placeholder="Enter company name"></b-form-input>
            </b-form-group>
            <b-form-group label="Address" label-for="name-input" invalid-feedback="Address is required">
                <b-form-input id="name-input" v-model="item.address" placeholder="Enter address"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {
    computed
} from "vue";
import ItemTable from "./item-table.vue";
import moment from "moment";
import Multiselect from 'vue-multiselect';

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        ItemTable,
        Multiselect
    },

    data() {
        return {
            discountAmount: computed(() => {
                if (this.purchase_order.total !== 0) {
                    const discount = this.purchase_order.discount || 0;
                    let total = this.purchase_order.total + discount * (this.purchase_order.total / 100)
                    let gtotal = total - this.purchase_order.total

                    return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                }
                return 0;
            }),
            taxAmount: computed(() => {
                if (this.purchase_order.total !== 0) {
                    const tax = this.purchase_order.tax || 0;
                    let total = this.purchase_order.total + tax * (this.purchase_order.total / 100)
                    let gtotal = total - this.purchase_order.total

                    return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                }
                return 0;
            }),

            vatAmount: computed(() => {
                if (this.purchase_order.total !== 0) {
                    const vat = this.purchase_order.vat || 0;
                    let total = this.purchase_order.total + vat * (this.purchase_order.total / 100)
                    let gtotal = total - this.purchase_order.total

                    return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                }
                return 0;
            }),

            purchase_order: {
                vendor: null,
                project: null,
                email: '',
                currency: 'BDT',
                vendor_address: '',
                billing_address: '',
                purchase_order_date: null,
                due_date: null,
                details: [],
                details_in_depth: [],
                total: computed(() => {
                    let total = 0;
                    if (this.purchase_order.details_in_depth.length >= 1) {
                        this.purchase_order.details_in_depth.map((item) => {
                            total = total + item.amount;
                        });
                    }
                    return total;
                }),
                vat: null,
                tax: null,
               
                discount: null,
                subtotal: computed(() => {
                    if (this.purchase_order.total !== 0) {
                        const tax = this.purchase_order.tax || 0;
                        const vat = this.purchase_order.vat || 0;
                        const discount = this.purchase_order.discount || 0;
                        let gtotal =
                            this.purchase_order.total +
                            tax * (this.purchase_order.total / 100) +
                            vat * (this.purchase_order.total / 100) -
                            discount * (this.purchase_order.total / 100);
                        return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                    }
                    return 0;
                }),
                purchase_order_attachment: null,
                in_words: '',
                remarks: ''
            },

            vendor: {
                name: '',
                company: '',
                designation: '',
                phone: '',
                email: '',
                status: 'Active'
            },

            category: [],

            item: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                category: [],
                company: '',
                address: '',
                status: true
            },

            currencies: ['BDT', 'USD', 'EUR']
        };
    },

    computed: {
        ...mapState({
            items: state => state.item.items,
            projects: state => state.project.project,
            vendors: state => state.vendor.vendor_without_pg,
            vendor_categories: state => state.vendor.vendor_categories
        })
    },

    mounted() {
        this.$store.dispatch("item/fetchItem")
        this.$store.dispatch("project/fetchProject")
        this.$store.dispatch("vendor/fetchVendor_without_pg");
        this.$store.dispatch("vendor/fetchVendorCategory")

        if (this.$route.params.project == 'new') {
            this.purchase_order.project = ''
        } else {
            this.purchase_order.project = this.$route.params.project
        }
    },

    methods: {
        getVendor() {
            this.$store.dispatch("vendor/fetchVendorByUserId", this.purchase_order.vendor).then(result => {
                this.purchase_order.email = result.data.user.email
                this.purchase_order.vendor_address = result.data.address
                this.purchase_order.billing_address = result.data.address
            })
        },

        updateItems(data) {
            if (data) {
                this.purchase_order.details_in_depth = data;
                this.purchase_order.details = data;
            }
        },

        uploadFile(e) {
            this.purchase_order.purchase_order_attachment = e.target.files[0];
        },

        onSubmit() {
            if (this.purchase_order.purchase_order_date !== null) {
                this.purchase_order.purchase_order_date = moment(String(this.purchase_order.purchase_order_date)).format("YYYY-MM-DD");
            }
            if (this.purchase_order.due_date !== null) {
                this.purchase_order.due_date = moment(String(this.purchase_order.due_date)).format("YYYY-MM-DD");
            }

            this.$store.dispatch("purchase_order/addPO", this.purchase_order).then(response => {
                // console.log(response)
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    setTimeout(() => {
                        this.$router.push('/finance-and-accounts/finance/purchase-order/list')
                    }, 2000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        resetModal() {
            this.item.first_name = ''
            this.item.last_name = ''
            this.item.email = ''
            this.item.phone = ''
            this.item.company = ''
            this.item.address = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.category.map(item => this.item.category.push(item.id));
            this.$store.dispatch("vendor/addVendor", this.item).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.purchase_order.vendor = response.data.user
                    this.purchase_order.email = response.data.email
                    this.purchase_order.vendor_address = response.data.address
                    this.purchase_order.billing_address = response.data.address

                    this.item.first_name = ''
                    this.item.last_name = ''
                    this.item.email = ''
                    this.item.phone = ''
                    this.item.company = ''
                    this.item.address = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        }
    }
};
</script>
