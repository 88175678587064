<template>
<div>
    <Breadcrumbs main="Finance & Accounts" title="Account Details" />
    <!-- Container-fluid starts-->
    <div>
        <div class="invoice-container">
            <div style="display: flex; gap: 10px;">
                <b-button variant="primary" @click="printPdf('invoice')">Print</b-button>
            </div>
            <div class="d-flex flex-row mt-4">
                <b-badge pill variant="primary" @click="toggleLetterHead" class="signature-btn" style="cursor: pointer;max-width: fit-content">
                    {{ showLetterHead ? 'Hide Letterhead' : 'Show Letterhead' }}
                </b-badge>
                <b-badge pill variant="primary" @click="toggleLogo" class="signature-btn" style="cursor: pointer;max-width: fit-content">
                    {{ showLogo ? 'Hide Logo' : 'Show Logo' }}
                </b-badge>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-8">

                <div v-if="!loading && !error">
                    <div id="invoice">
                        <div class="content-container d-flex justify-content-center align-items-center" :style="additionalStyle">
                            <div id="details">
                                <div style="display: flex; align-items: center;" class="imgSection" v-if="showLogo">
                                    <img style="height: 64px;" :src="host + setting.logo" alt="" />
                                </div>
                                <div class="d-flex justify-content-center align-items-center">
                                    <div style="border: 1px solid gray; ">
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Beneficiery Name</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.beneficiery_name }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Account Title</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.account_title }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Account Number</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.account_number }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Account Type</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.account_type }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Bank Name</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.bank_name }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Branch Name & Address</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.branch_detail }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Branch Routing Number</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.branch_routing_number }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Swift Code</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.swift_number }}</h7>
                                            </span>
                                        </div>
                                        <div style="border: 1px solid gray; display: flex;">
                                            <span style="width: 240px; padding: 16px 12px;">Beneficiery E-mail Address</span>
                                            <span style="border-left: 1px solid gray; display: flex; align-items: center; padding-left: 12px; padding-right: 12px;">
                                                <h7 style="width: 240px;">{{ bank.beneficiery_email }}</h7>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="loader-box">
                        <div class="loader-10"></div>
                    </div>
                </div>

            </div>

            <div class="col-xl-4">
                <div class="content-container-attahment w-100">
                    <div class="p-2" v-if="!loading && !error">
                        <div class="d-flex justify-content-between">
                            <h6 class="mb-2">Supporting Documents</h6>
                            <feather type="plus-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-modal.modal-scrollable-add-document v-b-tooltip.hover title="Add Documents"> </feather>
                        </div>

                        <b-table show-empty sticky-header :items="bank.info_attach" :fields="Attachment" head-variant="light">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>

                            <template #cell(title)="data">
                                <a :href="host+data.item.info_attachment" target="_blank"> {{ data.item.attach_title}}</a>
                            </template>
                            <template #cell(action)="data">
                                <div style="display: flex; flex-direction: row; gap:10px">
                                    <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete" @click="deleteAttach(data.item.id)"></feather>
                                </div>
                            </template>
                        </b-table>
                    </div>

                    <div class="p-2" v-else>
                        <div class="loader-box">
                            <div class="loader-10"></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--  Attachment Create -->
    <b-modal id="modal-scrollable-add-document" ref="modal" title="Add document" @ok="handleAttachOk">
        <form ref="form" @submit.stop.prevent="handleAttahmentSubmit">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Upload Documents</label>
                        <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." @change="uploadAttachFile"></b-form-file>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import {
    myHost
} from '../../../_helpers/host';
export default {
    data() {
        return {
            showLogo: true,
            bank: {},
            host: myHost.host,
            showLetterHead: true,

            add_attachment: {
                info_attachment: null,
                bank_info: ''
            },

            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                }
            ],

        };
    },

    computed: {
        ...mapState({
            account_detail: state => state.bank.account_detail,
            loading: state => state.bank.submitLoading,
            error: state => state.bank.submitError,
            setting: state => state.organization.organization_setting,
        }),

        additionalStyle() {
            return this.showLetterHead ? {
                'background-image': `url(${this.host}${this.setting.letterpad})`,
                'background-size': '100% 100%',
                'background-position': 'center center',
                'background-repeat': 'no-repeat',
            } : {};
        },
    },

    watch: {
        account_detail(oldValue, newValue) {
            this.bank = this.account_detail
        }
    },

    mounted() {
        this.$store.dispatch("bank/fetchBankAccountDetails", this.$route.params.id),
            this.$store.dispatch("organization/fetchOrganizationSettings")
    },

    methods: {
        toggleLogo() {
            this.showLogo = !this.showLogo;
        },
        toggleLetterHead() {
            this.showLetterHead = !this.showLetterHead;
        },
        printPdf(section) {
            this.downloadPdf = true;

            const printContents = document.getElementById(section).innerHTML;
            const originalContents = document.body.innerHTML;
            const wrappedPrintContents = `<div class="page">${printContents}</div>`;

            document.body.innerHTML = wrappedPrintContents;

            window.print();

            document.body.innerHTML = originalContents;
            location.reload();
        },
        uploadAttachFile(e) {
            this.add_attachment.info_attachment = e.target.files[0]
        },

        handleAttachOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleAttahmentSubmit()
        },

        handleAttahmentSubmit() {
            this.add_attachment.bank_info = this.$route.params.id
            this.$store.dispatch("bank/addBankAttachment", {
                attachment: this.add_attachment,
                bank_info: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.add_attachment.invoice_attachment = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable-add-document')
            })
        },

        deleteAttach(id) {
            this.$store.dispatch("bank/deleteAttachmentFromBank", {
                id: id,
                bank_info: this.$route.params.id,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
    }
};
</script>

<style scoped>
.content-container {
    background-color: white;
    max-width: 1280px;
    min-height: 1360px;
    padding: 140px 60px;
    display: flex;
    flex-direction: column;
    /* justify-items: center;
    align-items: center; */
    margin-bottom: 32px;
    position: relative;
}

.imgSection {
    position: absolute;
    top: 32px;
    right: 58px;
}

.content-container-attahment {
    background-color: white;
    padding: 10px;
}

.invoice-header,
.client-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 48px;
}

.company-info,
.client-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: start;
}

.payment-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    align-items: flex-end;
}

.total-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.invoice-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: end;
}

.logo-img {
    object-fit: cover;
    object-position: center;
}

.table-container {
    width: 100%;
    font-size: small;
    color: black;
}

.total-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    margin-top: 20px;
    margin-bottom: 32px;
}

.other-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* @media print {
        html,
        body {
            width: 210mm;
            height: 297mm;
        }

        .content-container {
            margin: 0;
            width: initial;
            min-height: initial;
        }
    } */

.invoice-container {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 32px;
}

@media print {
    .invoice-container {
        display: none;
        visibility: hidden;
    }

    .signature-btn {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 639px) {
    .invoice-header {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }

    .invoice-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: start;
    }

    .payment-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: flex-start;
    }

    .client-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 20px;
        margin-bottom: 20px;
    }

    .total-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-self: flex-end;
        margin-top: 20px;
        margin-bottom: 32px;
    }
}
</style>
