<template>
  <div>
    <Breadcrumbs main="Messaging" title="Send SMS"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="edit-profile">
        <div class="row">

          <div class="col-xl-8">
            <b-card header-tag="div" no-body>

              <b-card-body>
                <b-tabs pills slot="header" class="tabbed-card">
                  <b-tab title="Simple SMS" active>
                    <form>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-label">Select Sender ID</label>
                            <b-form-select v-model="numberSelectedFlatInput" class="btn-square"
                                           :options="numberOptions"></b-form-select>

                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="form-group">
                            <label class="form-label">Enter Mobile Numbers</label>
                            <textarea class="form-control" rows="3"
                                      placeholder="01734999936,01874862998"></textarea>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group m-checkbox-inline custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="checkbox7">Remove Duplicate</b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="radio radio-primary d-inline-block">
                              <b-form-radio name="radio3" value="option1">Text</b-form-radio>
                            </div>
                            <div class="radio radio-primary d-inline-block">
                              <b-form-radio name="radio3" value="option2">Unicode</b-form-radio>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="form-group">
                            <label class="form-label">Enter SMS Content | Use Templates</label>
                            <textarea class="form-control" rows="5"
                                      placeholder="Enter SMS Content" v-model="smsCount"></textarea>
                            <small class="bg-dark p-1"> {{ getCharacterCount() }} Characters |
                              {{ 1530 - getCharacterCount() }} Characters Left | <span class="font-primary">1 SMS ( 160 Char./SMS )</span></small>
                          </div>

                        </div>

                        <div class="col-md-4">
                          <button class="btn btn-primary" type="submit">Send SMS</button>
                        </div>
                      </div>
                    </form>
                  </b-tab>
                  <b-tab title="Upload File">
                    <form>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-label">Select Sender ID</label>
                            <b-form-select v-model="numberSelectedFlatInput" class="btn-square"
                                           :options="numberOptions"></b-form-select>

                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-label">Select File (CSV) | Download Sample File</label>
                            <div>
                              <b-form-file class="form-control" plain></b-form-file>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group m-checkbox-inline custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="checkbox7">Remove Duplicate</b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="radio radio-primary d-inline-block">
                              <b-form-radio name="radio3" value="option1">Text</b-form-radio>
                            </div>
                            <div class="radio radio-primary d-inline-block">
                              <b-form-radio name="radio3" value="option2">Unicode</b-form-radio>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="form-group">
                            <label class="form-label">Enter SMS Content | Use Templates</label>
                            <textarea class="form-control" rows="5"
                                      placeholder="Enter SMS Content" v-model="smsCount"></textarea>
                            <small class="bg-dark p-1"> {{ getCharacterCount() }} Characters |
                              {{ 1530 - getCharacterCount() }} Characters Left | <span class="font-primary">1 SMS ( 160 Char./SMS )</span></small>
                          </div>

                        </div>

                        <div class="col-md-4">
                          <button class="btn btn-primary" type="submit">Send SMS</button>
                        </div>
                      </div>
                    </form>
                  </b-tab>
                  <b-tab title="Group SMS">
                    <form>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-label">Select Sender ID</label>
                            <b-form-select v-model="numberSelectedFlatInput" class="btn-square"
                                           :options="numberOptions"></b-form-select>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-label">Select Contact Group</label>
                            <b-form-select v-model="numberSelectedFlatInput" class="btn-square"
                                           :options="numberOptions"></b-form-select>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group m-checkbox-inline custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="checkbox7">Remove Duplicate</b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="radio radio-primary d-inline-block">
                              <b-form-radio name="radio3" value="option1">Text</b-form-radio>
                            </div>
                            <div class="radio radio-primary d-inline-block">
                              <b-form-radio name="radio3" value="option2">Unicode</b-form-radio>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="form-group">
                            <label class="form-label">Enter SMS Content | Use Templates</label>
                            <textarea class="form-control" rows="5"
                                      placeholder="Enter SMS Content" v-model="smsCount"></textarea>
                            <small class="bg-dark p-1"> {{ getCharacterCount() }} Characters |
                              {{ 1530 - getCharacterCount() }} Characters Left | <span class="font-primary">1 SMS ( 160 Char./SMS )</span></small>
                          </div>

                        </div>

                        <div class="col-md-4">
                          <button class="btn btn-primary" type="submit">Send SMS</button>
                        </div>
                      </div>
                    </form>
                  </b-tab>
                </b-tabs>
              </b-card-body>
              <h5 slot="header"></h5>
            </b-card>

          </div>
          <div class="col-xl-4">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title mb-0"> Information</h4>
              </div>
              <div class="card-body">

                <span class="font-primary">Single SMS:</span> With this option, you can send SMS to single or multiple
                users at a time. You can pass comma separated mobile numbers to send to multiple number at a time.
                <br>
                <br>
                <span class="font-primary">Upload File:</span> You can send SMS in bulk by uploading a list of contacts in CSV format. Once the upload is
                completed, your file will be processed instantly.
                Download Sample CSV
                <br>
                <br>
                <span class="font-primary">Group SMS: </span> You can send SMS directly to your saved contact group. Just select the group and type your
                message. You are good to go!
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      numberSelectedFlatInput: 1,
      numberOptions: [
        {value: 1, text: 1},
        {value: 2, text: 2},
        {value: 3, text: 3},
        {value: 4, text: 4},
        {value: 5, text: 5}
      ],
      smsCount: ""
    }
  },
  methods: {
    getCharacterCount() {
      return this.smsCount.length
    }
  }
};
</script>
