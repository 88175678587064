<template>
<div>
    <Breadcrumbs main="HR" title="Compliance Form" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;">
                            <div style="display: flex; align-items: center;">
                                <h5>Compliance Details</h5>
                            </div>

                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div slot="with-padding">
                                    <div class="form theme-form">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <h6>Title</h6>
                                                    <h7>{{ compliance.title }}</h7>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <h6>Compliance</h6>
                                                    <div v-html="compliance.details"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div>
                            <h5>{{ form_data.form_name }}</h5>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div style="display: flex; flex-direction: column;">
                                        <div class="row">
                                            <div class="col-md-12 grid-style">
                                                <div v-for="(fo, index) in form_data.submission_form" :key="index">
                                                    <div>
                                                        <span>{{ fo.data_label }}</span>
                                                        <span>
                                                            <div v-if="fo.data_type === 'filefield'">
                                                                <div>
                                                                    <b-form-file placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="uploadFile($event, fo)"></b-form-file>
                                                                </div>
                                                            </div>
                                                            <div v-else-if="fo.data_type === 'textfield'">
                                                                <div>
                                                                    <b-form-input v-model="fo.data_value" type="text"></b-form-input>
                                                                </div>
                                                            </div>
                                                            <div v-else-if="fo.data_type === 'paragraph'">
                                                                <div>
                                                                    <textarea class="form-control" id="textarea-auto-height" rows="3" max-rows="8" v-model="fo.data_value"></textarea>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col">
                                                <div class="form-group mb-0">
                                                    <button @click="onSubmit" class="btn btn-success mr-3" v-if="compliance_is_submitted !== true ">Submit</button>
                                                    <p v-else> You've already submitted your response.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data() {
        return {
            form_id: null,

            form_data: {
                compliance: null,
                form_name: "",

                submission_form: [{
                    data_label: null,
                    data_type: null
                }]
            },
            compliance: {
                title: '',
                details: ''
            },
        }
    },

    computed: {
        ...mapState({
            form: state => state.compliance.form,
            detail: state => state.compliance.compliance_details,
            compliance_submit_check: state => state.onBoarding.complianceSubmissionCheck,
            compliance_is_submitted: state => state.onBoarding.isSubmitted,

        })
    },

    mounted() {
        this.$store.dispatch("compliance/fetchForm", this.$route.params.id).then(result => {
            this.form_id = result.data[0].id
            this.$store.dispatch("onBoarding/fetchComplianceSubmissionCheck", this.form_id)
        })
        this.$store.dispatch("compliance/fetchComplianceDetails", this.$route.params.id)

    },

    watch: {
        form(newValue, oldValue) {
            if (this.form !== null) {
                // this.form_id = this.form[0].id
                this.form_data.compliance = this.form[0].compliance
                this.form_data.form_name = this.form[0].form_name

                this.form_data.submission_form = this.form[0].submission_form
            }
        },
        detail(newValue, oldValue) {
            this.compliance.title = this.detail.title
            this.compliance.details = this.detail.details
        },

    },

    methods: {
        uploadFile(e, form) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                form.data_value = e.target.result;
            };
        },

        onSubmit() {
            this.form_data.compliance = this.$route.params.id

            let isEmpty = false;
            for (let i = 0; i < this.form_data.submission_form.length; i++) {
                const item = this.form_data.submission_form[i];

                if (item.data_value === null) {
                    isEmpty = true;
                    break;
                }
            }

            if (isEmpty) {
                Swal.fire({
                    title: "Error!",
                    text: 'All field is required',
                    icon: "error"
                });

            } else {

                this.$store.dispatch("compliance/submitForm", {
                    form_data: this.form_data.submission_form,
                    form: this.form_id,
                    compliance: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        setTimeout(() => this.$router.push('/user/profile'), 2000)

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }

        }
    }
};
</script>

<style>
.grid-style {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
}

@media screen and (max-width: 768px) {
    .grid-style {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 24px;
    }
}
</style>
