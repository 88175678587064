<template>
  <a
    class="nav-link"
    target="_blank"
    href="http://admin.pixelstrap.com/cuba/theme/landing-page.html#frameworks"
  >
    <div>
      <i class="icon-panel"></i>
    </div>
    <span>Check features</span>
  </a>
</template>

<script>
  export default {
    name: 'CheckFeatures',
  };
</script>
