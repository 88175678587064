<template>
<div>
    <Breadcrumbs main="Job" title="Job Create" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Job Title</label>
                                        <input class="form-control" type="text" placeholder="Enter job title" v-model="job.title">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Category</label>
                                        <select class="form-control digits" v-model="job.category">
                                            <option v-for="(category, index) in job_category" :key="index" :value="category.id">{{ category.title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Job Position</label>
                                        <input class="form-control" type="text" placeholder="Enter job position" v-model="job.position">
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Job Type</label>
                                        <select class="form-control digits" v-model="job.job_type">
                                            <option v-for="(status, index) in job_types" :key="index">{{ status }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select class="form-control digits" v-model="job.status">
                                            <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Vacancy</label>
                                        <input class="form-control" type="number" placeholder="Enter job vacancy" v-model="job.vacancies">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Location</label>
                                        <input class="form-control" type="text" placeholder="Enter job location" v-model="job.location">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Salary Start Range</label>
                                        <input class="form-control" type="number" placeholder="Enter salary start range" v-model="job.salary_start" step="1000">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Salary End Range</label>
                                        <input class="form-control" type="text" placeholder="Enter salary end range" v-model="job.salary_end" step="1000">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Post date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="job.opening"></datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Deadline</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="job.deadline"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col">
                                    <label>Upload Banner</label>
                                    <!-- <div class="flex items-center">
                                        <img v-if="job.banner_image" style="height: 60px; width: 100px;" :src="job.banner_image">

                                        <input :class="job.banner_image ? 'ml-4' : 'ml-0'" type="file" accept="image/*" @change="uploadFile">
                                    </div> -->
                                    <div class="flex items-center">

                                        <img v-if="show_image" style="height: 60px; width: 100px;" :src="show_image">
                                        <input :class="job.banner_image ? 'ml-2' : 'ml-2'" type="file" accept="image/*" @change="uploadFile">
                                    </div>
                                </div>

                            </div>

                            <div class="row mt-4">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Job Details</label>
                                        <b-form-textarea id="textarea-auto-height" placeholder="Enter job details..." v-model="job.description" rows="4" max-rows="13"></b-form-textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                        <router-link to="/job/job/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from "moment";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {
    myHost
} from "../../../_helpers/host";
import {
    mapState
} from 'vuex';
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data() {
        return {
            job: {
                title: '',
                position: '',
                location: '',
                vacancies: '',
                category: '',
                salary_start: '',
                salary_end: '',
                job_type: '',
                status: '',
                opening: null,
                deadline: null,
                description: '',
                banner_image: null
            },
            show_image: null,
            host: myHost.host,

            statuses: ['OPEN', 'CLOSED'],
            job_types: ['FULL-TIME', 'PART-TIME', 'INTERN', 'REMOTE', 'CONTRACT']
        };
    },
    computed: {
        ...mapState({
            job_category: state => state.job.job_categories,
        }),

    },

    methods: {
        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.job.banner_image = e.target.result;
            };
        },

        onSubmit(event) {
            if (this.job.opening !== null) {
                this.job.opening = moment(String(this.job.opening)).format("YYYY-MM-DD");
            }
            if (this.job.deadline !== null) {
                this.job.deadline = moment(String(this.job.deadline)).format("YYYY-MM-DD");
            }

            this.$store.dispatch("job/addJob", this.job).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.job.title = '',
                        this.job.position = '',
                        this.job.location = '',
                        this.job.vacancies = '',
                        this.job.salary_start = '',
                        this.job.salary_end = '',
                        this.job.job_type = '',
                        this.job.status = '',
                        this.job.opening = null,
                        this.job.deadline = null,
                        this.job.description = '',
                        this.job.banner_image = null

                    setTimeout(() => {
                        this.$router.push('/job/job/list')
                    }, 3000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    },
    mounted() {
        this.$store.dispatch("job/fetchJobCategory");
    },
};
</script>
