<template>
<div>
    <div class="background d-flex justify-content-center align-items-center">
        <h4 class="text-center">Career Details</h4>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">

                    <div class="card-body">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-sm-8">
                                <div slot="with-padding">

                                    <div>
                                        <b-badge variant="dark">{{job.title}}</b-badge>
                                        <h4 class="mt-2">Position: {{ job.position }}</h4>
                                        <p>{{ job.job_description }}</p>
                                    </div>
                                    <div class="d-flex flex-row justify-content-between mt-4">
                                        <div class="d-flex flex-column" style="gap: 5px;">
                                            <h7 style="font-size: 18px;">Location</h7>
                                            <div class="d-flex flex-row" style="gap: 8px;">
                                                <feather type="map-pin"></feather>
                                                <h7 style="font-weight: 500; font-size: 16px;">{{ job.location }}</h7>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column ">
                                            <h7>Job type</h7>
                                            <h7 style="font-weight: 500; font-size: 16px;">{{ job.job_type }}</h7>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <h5>Requirements</h5>
                                        <div style="" v-if="job.description">
                                            <div v-if="showDescription">
                                                <h7 v-if="job.description.length < 450">{{ job.description }}</h7>
                                                <h7 v-else>{{ job.description.substring(0, 450) }} ...</h7>
                                            </div>
                                            <div v-else class="pb-4 lg:pb-6">
                                                <div v-html="job.description"></div>
                                            </div>
                                            <div v-if="job.description.length > 450">
                                                <b-badge v-if="showDescription" variant="dark" size="sm" @click="displayDescription" style="margin-top: 20px; cursor: pointer;">See More</b-badge>
                                                <!-- <b-badge v-else variant="dark" size="sm" style="cursor: pointer;margin-top: 20px;" @click="closeDescription">See Less</b-badge> -->
                                            </div>
                                        </div>

                                    </div>

                                    <div class="mt-4 d-flex justify-content-center">
                                        <button class="buttonStyle" @click="goToSubmitPage(job.id)">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>

<!-- <b-form-textarea id="textarea-rows" placeholder="Tall textarea" rows="5" v-model="details.details" disabled></b-form-textarea> -->
</template>

<script>
import {
    mapState
} from 'vuex';
export default {
    data() {
        return {

            form: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                portfolio: '',
                linkedIn: '',
                facebook: '',
                cv: null,
                job: '',
                // current_address: '',
                // current_company: '',
                // expected_salary: '',
                // current_salary: '',
            },
            showDescription: true

        };
    },
    computed: {
        ...mapState({
            job: state => state.job.job_details,

        }),
    },
    mounted() {
        this.$store.dispatch("job/fetchJobDetails", this.$route.params.id)
    },
    methods: {
        goToSubmitPage(id) {
            const isLoggedIn = !!(localStorage.getItem('user'));
            if (isLoggedIn) {
                this.$router.push(`/job/job/apply/${id}`)
            } else {
                this.$router.push({
                    path: '/auth/login',
                    query: {
                        redirect: `/job/job/apply/${id}`
                    }
                })
            }
        },
        displayDescription() {
            this.showDescription = false
        },
        closeDescription() {
            this.showDescription = true
        },
        uploadFile(e) {
            const selectedFile = event.target.files[0];
            this.form.cv = selectedFile;
        },

        onSubmit() {
            this.form.job = this.$route.params.id

            this.$store.dispatch("job/addApplicant", this.form).then(response => {
                // console.log(response)
                if (response.code == 200) {
                    // Swal.fire({
                    //     title: "Success!",
                    //     text: response.response,
                    //     icon: "success"
                    // });

                    this.form.first_name = '',
                        this.form.last_name = '',
                        this.form.email = '',
                        this.form.phone = '',
                        this.form.portfolio = '',
                        this.form.linkedIn = '',
                        this.form.facebook = '',
                        this.form.cv = null,
                        this.form.job = '',

                        setTimeout(() => {
                            this.$router.push({
                                name: 'home'
                            })
                        }, 2000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
    }
};
</script>

<style scoped>
.container-fluid {
    padding: 40px 60px;
}

.background {
    background-image: url("../../../assets/images/dashboard/bg-1.jpg");
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 390px;
    background-position: center center,

}

.content-container {
    background-color: white;
    max-width: 1280px;
    padding: 20px;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin-bottom: 32px;
}

.button-container {
    width: 100%;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.rfp-container {
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.details-width {
    width: 60%;
}

.status-width {
    width: 40%;
}

.buttonStyle {
    background-color: black;
    color: #ffffff;
    padding: 10px 40px;
    border-radius: 24px;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid black;
    transition: background-color 0.3s, color 0.3s;
}

.buttonStyle:hover {
    background-color: #ffffff;
    color: black;
}

@media print {
    .button-container {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 1299px) {
    .rfp-container {
        gap: 20px;
    }
}

@media screen and (max-width: 849px) {

    .rfp-container {
        display: flex;
        flex-direction: column;
    }

    .details-width {
        width: 100%;
    }

    .status-width {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .container-fluid {
        padding: 10px 20px;
    }
}
</style>
