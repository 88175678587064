<template>
    <div>
        <!-- page-wrapper Start-->
        <div class="container-fluid p-0">
            <div class="login-card">
                <px-card>
                    <div style="padding: 28px;">
                        <div class="theme-form">
                            <div class="vendor-width">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>First Name</label>
                                            <input class="form-control" type="text" placeholder="Enter first name" v-model="candidate.first_name">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Last Name</label>
                                            <input class="form-control" type="text" placeholder="Enter last name" v-model="candidate.last_name">
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div>
                                <div class="form-group">
                                    <label>Email</label>
                                    <input class="form-control" type="text" placeholder="Enter your email address" v-model="candidate.email">
                                </div>
                            </div>
    
                            <div>
                                <div class="form-group">
                                    <label>Phone Number</label>
                                    <input class="form-control" type="text" placeholder="Enter your phone number" v-model="candidate.phone">
                                </div>
                            </div>
    
                            <div>
                                <div class="form-group">
                                    <label>Password</label>
                                    <input class="form-control" type="text" placeholder="Enter your password" v-model="candidate.password">
                                </div>
                            </div>
    
                            <div>
                                <div class="form-group">
                                    <label>Address</label>
                                    <input class="form-control" type="text" placeholder="Enter your address" v-model="candidate.address">
                                </div>
                            </div>
    
                            <div>
                                <div class="form-group">
                                    <button @click="onSubmit" class="btn btn-success mr-3">Register</button>
    
                                    <button @click="goBack()" class="btn btn-danger" href="#">Go Back</button>
    
                                </div>
                            </div>
                        </div>
    
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    
    export default {
        data() {
            return {
                candidate: {
                    first_name: null,
                    last_name: null,               
                    address: null,
                    phone: null,
                    email: null,
                    password: null,
                }
            };
        },
    
        methods: {
    
            onSubmit() {
    
                if (this.candidate.first_name === null || this.candidate.last_name === null || this.candidate.address === null || this.candidate.phone === null || this.candidate.email === null || this.candidate.password === null) {
                    Swal.fire({
                        title: "Error!",
                        text: 'All field is required!',
                        icon: "error"
                    })
                } else {
                    this.$store.dispatch("job/addCandidate", this.candidate).then(response => {
                        if (response.code == 200) {
                            Swal.fire({
                                title: "Success!",
                                text: response.response,
                                icon: "success"
                            });
    
                                this.candidate.first_name = null,
                                this.candidate.last_name = null,
                                this.candidate.address = null,
                                this.candidate.phone = null,
                                this.candidate.email = null
                                this.candidate.password = null
    
                            setTimeout(() => {
    
                                if (this.$route.query.redirect) {
                                    this.$router.push({
                                        path: '/auth/login',
                                        query: {
                                            redirect: this.$route.query.redirect
                                        }
                                    })
                                } else {
                                    this.$router.push('/auth/login')
                                }
    
                            }, 2000)
    
                        } else {
                            Swal.fire({
                                title: "Error!",
                                text: response.response,
                                icon: "error"
                            })
                        }
                    })
                }
    
            },
            goBack() {
                if (this.$route.query.redirect) {
                    this.$router.push({
                        path: '/auth/login',
                        query: {
                            redirect: this.$route.query.redirect
                        }
                    })
                } else {
                    this.$router.push('/auth/login')
                }
            }
        }
    };
    </script>
    
    <style scoped>
    .vendor-width {
        width: 399px;
    }
    
    @media screen and (max-width: 550px) {
        .vendor-width {
            width: 300px;
        }
    }
    
    @media screen and (max-width: 420px) {
        .vendor-width {
            width: 280px;
        }
    }
    </style>
    