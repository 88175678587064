<template>
    <div>
        <Breadcrumbs main="Job" title="Messages"/>

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12"></div>
            </div>
        </div>
    </div>
</template>

<script>
var primary = localStorage.getItem('primary_color') || '#FF0006';
var secondary = localStorage.getItem('secondary_color') || '#818284';

export default {
    data() {
        return {};
    }
}
</script>
  