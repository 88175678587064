<template>
<div>
    <Breadcrumbs main="My Workspace" title="Leave Details" />
    <!-- Employee Details  -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" v-if="!loading && !error">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                <div>
                                    <b-badge variant="primary" class="mb-4">
                                        <h7 style="text-align: center;font-size: 14px;">Employee Details</h7>
                                    </b-badge>
                                </div>
                                <div style="display: flex;">
                                    <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(leave_detail)" title="Reply"></feather>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Employee Name</label>
                                        <input class="form-control" type="text" placeholder="Enter grievance subject" v-model="leave_detail.employee.username" disabled>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Employee Email</label>
                                        <input class="form-control" type="text" placeholder="Enter grievance subject" v-model="leave_detail.employee.email" disabled>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Leave Type</label>
                                        <input class="form-control" type="text" placeholder="Enter grievance subject" v-model="leave_detail.leave_type.name" disabled>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Start Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="leave_detail.start_date" disabled></datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>End Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="leave_detail.end_date" disabled></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Leave Details</label>
                                        <b-form-textarea id="textarea-auto-height" placeholder="Enter asset details..." v-model="leave_detail.reason" rows="4" disabled></b-form-textarea>
                                    </div>
                                </div>
                            </div>

                            <div style="display: flex; flex-direction: row; gap: 10px;">
                                <h7>Leave Status:</h7>
                                <div>
                                    <span @click="getStatus(leave_detail.status)" v-if="leave_detail.status == 'APPROVED'">
                                        <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="success" title="Update Status">{{ leave_detail.status }}</b-badge>
                                    </span>
                                    <span @click="getStatus(leave_detail.status)" v-if="leave_detail.status == 'PENDING'">
                                        <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="primary" title="Update Status">{{ leave_detail.status }}</b-badge>
                                    </span>
                                    <span @click="getStatus(leave_detail.status)" v-if="leave_detail.status == 'DUE'">
                                        <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="danger" title="Update Status">{{ leave_detail.status }}</b-badge>
                                    </span>
                                </div>
                            </div>

                            <h6 class="mt-4 mb-2">Already Refered</h6>
                            <div class="row">
                                <div class="text-center col-md-2 w-full" v-for="user in leave_detail.refer_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                                    <div class="w-100 mt-2 badge-style">
                                        <h7>{{ user.first_name}}</h7>
                                        <b-icon icon="x" style="color: white;"></b-icon>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
            <div class="col-sm-12" v-else>
                <div class="loader-box">
                    <div class="loader-10"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- Leave Allocate Delete Modal -->
    <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this refere?</div>
    </b-modal>
    <!-- Status Update Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Update Leave Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Reply Create Modal -->

    <b-modal id="modal-scrollable" ref="modal" title="Leave Replies" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleOk">

        <h6>Already Refered</h6>
        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="user in this.my_reply.refer_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ user.first_name}}</h7>
                    <b-icon icon="x" style="color: white;"></b-icon>
                </div>
            </div>
        </div>
        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>

        <div style="margin-top: 30px;" v-if="ticket_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in ticket_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleLeaveReplySubmit()">
                <button @click="handleLeaveReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>

</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Multiselect from 'vue-multiselect';
import {
    myHost
} from '../../../../_helpers/host';
import {
    mapState
} from 'vuex';
import moment from "moment";
export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            statuses: ['DUE', 'PENDING', 'APPROVED'],
            selectedStatus: '',
            host: myHost.host,
            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            assignedMembers: [],
            filter: null,
            temp_id: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            leave_detail: state => state.leave.leave_detail,
            user_list: state => state.employee.employee_list,
            ticket_reply: state => state.leave.ticket_reply,
            loading: state => state.leave.submitLoading,
            error: state => state.leave.submitError,
        }),
    },

    mounted() {

        this.$store.dispatch("leave/fetchLeaveDetail", this.$route.params.id);
        this.$store.dispatch("employee/fetchEmployeeList");

    },
    watch: {

        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        }
    },

    methods: {
        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },
        handleLeaveReplySubmit() {

            this.$store.dispatch("leave/addLeaveReply", {
                leave: this.my_reply.id,
                reply: this.my_reply.reply
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.my_reply.id = '',
                        this.my_reply.reply = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },
        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.my_reply.refer_to = type.refer_to
            this.$store.dispatch("leave/fetchLeaveReply", this.my_reply.id)
        },
        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("leave/leaveAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })

        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {

            if (this.selectedStatus === 'APPROVED') {
                this.$store.dispatch("leave/approveLeave", {
                    id: this.$route.params.id,

                }).then(response => {

                    if (response.code == 200) {

                    } else {

                    }
                })

            }

            this.$store.dispatch("leave/updateLeaveStatus", {
                id: this.$route.params.id,
                status: this.selectedStatus
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },
        resetModal() {
            this.selectedStatus = ''
        },
        getStatus(status) {
            this.selectedStatus = status
        },

        deletetAllocation(id) {
            this.temp_id = id
        },
        handleAllocationDeleteOk() {
            this.$store.dispatch("leave/leaveDeleteRefer", {
                id: this.$route.params.id,
                refer_id: this.temp_id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center-deleteAlocation')
            })
        },

    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
