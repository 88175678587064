<template>
    <div>
        <Breadcrumbs main="UI Kits" title="Spinners"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <h5>Loader Style</h5>
                  </div>
                  <div class="card-body row">
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 1</h6>
                      <div class="loader-box">
                        <div class="loader-1"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 2</h6>
                      <div class="loader-box">
                        <div class="loader-2"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 3</h6>
                      <div class="loader-box">
                        <div class="loader-3"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 4</h6>
                      <div class="loader-box">
                        <div class="loader-4"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 5</h6>
                      <div class="loader-box">
                        <div class="loader-5"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 6</h6>
                      <div class="loader-box">
                        <div class="loader-6"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 7</h6>
                      <div class="loader-box">
                        <div class="loader-7"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 8</h6>
                      <div class="loader-box">
                        <div class="loader-8"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 9</h6>
                      <div class="loader-box">
                        <div class="loader-9"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 10</h6>
                      <div class="loader-box">
                        <div class="loader-10"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 11</h6>
                      <div class="loader-box">
                        <div class="loader-11"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 12</h6>
                      <div class="loader-box">
                        <div class="loader-12"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 13</h6>
                      <div class="loader-box">
                        <div class="loader-13"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 14</h6>
                      <div class="loader-box">
                        <div class="loader-14"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 15</h6>
                      <div class="loader-box">
                        <div class="loader-15"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 16</h6>
                      <div class="loader-box">
                        <div class="loader-40"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 17</h6>
                      <div class="loader-box">
                        <div class="loader-17"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 18</h6>
                      <div class="loader-box">
                        <div class="loader-18"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 19</h6>
                      <div class="loader-box">
                        <div class="loader-19"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 20</h6>
                      <div class="loader-box">
                        <div class="loader-20"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 21</h6>
                      <div class="loader-box">
                        <div class="loader-21"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 22</h6>
                      <div class="loader-box">
                        <div class="loader-22"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 23</h6>
                      <div class="loader-box">
                        <div class="loader-23"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 24</h6>
                      <div class="loader-box">
                        <div class="loader-24"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 25</h6>
                      <div class="loader-box">
                        <div class="loader-25"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 26</h6>
                      <div class="loader-box">
                        <div class="loader-26"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 27</h6>
                      <div class="loader-box">
                        <div class="loader-29"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 28</h6>
                      <div class="loader-box">
                        <div class="loader-30"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 29</h6>
                      <div class="loader-box">
                        <div class="loader-31"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 30</h6>
                      <div class="loader-box">
                        <div class="loader-32"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 31</h6>
                      <div class="loader-box">
                        <div class="loader-34"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 32</h6>
                      <div class="loader-box">
                        <div class="loader-35"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 33</h6>
                      <div class="loader-box">
                        <div class="loader-37"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 34</h6>
                      <div class="loader-box">
                        <div class="loader-38"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 35</h6>
                      <div class="loader-box">
                        <div class="loader-39"></div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <h6 class="sub-title mb-0 text-center">Loader 36</h6>
                      <div class="loader-box">
                        <div class="loader-40">       </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
  export default {
  };
</script>