import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'HOLIDAY_UPDATE',
    smsDelete : 'HOLIDAY_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        holiday: [],
        holiday_count:null,
        holiday_detail: {},
        



        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_HOLIDAY (state,  {results,pg_count}) {
            state.holiday = results;
            state.holiday_count = pg_count;
        },

        SET_PROJECT_DETAIL (state, holiday) {
            state.holiday_detail = holiday;
        },


        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateHoliday ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
               
                async () => {
                    response= await axios.patch(V1API.edit_holiday+payload.id,{name:payload.name, start_date:payload.start_date, end_date:payload.end_date, notice:payload.notice},config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchHoliday',{perPage:payload.perPage, currentPage:payload.page});

                }
            )
            return response
        },
    

        
        async deleteHoliday ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_holiday + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchHoliday',{perPage:payload.perPage, currentPage:payload.page});

            return response
        },

    
        async fetchHoliday ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_holiday+'?count='+payload.perPage+'&page='+payload.currentPage, config);
                const {results,pg_count} = result.data
              
                commit('SET_HOLIDAY', {results,pg_count});

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addHoliday ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            try{
                let response = await axios.post(V1API.create_holiday, payload.holiday, config).then(result=>{
                    
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchHoliday',{perPage:payload.perPage, currentPage:payload.page})
                return response

            }catch (e) {
                
                commit('SUBMIT_ERROR', true);
            }finally {
              
                commit('SUBMIT_LOADING', false);
            }
        }
    },

    getters: {}
}
