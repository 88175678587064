<template>
<div>
    <Breadcrumbs main="Project" title="Project Edit" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Project Title</label>
                                        <input class="form-control" type="text" placeholder="Enter project title *" v-model="temp_project.title">
                                    </div>
                                </div>
                                <!-- <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Client <span><button class="btn-success ml-3">Add New</button></span></label>
                                            <select class="form-control digits" v-model="project.client">
                                                <option v-for="(client, index) in clients" :key="index">{{ client }}</option>
                                            </select>
                                        </div>
                                    </div> -->
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Priority</label>
                                        <select class="form-control digits" v-model="temp_project.priority">
                                            <option v-for="(priority, index) in priorities" :key="index">{{ priority }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select class="form-control digits" v-model="temp_project.status">
                                            <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Starting date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="temp_project.start_date"></datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Deadline</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="temp_project.deadline"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Upload project file</label>
                                        <b-form-file  placeholder="Choose a file or drop it here..."  drop-placeholder="Drop file here..." @change="uploadFile"></b-form-file>
                                    </div>
                                </div>
                            </div>
                            {{ this.temp_project.project_attachment }}
                            

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Project Details</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter project details..." rows="4" v-model="temp_project.details"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Update</button>
                                        <router-link to="/project/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from "moment";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {
    mapState
} from 'vuex';
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data() {
        return {
            temp_project: {
                title: '',
                details: '',

                start_date: null,
                deadline: null,
                priority: 'HIGH',
                status: 'APPROVED',
                project_attachment: [],
            },

            priorities: ['HIGH', 'MEDIUM', 'LOW'],
            statuses: ['PENDING', 'APPROVED', 'WORKING', 'COMPLETED'],
            clients: ['Catch Bangladesh', 'BitFlex', 'PixelTag', 'Catch Digital']
        };
    },

    created() {
        this.$store.dispatch("project/fetchProjectDetails", this.$route.params.id)

    },

    computed: {
        ...mapState({
            project_detail: state => state.project.project_detail,
        })
    },
    watch: {
        project_detail(oldValue, newValue) {
            this.temp_project.title = this.project_detail.title
            this.temp_project.details = this.project_detail.details
            this.temp_project.start_date = this.project_detail.start_date
            this.temp_project.deadline = this.project_detail.deadline
            this.temp_project.priority = this.project_detail.priority
            this.temp_project.status = this.project_detail.status
            this.temp_project.project_attachment = this.project_detail.project_attachment
        }
    },

    methods: {
        uploadFile(e) {
            this.temp_project.project_attachment = e.target.files[0]

        },

        onSubmit() {
            if (this.temp_project.start_date !== null) {
                this.temp_project.start_date = moment(String(this.temp_project.start_date)).format("YYYY-MM-DD");
            }
            if (this.temp_project.deadline !== null) {
                this.temp_project.deadline = moment(String(this.temp_project.deadline)).format("YYYY-MM-DD");
            }

            this.$store.dispatch("project/updateProject", {
                id: this.$route.params.id,
                payload: this.temp_project
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                        this.temp_project.title = '',
                        this.temp_project.details = '',
                        this.temp_project.start_date = null,
                        this.temp_project.deadline = null,
                        this.temp_project.priority = 'HIGH',
                        this.temp_project.status = 'APPROVED',
                        this.temp_project.project_attachment = null

                    setTimeout(() => {
                        this.$router.push(`/project/details/${response.data.id}`)
                    }, 2000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
