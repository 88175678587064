<template>
<div>
    <Breadcrumbs main="Careers" submain="Apply for Job" title="Job Details" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5">
                <div class="card p-3" style="width: 100%;">
                    <div class="task-board-task-scroll">
                        <div style="display: flex; flex-direction: column; height: 100%; overflow-y: auto; padding-right: 12px;">
                            <div>
                                <div style="display:flex; flex-direction: column; align-items: center; justify-content: center;" class="w-full">
                                    <img style="width: 100%; border-radius: 0.75rem; object-fit: cover; background-size: cover;" :src="host+job.banner_image" class="image-height">
                                </div>

                                <div style="margin-top: 25px; display:flex; flex-direction: column; align-items: start; justify-content: left; text-align: center;">
                                    <h5 style="text-align: left;">{{ job.title }}</h5>
                                    <h5 class="mt-2">
                                        <b-badge pill variant="success">{{ job.position }}</b-badge>
                                    </h5>
                                    <div class="mt-1 d-flex">
                                        <h5>
                                            <b-badge pill variant="primary">Vacancy: {{ job.vacancies }}</b-badge>
                                        </h5>
                                        <h5 style="margin-left: 12px;">
                                            <b-badge pill variant="info">Applied: {{ job.applied }}</b-badge>
                                        </h5>
                                    </div>
                                </div>

                                <div style="margin-top: 15px; display:flex; flex-direction: column; align-items: start; justify-content: left;; text-align: center; font-size: medium;">
                                    <h7>Post Date: {{ job.opening }}</h7>
                                    <h7>Deadline: <span style="color: red;">{{ job.deadline }}</span></h7>
                                </div>
                            </div>

                            <div style="margin-top: 20px;">
                                <div v-if="showDescription">
                                    <h7 v-if="job.description.length < 450">{{ job.description }}</h7>
                                    <h7 v-else>{{ job.description.substring(0, 450) }}</h7>
                                </div>
                                <div v-else class="pb-4 lg:pb-6">
                                    <div v-html="job.description"></div>
                                </div>
                                <div v-if="job.description.length > 450">
                                    <b-button v-if="showDescription" variant="primary" size="sm" @click="displayDescription" style="margin-top: 20px;">See More</b-button>
                                    <b-button v-else variant="primary" size="sm" @click="closeDescription">See Less</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div>
                                    <h5>{{ form_data.form_name }}</h5>
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <div style="display: flex; flex-direction: column;">
                                                <div class="row">
                                                    <div class="col-md-12 grid-style">
                                                        <div v-for="(fo, index) in form_data.submission_form" :key="index">

                                                            <div>
                                                                <span>{{ fo.data_label }}</span>
                                                                <span>
                                                                    <div v-if="fo.data_type === 'filefield'">
                                                                        <div>
                                                                            <b-form-file placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="uploadFile($event, fo)"></b-form-file>
                                                                        </div>
                                                                    </div>
                                                                    <div v-else-if="fo.data_type === 'textfield'">
                                                                        <div>
                                                                            <b-form-input v-model="fo.data_value" type="text"></b-form-input>
                                                                        </div>
                                                                    </div>
                                                                    <div v-else-if="fo.data_type === 'paragraph'">
                                                                        <div>
                                                                            <textarea class="form-control" id="textarea-auto-height" rows="3" max-rows="8" v-model="fo.data_value"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row mt-4">
                                                    <div class="col">
                                                        <div class="form-group mb-0">
                                                            <button @click="onSubmit" class="btn btn-success mr-3">Submit</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

    
<script>
var primary = localStorage.getItem('primary_color') || '#FF0006';
var secondary = localStorage.getItem('secondary_color') || '#818284';
import {
    myHost
} from "../../../_helpers/host";
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
export default {
    data() {
        return {
            selected: 'a',
            host: myHost.host,
            options: [{
                    value: 'a',
                    text: 'First Option'
                },
                {
                    value: 'b',
                    text: 'Selected Option'
                },
                {
                    value: 'c',
                    text: 'Third Option'
                },
            ],
            
            search_text: '',
            selected_job: null,
            notes: '',
            tag_a_manager: null,

            managers: [{
                    value: null,
                    text: 'Tag a Manager'
                },
                {
                    value: 'a',
                    text: 'A'
                },
                {
                    value: 'b',
                    text: 'B'
                }
            ],

            showDescription: true,
            form_id: null,
            
            form_data: {
                job: null,
                form_name: "",

                submission_form: [{
                    data_label: null,
                    data_type: null
                }]
            },
        };
    },
    watch:{
        form(newValue, oldValue) {
            if (this.form !== null) {
                this.form_id = this.form[0].id
                this.form_data.rfp = this.form[0].rfp
                this.form_data.form_name = this.form[0].form_name
                this.form_data.submission_form = this.form[0].submission_form
            }
        },
    },

    methods: {
        uploadFile(e, form) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                form.data_value = e.target.result;
            };
        },

        onSubmit() {
            this.form_data.rfp = this.$route.params.id

            this.$store.dispatch("job/submitForm", {
                form_data: this.form_data.submission_form,
                form: this.form_id,
                job: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    setTimeout(() => this.$router.push('/candidate/application'), 2000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
        displayDescription() {
            this.showDescription = false
        },

        closeDescription() {
            this.showDescription = true
        }
    },
    computed: {
        ...mapState({
            job: state => state.job.job_details,
            form: state => state.job.form

        }),
    },
    mounted() {
        this.$store.dispatch("job/fetchJobDetails", this.$route.params.id);
        this.$store.dispatch("job/fetchForm", this.$route.params.id)
        
    },
}
</script>

    
<style scoped>
.image-height {
    height: 310px;
}

.applicant-view {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 25px;
}

.task-board-task-scroll {
    position: relative;
    height: 75.5vh;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media screen and (max-width: 1680px) {
    .image-height {
        height: 270px;
    }

    .applicant-view {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}

@media screen and (max-width: 991px) {
    .image-height {
        height: 290px;
    }

    .applicant-view {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 20px;
    }

    .task-board-task-scroll {
        position: relative;
        height: 100%;
    }
}

@media screen and (max-width: 730px) {
    .applicant-view {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}

@media screen and (max-width: 645px) {
    .image-height {
        height: 260px;
    }
}

@media screen and (max-width: 430px) {
    .image-height {
        height: 240px;
    }

    .applicant-view {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 20px;
    }
}
</style>
