<template>
<div>
    <Breadcrumbs main="Vendor" submain="RFQ" title="Request for Quotation" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input class="form-control" type="text" placeholder="Enter email address" v-model="quotation.email">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Currency</label>
                                        <select class="form-control digits" v-model="quotation.currency">
                                            <option v-for="(currency, index) in currencies" :key="index">{{ currency }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Client Address</label>
                                        <input class="form-control" type="text" placeholder="Enter client address" v-model="quotation.client_address">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Billing Address</label>
                                        <input class="form-control" type="text" placeholder="Enter billing address" v-model="quotation.billing_address">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Quotation Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="quotation.quotation_date"></datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Due Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="quotation.due_date"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Upload Attachment</label>
                                        <b-form-file v-model="quotation.quotation_attachment" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="uploadFile"></b-form-file>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Items</label>
                                        <ItemTable style="margin-bottom: 10px;" id="item-table" @itemsUpdated="updateItems" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <!-- Total -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Total</label>
                                        <input class="form-control" type="number" placeholder="Enter total amount" disabled v-model="quotation.total" />
                                    </div>

                                    <!-- Vat -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Vat (%)</label>
                                        <input class="form-control" type="number" placeholder="Enter vat (%)" v-model="quotation.vat" />
                                    </div>

                                    <!-- Tax -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Tax (%)</label>
                                        <input class="form-control" type="number" placeholder="Enter tax (%)" v-model="quotation.tax" />
                                    </div>

                                    <!-- Discount -->
                                    <!--                                        <div class="form-group" style="display: flex; gap: 25px; align-items: center;">-->
                                    <!--                                            <label style="margin-top: 8px; font-weight: 500; width: 120px;">Discount (%)</label>-->
                                    <!--                                            <input class="form-control" type="number" placeholder="Enter discount (%)" v-model="quotation.discount" />-->
                                    <!--                                        </div>-->

                                    <!-- Grand Total -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Grand Total ({{ quotation.currency ? quotation.currency : "BDT" }})</label>
                                        <input class="form-control" type="number" placeholder="Enter grand total" disabled v-model="quotation.subtotal" />
                                    </div>

                                    <!-- In Words -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">In Words</label>
                                        <input class="form-control" type="text" placeholder="Enter grand total in words" v-model="quotation.in_words" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Remarks</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter remarks..." rows="2" v-model="quotation.remarks"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                        <router-link to="/finance-and-accounts/finance/quotation/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

</div>
</template>

    
<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {
    computed
} from "vue";
import ItemTable from "../../quotation/item-table.vue";
import moment from "moment";

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        ItemTable
    },

    data() {
        return {
            quotation: {
                rfp:this.$route.params.id,
                email: '',
                currency: 'BDT',
                client_address: '',
                billing_address: '',
                quotation_date: null,
                due_date: null,
                details: [],
                details_in_depth: [],
                total: computed(() => {
                    let total = 0;

                    if (this.quotation.details_in_depth.length >= 1) {
                        this.quotation.details_in_depth.map((item) => {
                            total = total + item.amount;
                        });
                    }
                    return total;
                }),
                vat: null,
                tax: null,
                discount: null,
                subtotal: computed(() => {
                    if (this.quotation.total !== 0) {
                        const tax = this.quotation.tax || 0;
                        const vat = this.quotation.vat || 0;
                        const discount = this.quotation.discount || 0;
                        let gtotal =
                            this.quotation.total +
                            tax * (this.quotation.total / 100) +
                            vat * (this.quotation.total / 100) -
                            discount * (this.quotation.total / 100);
                        return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                    }
                    return 0;
                }),
                vendor_attachment: null,
                in_words: '',
                remarks: ''
            },

          
           
            currencies: ['BDT', 'USD', 'EUR'],
        };
    },

    computed: {
        ...mapState({
            items: state => state.item.items,
      
        })
    },

    mounted() {
        this.$store.dispatch("item/fetchItem")
    },

    methods: {
        updateItems(data) {
            if (data) {
                this.quotation.details_in_depth = data;
                this.quotation.details = data;
            }
        },

        uploadFile(e) {
            this.quotation.vendor_attachment = e.target.files[0];
        },

        onSubmit() {
            if (this.quotation.quotation_date !== null) {
                this.quotation.quotation_date = moment(String(this.quotation.quotation_date)).format("YYYY-MM-DD");
            }
            if (this.quotation.due_date !== null) {
                this.quotation.due_date = moment(String(this.quotation.due_date)).format("YYYY-MM-DD");
            }


            this.$store.dispatch("vendor/requestForQuotation", this.quotation).then(response => {
                console.log(response)
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    setTimeout(() => {
                        this.$router.push('/vendor/rfq')
                    }, 2000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

  
    }
};
</script>
