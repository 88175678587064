<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Quotation Edit" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Client <span><button class="btn-success ml-3" v-b-modal.modal-prevent-closing>Add New</button></span></label>
                                        <select class="form-control digits" v-model="quotation.client" @change="getClient">
                                            <option v-for="(client, index) in clients" :key="index" :value="client.user.id">{{ client.company_name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Project</label>
                                        <select class="form-control digits" v-model="quotation.project">
                                            <option v-for="(project, index) in projects" :key="index" :value="project.id">{{ project.title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input class="form-control" type="text" placeholder="Enter email address" v-model="quotation.email">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Currency</label>
                                        <select class="form-control digits" v-model="quotation.currency">
                                            <option v-for="(currency, index) in currencies" :key="index">{{ currency }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Client Address</label>
                                        <input class="form-control" type="text" placeholder="Enter client address" v-model="quotation.client_address">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Billing Address</label>
                                        <input class="form-control" type="text" placeholder="Enter billing address" v-model="quotation.billing_address">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Quotation Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="quotation.quotation_date"></datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Due Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="quotation.due_date"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Upload Attachment</label>
                                        <b-form-file v-model="quotation.quotation_attachment" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="uploadFile"></b-form-file>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Items</label>
                                        <ItemTable v-if="quotation.details" style="margin-bottom: 10px;" id="item-table" :tableData="quotation.details" @itemsUpdated="updateItems" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <!-- Total -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Total</label>
                                        <input class="form-control" type="number" placeholder="Enter total amount" disabled v-model="quotation.total" />
                                    </div>

                                    <!-- Vat -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Vat (%)</label>
                                        <div class="d-flex w-100" style="gap:16px">
                                            <input class="form-control" type="number" placeholder="Enter vat (%)" v-model="quotation.vat" />
                                            <input class="form-control" type="number" placeholder="Vat Amount" v-model="vatAmount" disabled />
                                        </div>
                                    </div>

                                    <!-- Tax -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Tax (%)</label>
                                        <div class="d-flex w-100" style="gap:16px">
                                            <input class="form-control" type="number" placeholder="Enter tax (%)" v-model="quotation.tax" />
                                            <input class="form-control" type="number" placeholder="Tax Amount" v-model="taxAmount" disabled />
                                        </div>
                                    </div>


                                    <!-- Discount -->
                                     <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Discount (%)</label>
                                        <div class="d-flex w-100" style="gap:16px">
                                            <input class="form-control" type="number" placeholder="Enter discount (%)" v-model="quotation.discount" />
                                            <input class="form-control" type="number" placeholder="Tax Amount" v-model="discountAmount" disabled/>
                                        </div>
                                    </div>


                                    <!-- Grand Total -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Grand Total ({{ quotation.currency ? quotation.currency : "BDT" }})</label>
                                        <input class="form-control" type="number" placeholder="Enter grand total" disabled v-model="quotation.subtotal" />
                                    </div>

                                    <!-- In Words -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">In Words</label>
                                        <input class="form-control" type="text" placeholder="Enter grand total in words" v-model="quotation.in_words" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Remarks</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter remarks..." rows="2" v-model="quotation.remarks"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Submit</button>
                                        <router-link to="/finance-and-accounts/finance/quotation/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>

    <!-- Container-fluid Ends-->

    <!-- Client Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add New Client" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <div class="form-group">
                <label>Name (Contact Person)</label>
                <input class="form-control" type="text" v-model="client.name">
            </div>

            <div class="form-group">
                <label>Company <span class="btn-success ml-3 p-1 rounded" v-b-modal.modal-prevent-closing-company>Add New </span></label>
                <select class="form-control digits" v-model="client.company" @change="getCompany">
                    <option v-for="(company, index) in companies" :key="index" :value="company.id">{{ company.name }}</option>
                </select>
            </div>

            <div class="form-group">
                <label>Designation</label>
                <input class="form-control" type="text" v-model="client.designation">
            </div>

            <div class="form-group">
                <label>Phone Number</label>
                <input class="form-control" type="text" v-model="client.phone">
            </div>

            <div class="form-group">
                <label>Email</label>
                <input class="form-control" type="text" placeholder="Enter email address" v-model="client.email">
            </div>

            <div class="form-group">
                <label>Address</label>
                <input class="form-control" type="text" v-model="client.address">
            </div>
        </form>
    </b-modal>

    <b-modal id="modal-prevent-closing-company" ref="modal" title="Add Company" @show="resetCompanyModal" @hidden="resetClient" @ok="handleClientOk">
        <form ref="form" @submit.stop.prevent="handleClientSubmit">
            <b-form-group label="Company Name" label-for="name-input" invalid-feedback="Company Name is required" :state="nameState">
                <b-form-input id="name-input" v-model="company.name" :state="nameState" required></b-form-input>
            </b-form-group>

            <b-form-group label="Email" label-for="name-input" invalid-feedback="Email address is required" :state="nameState">
                <b-form-input id="name-input" v-model="company.email" :state="nameState" required></b-form-input>
            </b-form-group>

            <b-form-group label="Website" label-for="name-input" invalid-feedback="Website is required" :state="nameState">
                <b-form-input id="name-input" v-model="company.website" :state="nameState" required></b-form-input>
            </b-form-group>

            <b-form-group label="Address" label-for="name-input" invalid-feedback="Company address is required" :state="nameState">
                <b-form-input id="name-input" v-model="company.address" :state="nameState" required></b-form-input>
            </b-form-group>

            <b-form-group label="BIN" label-for="name-input" invalid-feedback="BIN is required" :state="nameState">
                <b-form-input id="name-input" v-model="company.bin" :state="nameState" required></b-form-input>
            </b-form-group>

            <b-form-group label="TIN" label-for="name-input" invalid-feedback="TIN is required" :state="nameState">
                <b-form-input id="name-input" v-model="company.tin" :state="nameState" required></b-form-input>
            </b-form-group>
        </form>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {
    computed
} from "vue";
import ItemTable from "./edit-item-table.vue";
import moment from "moment";

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        ItemTable
    },

    data() {
        return {
            discountAmount: computed(() => {
                if (this.quotation.total !== 0) {
                    const discount = this.quotation.discount || 0;
                    let total = this.quotation.total + discount * (this.quotation.total / 100)
                    let gtotal = total - this.quotation.total

                    return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                }
                return 0;
            }),
            taxAmount: computed(() => {
                if (this.quotation.total !== 0) {
                    const tax = this.quotation.tax || 0;
                    let total = this.quotation.total + tax * (this.quotation.total / 100)
                    let gtotal = total - this.quotation.total

                    return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                }
                return 0;
            }),

            vatAmount: computed(() => {
                if (this.quotation.total !== 0) {
                    const vat = this.quotation.vat || 0;
                    let total = this.quotation.total + vat * (this.quotation.total / 100)
                    let gtotal = total - this.quotation.total

                    return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                }
                return 0;
            }),

            quotation: {
                client: '',
                project: '',
                email: '',
                currency: '',
                client_address: '',
                billing_address: '',
                quotation_date: null,
                due_date: null,
                details: null,
                details_in_depth: [],
                total: computed(() => {
                    let total = 0;

                    if (this.quotation.details_in_depth.length >= 1) {
                        this.quotation.details_in_depth.map((item) => {
                            total = total + item.amount;
                        });
                    }
                    return total;
                }),
                vat: null,
                tax: null,
                discount: null,
                subtotal: computed(() => {
                    if (this.quotation.total !== 0) {
                        const tax = this.quotation.tax || 0;
                        const vat = this.quotation.vat || 0;
                        const discount = this.quotation.discount || 0;
                        let gtotal =
                            this.quotation.total +
                            tax * (this.quotation.total / 100) +
                            vat * (this.quotation.total / 100) -
                            discount * (this.quotation.total / 100);
                        return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                    }
                    return 0;
                }),
                quotation_attachment: null,
                in_words: '',
                remarks: ''
            },

            client: {
                name: '',
                company: '',
                designation: '',
                phone: '',
                email: '',
                address: '',
                status: true
            },

            company: {
                name: '',
                email: '',
                website: '',
                address: '',
                bin: '',
                tin: ''
            },

            currencies: ['BDT', 'USD', 'EUR']
        };
    },

    computed: {
        ...mapState({
            detail: state => state.quotation.quotation_detail,
            items: state => state.item.items,
            projects: state => state.project.project,
            clients: state => state.client.client_without_pg,
            companies: state => state.client.company
        })
    },

    mounted() {
        this.$store.dispatch("quotation/fetchQuotationDetail", this.$route.params.id)
        this.$store.dispatch("item/fetchItem")
        this.$store.dispatch("project/fetchProject")
        this.$store.dispatch("client/fetchClient_without_pg")
        this.$store.dispatch("client/fetchCompany")
    },

    watch: {
        detail(oldValue, newValue) {
            this.quotation.client = this.detail.client.id
            this.quotation.project = this.detail.project.id
            this.quotation.email = this.detail.email
            this.quotation.currency = this.detail.currency
            this.quotation.client_address = this.detail.client_address
            this.quotation.billing_address = this.detail.billing_address
            this.quotation.quotation_date = this.detail.quotation_date
            this.quotation.due_date = this.detail.due_date
            this.quotation.quotation_attachment = this.detail.quotation_attachment
            this.quotation.details = JSON.parse(this.detail.details)
            this.quotation.details_in_depth = this.quotation.details
            this.quotation.total = this.detail.total
            this.quotation.vat = this.detail.vat
            this.quotation.tax = this.detail.tax
            this.quotation.discount = this.detail.discount
            this.quotation.subtotal = this.detail.subtotal
            this.quotation.in_words = this.detail.in_words
            this.quotation.remarks = this.detail.remarks
        }
    },

    methods: {
        getCompany() {
            this.$store.dispatch("client/fetchCompanyDetails", this.client.company).then(result => {
                this.client.address = result.data.address
            })
        },

        getClient() {
            this.$store.dispatch("client/fetchClientByUserId", this.quotation.client).then(result => {
                this.quotation.email = result.data.user.email
                this.quotation.client_address = result.data.company.address
                this.quotation.billing_address = result.data.company.address
            })
        },

        resetCompanyModal() {
            this.company.name = ''
            this.company.email = ''
            this.company.website = ''
            this.company.address = ''
            this.company.bin = ''
            this.company.tin = ''
            this.nameState = null
        },

        updateItems(data) {
            // console.log(data);
            if (data) {
                this.quotation.details = data;
                this.quotation.details_in_depth = data;
            }
        },

        uploadFile(e) {
            this.quotation.quotation_attachment = e.target.files[0];
        },

        onSubmit() {
            if (this.quotation.quotation_date !== null) {
                this.quotation.quotation_date = moment(String(this.quotation.quotation_date)).format("YYYY-MM-DD");
            }
            if (this.quotation.due_date !== null) {
                this.quotation.due_date = moment(String(this.quotation.due_date)).format("YYYY-MM-DD");
            }

            // this.quotation.details = JSON.stringify(this.quotation.details)

            // console.log(this.quotation.details)
            // alert(JSON.stringify(this.quotation.details))

            this.$store.dispatch("quotation/updateQuotation", {
                id: this.$route.params.id,
                quotation: this.quotation
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    setTimeout(() => {
                        this.$router.push('/finance-and-accounts/finance/quotation/list')
                    }, 2000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        resetModal() {
            this.client.first_name = ''
            this.client.company = '',
                this.client.designation = '',
                this.client.phone = '',
                this.client.email = '',
                this.client.address = ''
            this.nameState = null
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.$store.dispatch("client/addClient", this.client).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.quotation.client = response.data.user
                    this.quotation.email = response.data.email
                    this.quotation.client_address = response.data.address
                    this.quotation.billing_address = response.data.address

                    this.client.first_name = '',
                        this.client.company = null,
                        this.client.designation = '',
                        this.client.phone = '',
                        this.client.email = '',
                        this.client.address = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        handleClientOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleClientSubmit()
        },

        handleClientSubmit() {
            this.$store.dispatch("client/addCompany", this.company).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.client.company = response.data.id
                    this.client.address = response.data.address

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-company')
            })
        }
    }
};
</script>
