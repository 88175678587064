import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'JOB_UPDATE',
    smsDelete : 'JOB_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
      
        job_list: [],
        job_count:{},
        form: [],
        job_categories:[],
        job_details:{},
        responses:[],
        applicant_list:[],
        applicant_list_by_job:[],
        user_applicant_list:[],
        applicant_details:{},
        applicant_label_list:[],
        jobLoading: false,
        job_submission_response:{},
        jobError: false,
        jobSuccess:false,


        ...store
    },
    mutations : {
        ...mutations,

        SET_JOB (state, job) {
            state.job_list = job
        },
        SET_JOB_COUNT(state,job){
            state.job_count = job
        },
        SET_JOB_DETAILS(state,job){
            state.job_details = job
        },
        SET_JOB_FORM_RESPONSES(state,response){
            state.responses = response
        },

        
        SET_JOB_SUBMISSION_DETAILS(state, details) {
            state.job_submission_response = details;
        },

        SET_JOB_FORM (state, item) {
            state.form = item;
        },
        SET_JOB_CATEGORIES(state, JobCategory) {
            state.job_categories = JobCategory;
        },

        SET_APPLICANT_LABELS(state, label) {
            state.applicant_label_list = label;
        },

        
        
        SET_APPLICANT (state, applicant) {
            state.applicant_list = applicant
        },
        SET_APPLICANT_BY_JOB(state, applicant) {
            state.applicant_list_by_job = applicant
        },
        SET_USER_APPLICANT_LIST(state, applicantList) {
            state.user_applicant_list = applicantList
        },

        SET_APPLICANT_DETAILS(state,applicant){
            state.applicant_details = applicant
        },



        JOB_LOADING (state, loading) {
            state.jobLoading = loading;
        },
        JOB_ERROR (state, hasError) {
            state.jobError = hasError;
        },
        JOB_SUBMIT_SUCCESS (state, success) {
            state.jobSuccess = success;
        }
    },

    actions: {



        //Candidate Register
        async addCandidate ({commit, dispatch}, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            try{
                let response = await axios.post(V1API.create_candidate, payload).then(result=>{
                    let data = result.data
                    return data

                });

                commit('JOB_SUBMIT_SUCCESS', true);
                // dispatch ('fetchVendor')
                return response

            }catch (e) {
                commit('JOB_ERROR', true);
            }finally {
                commit('JOB_LOADING', false);
            }
        },
        //Job Category
        async addJobCategory ({commit, dispatch}, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
    
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
    
            try{
                let response = await axios.post(V1API.add_job_category, payload, config).then(result=>{
                    let data = result.data
                    return data
    
                });
    
                commit('JOB_SUBMIT_SUCCESS', true);
                dispatch ('fetchJobCategory')
                return response
    
            }catch (e) {
                commit('JOB_ERROR', true);
            }finally {
                commit('JOB_LOADING', false);
            }
        },
    
        async updateJobCategory ({commit, dispatch}, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
    
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
    
            try{
                let response = await axios.patch(V1API.edit_job_category+ payload.id,{title:payload.title}, config).then(result=>{
                    let data = result.data
                    return data
    
                });
    
                commit('JOB_SUBMIT_SUCCESS', true);
                dispatch ('fetchJobCategory')
                return response
    
            }catch (e) {
                commit('JOB_ERROR', true);
            }finally {
                commit('JOB_LOADING', false);
            }
        },
    
        async fetchJobCategory ({ commit }) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
    
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
    
            try{
                let result = await axios.get(V1API.get_job_categories, config);
                let response = result.data
    
                commit('SET_JOB_CATEGORIES', response.data);
    
            } catch(error) {
                commit('JOB_ERROR', true);
            }
            commit('JOB_LOADING', false);
        },
    
        
        
        async deleteJobCategory ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
    
            let response = await axios.delete(V1API.delete_job_category + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchJobCategory')
            
            return response
        },
       
        async addJob ({commit, dispatch}, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.job_create, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('JOB_SUBMIT_SUCCESS', true);
                dispatch ('fetchJob')
                return response

            }catch (e) {
                commit('JOB_ERROR', true);
            }finally {
                commit('JOB_LOADING', false);
            }
        },

        
        async fetchJobDetails ({ commit }, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_job_details + payload);
                let response = result.data

                commit('SET_JOB_DETAILS', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            finally {
                commit('JOB_LOADING', false);
            }
        },

        async addForm ({commit, dispatch}, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_job_form, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('JOB_SUBMIT_SUCCESS', true);
              
                dispatch ('fetchForm', payload.job)
                return response

            }catch (e) {
                commit('JOB_ERROR', true);
            }finally {
                commit('JOB_LOADING', false);
            }
        },
        async submitForm ({commit, dispatch}, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.job_submit_form, {
                    form_data: payload.form_data,
                    submitted_form: payload.form
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('JOB_SUBMIT_SUCCESS', true);
                dispatch ('fetchForm', payload.job)
                return response

            }catch (e) {
                commit('JOB_ERROR', true);
            }finally {
                commit('JOB_LOADING', false);
            }
        },

        async fetchForm ({ commit }, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_job_form + payload, config);
                let response = result.data

                commit('SET_JOB_FORM', response.data);
                return response

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            finally {
                commit('JOB_LOADING', false);
            }
        },

        async fetchFormResponses ({ commit }, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_job_form_responses + payload, config);
                let response = result.data

                commit('SET_JOB_FORM_RESPONSES', response.data);
                return response

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            finally {
                commit('JOB_LOADING', false);
            }
        },

        async fetchJobSubmissionDetails ({ commit }, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.job_submission_details + payload, config);
                let response = result.data

                commit('SET_JOB_SUBMISSION_DETAILS', response.data);
                return response

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            finally {
                commit('JOB_LOADING', false);
            }
        },

        async deleterfpFormResonse ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_job_form_responses +payload.response_id, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchFormResponses',payload.job_id);

            return response
        },


        async fetchJob ({ commit }) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_job_list);
                let response = result.data
                commit('SET_JOB', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            finally {
                commit('JOB_LOADING', false);
            }
        },


        async fetchJobCount ({ commit }) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_job_count, config);
                let response = result.data
                commit('SET_JOB_COUNT', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            finally {
                commit('JOB_LOADING', false);
            }
        },


        
        async updateJob({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.update_job+payload.id,payload.payload,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchJob');

                }
            )
            return response
        },


        async deleteJob({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_job + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchJob');
            
            return response
        },

        async updateJobStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.update_ticket_status+ payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data

                })
                dispatch("fetchJob")
                return response
            } catch (e) {

                commit('JOB_ERROR', true);
            } finally {

                commit('JOB_LOADING', false);
            }
        },


        //Applicant
    
        async fetchApplicantDetails ({ commit }, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_applicant_details + payload, config);
                let response = result.data

                commit('SET_APPLICANT_DETAILS', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            commit('JOB_LOADING', false);
        },
        async fetchApplicant({ commit }) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_applicant, config);
                let response = result.data
                commit('SET_APPLICANT', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            commit('JOB_LOADING', false);
        },
        async fetchApplicantListByJob({ commit },payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_applicant_list_by_job + payload, config);
                let response = result.data
                commit('SET_APPLICANT_BY_JOB', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            commit('JOB_LOADING', false);
        },

        async fetchUserApplicantList ({ commit }) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
    
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
    
            try{
                let result = await axios.get(V1API.get_user_applicant_list, config);
                let response = result.data
    
                commit('SET_USER_APPLICANT_LIST', response.data);
    
            } catch(error) {
                commit('JOB_ERROR', true);
            }
            commit('JOB_LOADING', false);
        },

        async addApplicant ({commit, dispatch}, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{
                
                let data= new FormData()
                let payloadData = buildFormData(data, payload);
        
                let response = await axios.post(V1API.create_applicant, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('JOB_SUBMIT_SUCCESS', true);
              
                return response

            }catch (e) {
                
                commit('JOB_ERROR', true);
            }finally { 
                commit('JOB_LOADING', false);
            }
        },

        //Applicant Label
        async addApplicantLabel({commit, dispatch}, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.create_applicant_label, {
                    name: payload
                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('JOB_SUBMIT_SUCCESS', true);
                dispatch ('fetchApplicantLabels')
                return response

            }catch (e) {
                commit('JOB_ERROR', true);
            }finally {
                commit('JOB_LOADING', false);
            }
        },
        // async deleteApplicantLabel({commit, dispatch}, payload) {
        //     commit('JOB_LOADING', true);
        //     commit('JOB_ERROR', false);
        //     let config = {
        //         headers: {
        //           Authorization: "Bearer " + localStorage.getItem("access_token"),
        //         },
        //     };
        //     try{
        //         let response = await axios.post(V1API.delete_applicant_label, {
        //             name: payload
        //         }, config).then(result=>{
        //             let data = result.data
        //             return data
        //         });

        //         commit('JOB_SUBMIT_SUCCESS', true);
        //         dispatch ('fetchApplicantLabels')
        //         return response

        //     }catch (e) {
        //         commit('JOB_ERROR', true);
        //     }finally {
        //         commit('JOB_LOADING', false);
        //     }
        // },

        async deleteApplicantLabel ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_applicant_label + payload, config).then(result=>{
                let data = result.data
                return data
            })
          
            dispatch ('fetchApplicantLabels')
            return response
        },

        async fetchApplicantLabels ({ commit }) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_applicant_label, config);
                let response = result.data

                commit('SET_APPLICANT_LABELS', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            commit('JOB_LOADING', false);
        },  
        
        async addLabelToApplicant({commit, dispatch}, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_label_to_applicant+payload.applicant_id, {
                    label_id: payload.label_id
                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('JOB_SUBMIT_SUCCESS', true);
                dispatch ('fetchApplicantListByJob',payload.job_id)
                return response

            }catch (e) {
                commit('JOB_ERROR', true);
            }finally {
                commit('JOB_LOADING', false);
            }
        },

        async deleteLabelFromApplicant({commit, dispatch}, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.delete_applicant_from_label+payload.applicant_id, {
                    label_id: payload.label_id
                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('JOB_SUBMIT_SUCCESS', true);
                dispatch ('fetchApplicantListByJob',payload.job_id)
                return response

            }catch (e) {
                commit('JOB_ERROR', true);
            }finally {
                commit('JOB_LOADING', false);
            }
        },


    






    },

    getters: {}
}
