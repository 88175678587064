import { userService } from '../../_services';
import router from '../../router/index';
import axios from 'axios'
import UserAuth from "@/auth/js";

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };

export const authentication = {
    namespaced: true,

    state: initialState,

    getters: {

    },

    actions: {
        async login({ dispatch, commit }, { email, passwordjwt }) {
            commit('loginRequest', { email });
            let response = await userService.login(email, passwordjwt)
                .then(
                    user => {
                        if(user.data.code!=401){
                            commit('loginSuccess', user);
                            // console.log(user)

                            const redirectPath = router.currentRoute.query.redirect || '/';
                            router.push(redirectPath);


                            // router.push('/');

                        }else{
                            let error = user.data
                            commit('loginFailure', error.message);
                            dispatch('alert/error', error.message, { root: true });

                            return error
                        }

                        // console.log(user)
                        return user.data
                    },
                    error => {
                        // console.log(error)

                        commit('loginFailure', error.message);
                        dispatch('alert/error', error.message, { root: true });
                    }
                );

                return response
        },

        logout({ commit }) {
            console.log("alsohere")
            userService.logout();
            UserAuth.Logout();
            router.replace('/auth/login');
            commit('logout');
        } ,
       async verify_token({dispatch},payload) {
            // console.log(payload)
            let response= await userService.verify(payload).then(data=>{
               return data

            });
            console.log("resoponse from authentication js", response)
            if(response){
                return response
            }else{
                dispatch('logout')
            }




        }
    },

    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },

        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },

        loginFailure(state) {
            state.status = {};
            state.user = null;
        },

        logout(state) {
            state.status = {};
            state.user = null;
        }
    }
};
