import Vue from 'vue';
import Vuex from 'vuex';
// import 'es6-promise/auto';
import layout from './modules/layout';
import menu from './modules/menu';
import chat from './modules/chat';
import products from './modules/products';
import email from './modules/email';
import todo from './modules/todo';
import firebase_todo from './modules/firebase_todo';
import jobs from './modules/jobs';
import courses from './modules/courses';
import common from './modules/common';
import { alert } from './modules/alert';
import { authentication } from './modules/authentication';
import { users } from './modules/users';

// SMICEE
import project from './modules/smicee/project';
import myUpdates from './modules/smicee/myUpdates';
import client from './modules/smicee/client';
import task from './modules/smicee/task';
import holiday from './modules/smicee/holiday';
import vendor from './modules/smicee/vendor';
import quotation from './modules/smicee/quotation';
import item from './modules/smicee/item';
import lead from './modules/smicee/lead';
import rfp from './modules/smicee/rfp';
import account from './modules/smicee/account';
import asset from './modules/smicee/assest';
import policy from './modules/smicee/policy';
import compliance from './modules/smicee/compliance';
import budget from './modules/smicee/estimatedBudget';
import grievance from './modules/smicee/grivence';
import training from './modules/smicee/training';
import requisition from './modules/smicee/requisition';
import invoice from './modules/smicee/invoice';
import leave from './modules/smicee/leave';
import purchase_order from './modules/smicee/purchaseOrder';
import bill from './modules/smicee/bill';
import ticket from './modules/smicee/ticket';
import job from './modules/smicee/job'
import department from './modules/smicee/department';
import designation from './modules/smicee/designation';
import employee from './modules/smicee/employee';
import resetPassword from './modules/smicee/resetPassword';
import eod from './modules/smicee/eod';
import organization from './modules/smicee/organization';
import onBoarding from './modules/smicee/onBoarding';
import dashboard from './modules/smicee/dashboard';
import payments from './modules/smicee/payments';
import payroll from './modules/smicee/payroll';
import punch from './modules/smicee/punch';
import bank from './modules/smicee/bank';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {},

    mutations: {
        changeLang (state, payload) {
            localStorage.setItem('currentLanguage', payload.id);
            localStorage.setItem('currentLanguageIcon', payload.icon);
            window.location.reload();
        }
    },

    actions: {
        setLang ({ commit }, payload) {
            commit('changeLang', payload);
        }
    },

    modules: {
        alert,
        authentication,
        users,
        layout,
        chat,
        menu,
        products,
        email,
        todo,
        firebase_todo,
        jobs,
        courses,
        common,

        // SMICEE
        project,
        client,
        task,
        holiday,
        vendor,
        quotation,
        item,
        lead,
        rfp,
        account,
        budget,
        asset,
        policy,
        compliance,
        grievance,
        training,
        requisition,
        invoice,
        leave,
        purchase_order,
        bill,
        ticket,
        job,
        myUpdates,
        designation,
        department,
        employee,
        resetPassword,
        eod,
        organization,
        onBoarding,
        dashboard,
        payments,
        payroll,
        punch,
        bank
    }
});

