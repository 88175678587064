<template>
  <div>
    <Breadcrumbs main="" title="Billing"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">


        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <button class="btn btn-primary" type="submit">Add Fund</button>
            </div>

            <div class="card-body">
              <b-row>
                <b-col xl="6">

                  <b-input-group class="datatable-btn">
                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>

                </b-col>

                <b-col xl="6">
                  <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue">
                <b-table
                    show-empty
                    stacked="md"
                    :items="items"
                    :fields="tablefields"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                >
                  <template #cell(old_balance)="row">
                    {{ row.value }} BDT
                  </template>
                  <template #cell(cr_dr_amount)="row">
                    {{ row.value }} BDT
                  </template>
                  <template #cell(new_balance)="row">
                    {{ row.value }} BDT
                  </template>
                </b-table>
              </div>

              <b-col md="6" class="my-1 p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="my-0"
                ></b-pagination>
              </b-col>


            </div>

          </div>
        </div>

      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>

export default {
  data() {
    return {
      tablefields: [
        {key: 'recharge_date', label: 'Recharge Date', sortable: true,},
        {key: 'type', label: 'Type', sortable: true,},
        {key: 'old_balance', label: 'Old Balance', sortable: true,},
        {key: 'cr_dr_amount', label: 'CR/DR Amount', sortable: true,},
        {key: 'new_balance', label: 'New Balance', sortable: true,},
        {key: 'validity', label: 'Validity', sortable: true,},
      ],

      items: [
        {recharge_date: '07 Jun 2022', type: 'Credit', old_balance: '4.40 ', cr_dr_amount: '1.00', new_balance: '5.40 ',  validity: '05 Dec 2022',  },


      ],

      filter: null,

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>
