<template>
<div>
    <Breadcrumbs main="HR" title="Response" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div>
                            <h5>Response</h5>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div style="display: flex; flex-direction: column;">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div v-for="(item, index) in responses" :key="index">
                                                    <!-- <div style="display: flex; gap: 20px;">
                                                            <div>
                                                                <span>{{ responses.indexOf(item) + 1 }}.</span>
                                                            </div>
                                                            <div>
                                                                <div v-for="(info, index) in item.form_data" :key="index">
                                                                    <div style="display: flex; gap: 16px; margin-bottom: 5px;">
                                                                        <span>{{ info.data_label }}:</span>
                                                                        <h6 v-if="info.data_type == 'filefield'">
                                                                            <a :href="host + info.data_value" target="_blank">{{ host + info.data_value }}</a>
                                                                        </h6>
                                                                        <h6 v-else>{{ info.data_value }}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style="display: flex; justify-content: flex-end;">
                                                                <feather type="trash" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(item.id)" title="Delete"></feather>
                                                            </div>
                                                        </div> -->
                                                    <div class="accordion" role="tablist">
                                                        <b-card no-body class="mb-1">
                                                            <b-card-header header-tag="header" class="p-1" role="tab">
                                                                <b-button block v-b-toggle="'accordion-' + index" variant="primary">
                                                                    <div style="display: flex; flex-direction: column; gap: 5px;">
                                                                        <!-- <h7>Response - {{ responses.indexOf(item) + 1 }}</h7> -->
                                                                        <h7>{{ item.submitted_by.first_name }} {{ item.submitted_by.last_name }}</h7>

                                                                    </div>
                                                                </b-button>
                                                            </b-card-header>
                                                            <b-collapse visible  :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
                                                                <b-card-body>
                                                                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                        <div>
                                                                            <div v-for="(info, index) in item.form_data" :key="index">
                                                                                <div style="display: flex; gap: 16px; margin-bottom: 5px;">
                                                                                    <span>{{ info.data_label }}:</span>
                                                                                    <h6 v-if="info.data_type == 'filefield'">
                                                                                        <a :href="host + info.data_value" target="_blank">{{ host + info.data_value }}</a>
                                                                                    </h6>
                                                                                    <h6 v-else>{{ info.data_value }}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(item.id)" title="Delete"></feather>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this response?</div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {myHost} from "../../../../_helpers/host";
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data() {
        return {
            host:myHost.host,
            delete_value: '',
        }
    },

    computed: {
        ...mapState({
            responses: state => state.policy.responses
        })
    },

    mounted() {
        this.$store.dispatch("policy/fetchFormResponses", this.$route.params.id)
    },

    watch: {
        responses(newValue, oldValue) {}
    },
    methods: {
        deleteItem(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("policy/deletePolicyFormResonse", {
                response_id: this.delete_value,
                policy_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
