import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        accounts: [],
        active_accounts: [],
        account_detail: {},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_BANK_ACCOUNTS (state, items) {
            state.accounts = items;
        },

        SET_ACTIVE_BANK_ACCOUNTS (state, items) {
            state.active_accounts = items;
        },

        SET_ACCOUNT_DETAILS (state, details){
            state.account_detail = details;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateBankAccount ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_bank + payload.id, payload.account, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchBankAccounts');
            return response
        },

        async deleteBankAccount ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.bank_delete + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchBankAccounts')
            return response
        },

        async fetchBankAccounts ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.bank_list, config);
                let response = result.data

                commit('SET_BANK_ACCOUNTS', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchActiveBankAccounts ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.active_bank_list, config);
                let response = result.data

                commit('SET_ACTIVE_BANK_ACCOUNTS', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addBankAccount ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.bank_create, payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchBankAccounts')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchBankAccountDetails ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.bank_details + payload, config);
                let response = result.data

                commit('SET_ACCOUNT_DETAILS', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async toggleActiveStatus ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.get(V1API.toggle_active + payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchBankAccounts')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        //Attachment
        async addBankAttachment ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };
            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.attachment);
        
                let response = await axios.post(V1API.bank_add_attachment, payloadData, config).then(result=>{
                  
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch("fetchBankAccountDetails",payload.bank_info)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async deleteAttachmentFromBank ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.bank_delete_attachment + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchBankAccountDetails',payload.bank_info)
            
            return response
        },
    },

    getters: {}
}
