<template>
<div>
    <Breadcrumbs main="Procurement" title="RFP" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header header-mobile">
                        <router-link to="/procurement/rfp/create/new">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i>
                                <h7>Add New RFP</h7>
                            </button>
                        </router-link>

                        <router-link to="/procurement/rfp/category/list">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-solid fa-bars"></i>
                                <h7>RFP Category</h7>
                            </button>
                        </router-link>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-column flex-xl-row " style="gap: 10px;">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <!-- {{ rfp_category_list }} -->

                                    <div class="d-flex mt-3 mt-sm-0">
                                        <select class="form-control" v-model="rfp_category_id">
                                            <option value="" disabled selected>Sort by Category</option>
                                            <option v-for="(item, index) in rfp_category_list" :key="index" :value="item.id">{{ item.title }}</option>
                                        </select>
                                    </div>
                                </div>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="displayedRFPs" :fields="tablefields" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(rfp_id)="data">
                                        <span>
                                            <router-link :to="`/procurement/rfp/${data.item.id}`" class="link-style">
                                                {{ data.item.rfp_id }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(rfp_category)="data">
                                        <span v-if="data.item.rfp_category !== null">
                                            {{ data.item.rfp_category.title }}
                                        </span>
                                    </template>

                                    <template #cell(status)="row">
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'COMPLETED'">
                                            <b-badge pill variant="success" v-b-modal.modal-prevent-closing-status>{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'WORKING'">
                                            <b-badge pill variant="info" v-b-modal.modal-prevent-closing-status>{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'APPROVED'">
                                            <b-badge pill variant="primary" v-b-modal.modal-prevent-closing-status>{{ row.value }}</b-badge>
                                        </span>
                                    </template>

                                    <!-- <template #cell(form_submit)="data">
                                    <span v-if="data.item.form_generated == true">
                                        <router-link :to="`/procurement/rfp/submit-form/${data.item.id}`">
                                            <b-button variant="secondary" style="width: 125px;">View Form</b-button>
                                        </router-link>
                                    </span>
                                </template> -->

                                    <template #cell(form_generated)="data">
                                        <div style="display: flex; flex-direction: row; gap: 5px; justify-content: center; align-items: center;">
                                            <div>
                                                <span v-if="data.item.form_generated == true">
                                                    <router-link :to="`/procurement/rfp/edit-form/${data.item.id}`">
                                                        <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                    </router-link>
                                                </span>
                                                <span v-else>
                                                    <router-link :to="`/procurement/rfp/create-form/${data.item.id}`">
                                                        <feather type="plus-circle" size="20px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Add"></feather>
                                                    </router-link>
                                                </span>
                                            </div>
                                            <div>
                                                <span v-if="data.item.form_generated == true">
                                                    <router-link :to="`/procurement/rfp/responses/${data.item.generated_form}`">

                                                        <feather type="message-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Response"></feather>

                                                    </router-link>
                                                </span>
                                            </div>
                                        </div>

                                    </template>

                                    <template #cell(active_status)="data">

                                        <div v-if="data.item.active == true" @click="activeStatus(data.item.id)" style="cursor: pointer;">
                                            <b-badge pill variant="success" v-b-tooltip.hover title="Toggle status">Active</b-badge>
                                        </div>
                                        <div v-if="data.item.active == false" @click="activeStatus(data.item.id)" style="cursor: pointer;">
                                            <b-badge pill variant="danger" v-b-tooltip.hover title="Toggle status">Inactive</b-badge>
                                        </div>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteRFP(data.item.id)" title="Delete"></feather>
                                            <router-link :to="`/procurement/rfp/edit/${data.item.id}`">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                            </router-link>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this RFP?</div>
    </b-modal>

    <!-- Update Status -->
    <b-modal id="modal-prevent-closing-status" ref="modal" title="Update RFP Status" @show="resetModal" @hidden="resetModal" @ok="handleStatusOk">
        <form ref="form" @submit.stop.prevent="handleStatusSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import js from '../../../auth/js';

export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'rfp_id',
                    label: 'RFP ID',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'rfp_category',
                    label: 'Category',
                    sortable: true
                },
                {
                    key: 'deadline',
                    label: 'Deadline',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },

                {
                    key: 'form_generated',
                    label: 'Form',
                    sortable: true,
                    class: 'text-center'

                },

                {
                    key: 'active_status',
                    label: 'Active Status',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            filter: null,
            rfp_category_id: '',
            delete_value: null,
            rfp_update_id: '',

            statuses: ['COMPLETED', 'WORKING', 'APPROVED'],
            selectedStatus: '',

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            items: state => state.rfp.rfp,
            count: state => state.rfp.count,
            rfp_category_list: state => state.rfp.rfp_category,
            loading: state => state.rfp.submitLoading,
            error: state => state.rfp.submitError,
        }),

        rfp() {
            return this.$store.state.rfp.rfp;
        },

        filteredRFPs() {

            return this.items.filter(rfp => rfp.rfp_category.id === this.rfp_category_id);
        },

        displayedRFPs() {
            return this.rfp_category_id ? this.filteredRFPs : this.rfp;
        },

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {
        this.$store.dispatch("rfp/fetchRFPCategory");

        this.$store.dispatch("rfp/fetchRFP", {
            perPage: this.$route.params.perPage,
            currentPage: this.$route.params.page
        }).then(() => {
            this.totalRows = this.count;
            this.currentPage = parseInt(this.$route.params.page)
            this.perPage = parseInt(this.$route.params.perPage)

        });

    },

    watch: {
        currentPage(newCurrentPage) {
            if (this.$route.params.page !== newCurrentPage.toString()) {
                this.$router.push(`/procurement/rfp/list/${newCurrentPage}/${this.perPage}`);
                this.currentPage = newCurrentPage
                this.$store.dispatch("rfp/fetchRFP", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage
                }).then(() => {
                    this.totalRows = this.count;
                });
            }

        },

        perPage(newperPage) {
            if (this.$route.params.perPage !== newperPage.toString()) {
                this.$router.push(`/procurement/rfp/list/${this.currentPage}/${newperPage}`);
                this.perPage = newperPage
                this.$store.dispatch("rfp/fetchRFP", {
                    perPage: newperPage,
                    currentPage: this.currentPage
                }).then(() => {
                    this.totalRows = this.count;
                });
            }

        },
    },

    methods: {
        resetModal() {
            this.selectedStatus = ''
        },
        getStatus(status, id) {
            this.rfp_update_id = id
            this.selectedStatus = status
        },

        sortByCategory() {

        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteRFP(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("rfp/deleteRFP", {
                delete: this.delete_value,
                page: this.$route.params.page,
                perPage: this.$route.params.perPage,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        activeStatus(id) {

            this.$store.dispatch("rfp/toggleRFPStatus", {
                id: id,
                page: this.$route.params.page,
                perPage: this.$route.params.perPage,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.udate_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        handleStatusOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleStatusSubmit()
        },

        handleStatusSubmit() {

            // alert(JSON.stringify(this.rfp_update_id))
            // alert(JSON.stringify(this.selectedStatus))
            this.$store.dispatch("rfp/updateRfpStatus", {
                id: this.rfp_update_id,
                status: this.selectedStatus,
                page: this.$route.params.page,
                perPage: this.$route.params.perPage,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-status')
            })
        },

    }
};
</script>

<style scoped>
.header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 460px) {
    .header-mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: start;
    }
}
</style>
