import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    
    state : {
        vendor: [],
        vendor_without_pg: [],
        vendor_count: null,

        vendorLoading: false,
        vendorError: false,

        vendorRFQ: [],
        vendorsubmitSuccess: false,

        label: [],
        vendor_projects: [],
        vendor_project_detail: {},
        vendor_po_by_project: [],
        vendor_bill_by_project: [],
        purchase_order_list: [],
        bill_list: [],
        vendor_categories: [],

        vendorContact: [],
        vendorContact_count: null,
        vendorDetails: {},
        vendor_by_user: {},

        ...store
    },

    mutations : {
        ...mutations,

        SET_VENDOR_CATEGORIES(state, vendorCategory) {
            state.vendor_categories = vendorCategory;
        },

        SET_VENDOR (state, {results,pg_count}) {
            state.vendor = results;
            state.vendor_count = pg_count;
        },

        SET_VENDOR_WITHOUT_PG (state, results) {
            state.vendor_without_pg = results;
        },

        SET_VENDOR_RFQ(state, rfq) {
            state.vendorRFQ = rfq;
        },

        SET_VENDOR_CONTACT (state, {results,pg_count}) {
            state.vendorContact = results;
            state.vendorContact_count = pg_count;
        },
        
        SET_VENDOR_DETAILS (state, details) {
            state.vendorDetails = details;
        },

        SET_VENDOR_DETAILS_BY_USER_ID (state, details) {
            state.vendor_by_user = details;
        },

        SET_VENDOR_CONTACT_LABELS (state, label) {
            state.label = label;
        },

        SET_VENDOR_PURCHASE_ORDER_LIST(state, po) {
            state.purchase_order_list = po;
        },
        
        SET_VENDOR_BILL_LIST(state, bill) {
            state.bill_list = bill;
        },

        SET_VENDOR_PO_BY_PROJECT(state, po) {
            state.vendor_po_by_project = po;
        },

        SET_VENDOR_BILL_BY_PROJECT(state, bill) {
            state.vendor_bill_by_project = bill;
        },

        SET_VENDOR_PROJECT_DETAILS(state, details) {
            state.vendor_project_detail = details;
        },

        SET_VENDOR_PROJECTS(state, project) {
            state.vendor_projects = project;
        },

        VENDOR_LOADING (state, loading) {
            state.vendorLoading = loading;
        },

        VENDOR_ERROR (state, loading) {
            state.vendorError = loading;
        },

        VENDOR_SUBMIT_SUCCESS (state, success) {
            state.vendorsubmitSuccess = success;
        }
    },

    actions: {
        //vendor
        async updateVendor ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.vendor_edit+payload.id,{phone:payload.phone, company:payload.company, address:payload.address,category:payload.category},config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchVendor',{currentPage:payload.page,perPage:payload.perPage});
                }
            )
            return response
        },

        async deleteVendor ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_vendor + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchVendor',{currentPage:payload.page,perPage:payload.perPage});
            
            return response
        },

        async updateVendorStatus ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.toggle_vendor_status + payload.status, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchVendor',{currentPage:payload.page,perPage:payload.perPage});
            
            return response
        },

        async fetchVendor ({ commit },payload) {
            commit('VENDOR_LOADING', true);
            commit('VENDOR_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_vendor+'?count='+payload.perPage+'&page='+payload.currentPage, config);
                const {results,pg_count} = result.data

                commit('SET_VENDOR', {results,pg_count});

            } catch(error) {
                commit('VENDOR_ERROR', true);
            }
            finally {
                commit('VENDOR_LOADING', false);
            }
           
        }, 

        async fetchVendor_without_pg ({ commit },payload) {
            commit('VENDOR_LOADING', true);
            commit('VENDOR_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_vendor_without_pg, config);
                let response = result.data.data

                commit('SET_VENDOR_WITHOUT_PG', response);

            } catch(error) {
                commit('VENDOR_ERROR', true);
            }
            finally {
                commit('VENDOR_LOADING', false);
            }
           
        },

        async fetchVendorByUserId ({ commit },payload) {
            commit('VENDOR_LOADING', true);
            commit('VENDOR_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.vendor_by_user_id + payload, config);
                let response = result.data

                commit('SET_VENDOR_DETAILS_BY_USER_ID', response.data); 
                return response

            } catch(error) {
                commit('VENDOR_ERROR', true);
            }finally {
                commit('VENDOR_LOADING', false);
            }

            return response
        },

        async fetchVendorDetails ({ commit },payload) {
            commit('VENDOR_LOADING', true);
            commit('VENDOR_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.venodr_details+payload, config);
                let response = result.data

                commit('SET_VENDOR_DETAILS', response.data);

            } catch(error) {
                commit('VENDOR_ERROR', true);
            }
            finally {
                commit('VENDOR_LOADING', false);
            }
           
        },


        async addVendor ({commit, dispatch}, payload) {
            commit('VENDOR_LOADING', true);
            commit('VENDOR_ERROR', false);

            try{
                let response = await axios.post(V1API.create_vendor, payload).then(result=>{
                    let data = result.data
                    return data

                });

                commit('VENDOR_SUBMIT_SUCCESS', true);
                dispatch ('fetchVendor_without_pg')
                dispatch ('fetchVendor')
                return response

            }catch (e) {
                commit('VENDOR_ERROR', true);
            }finally {
                commit('VENDOR_LOADING', false);
            }
        },
        
         //Vendor Contact

        async addVendorContact ({commit, dispatch}, payload) {
            commit('VENDOR_LOADING', true);
            commit('VENDOR_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.vendor_contact_create, payload.add_item, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('VENDOR_SUBMIT_SUCCESS', true);
                dispatch ('fetchVendorContact',{currentPage:payload.page,perPage:payload.perPage})
                return response

            }catch (e) {
                commit('VENDOR_ERROR', true);
            }finally {
                commit('VENDOR_LOADING', false);
            }
        },

        async addVendorContactLabel({commit, dispatch}, payload) {
            commit('VENDOR_LOADING', true);
            commit('VENDOR_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.vendor_contact_label_create, {
                    name: payload
                }, config).then(result=>{
                    let data = result.data
                    return data

                });


                commit('VENDOR_SUBMIT_SUCCESS', true);
                // dispatch ('fetchVendorContact')
                dispatch ('fetchVendorContactLabel')
                return response

            }catch (e) {
                commit('VENDOR_ERROR', true);
            }finally {
                commit('VENDOR_LOADING', false);
            }
        },

        
        
        async fetchVendorContactLabel ({ commit }) {
            commit('VENDOR_LOADING', true);
            commit('VENDOR_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.venodr_contact_label_lists, config);
                let response = result.data

                commit('SET_VENDOR_CONTACT_LABELS', response.data);

            } catch(error) {
                commit('VENDOR_ERROR', true);
            }
            finally {
                commit('VENDOR_LOADING', false);
            }
            
        },


        async deleteVendorContactLabel ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.vendor_label_delete + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchVendorContactLabel')
            // dispatch ('fetchVendorContact')
            
            return response
        },



        async addLabeltoVendorContact ({ commit, dispatch }, payload) {
            commit('VENDOR_LOADING', true);
            commit('VENDOR_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.post(V1API.vendor_contact_label_add + payload.vendor, {
                    label_id: payload.label_id
                }, config)

                let response = result.data

                   
            // dispatch ('fetchVendorContactLabel')
            dispatch ('fetchVendorContact',{currentPage:payload.page,perPage:payload.perPage})

                return response

            } catch(error) {
                commit('VENDOR_ERROR', true);
            }
            finally {
                commit('VENDOR_LOADING', false);
            }
            
        },
        async deleteLabeltoVendorContact ({ commit, dispatch }, payload) {
            commit('VENDOR_LOADING', true);
            commit('VENDOR_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.post(V1API.vendor_contact_label_delete + payload.vendor, {
                    label_id: payload.label_id
                }, config)

                let response = result.data

                   
            // dispatch ('fetchVendorContactLabel')
            dispatch ('fetchVendorContact',{currentPage:payload.page,perPage:payload.perPage})

                return response

            } catch(error) {
                commit('VENDOR_ERROR', true);
            }
            finally {
                commit('VENDOR_LOADING', false);
            }
           
        },






        async fetchVendorContact ({ commit },payload) {
            commit('VENDOR_LOADING', true);
            commit('VENDOR_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_vendor_contact+'?count='+payload.perPage+'&page='+payload.currentPage, config);
                const {results,pg_count} = result.data

                commit('SET_VENDOR_CONTACT', {results,pg_count});

            } catch(error) {
                commit('VENDOR_ERROR', true);
            }
            finally {
                commit('VENDOR_LOADING', false);
            }
           
        },

        async updateVendorContact ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.vendor_contact_edit+payload.id,{first_name:payload.first_name, last_name:payload.last_name, email:payload.email, phone:payload.phone, company:payload.company, designation:payload.designation},config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchVendorContact',{currentPage:payload.page,perPage:payload.perPage});

                }
            )
        
            return response
        },
        async deleteVendorContat ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_vendor_contact + payload.delete_id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchVendorContact',{currentPage:payload.page,perPage:payload.perPage});
            
            return response
        },

    // Vendor Category

    async addVendorCategory ({commit, dispatch}, payload) {
        commit('VENDOR_LOADING', true);
        commit('VENDOR_ERROR', false);

        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
        };

        try{
            let response = await axios.post(V1API.add_vendor_category, payload, config).then(result=>{
                let data = result.data
                return data

            });

            commit('SUBMIT_SUCCESS', true);
            dispatch ('fetchVendorCategory')
            return response

        }catch (e) {
            commit('VENDOR_ERROR', true);
        }finally {
            commit('VENDOR_LOADING', false);
        }
    },

    async updateVendorCategory ({commit, dispatch}, payload) {
        commit('VENDOR_LOADING', true);
        commit('VENDOR_ERROR', false);

        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
        };

        try{
            let response = await axios.patch(V1API.edit_vendor_category+ payload.id,{title:payload.title}, config).then(result=>{
                let data = result.data
                return data

            });

            commit('SUBMIT_SUCCESS', true);
            dispatch ('fetchVendorCategory')
            return response

        }catch (e) {
            commit('VENDOR_ERROR', true);
        }finally {
            commit('VENDOR_LOADING', false);
        }
    },

    async fetchVendorCategory ({ commit }) {
        commit('VENDOR_LOADING', true);
        commit('VENDOR_ERROR', false);

        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            }
        };

        try{
            let result = await axios.get(V1API.get_vendor_categories, config);
            let response = result.data

            commit('SET_VENDOR_CATEGORIES', response.data);

        } catch(error) {
            commit('VENDOR_ERROR', true);
        }
        finally {
            commit('VENDOR_LOADING', false);
        }
      
    },

    
    
    async deleteVendorCategory ({commit, dispatch}, payload) {
        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            }
        };

        let response = await axios.delete(V1API.delete_vendor_category + payload, config).then(result=>{
            let data = result.data
            return data
        })
        
        dispatch ('fetchVendorCategory')
        
        return response
    },


        
    //Vendor Requst for Quotation


    async requestForQuotation ({commit, dispatch}, payload) {
        commit('VENDOR_LOADING', true);
        commit('VENDOR_ERROR', false);

        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
            "Content-Type": "multipart/form-data"
        };

        try{



            payload.details=JSON.stringify(payload.details)
            console.log(payload.details)


            let data2= new FormData()
            let payloadData = buildFormData(data2, payload);
           

            let response = await axios.post(V1API.vendor_request_for_quotation, payloadData, config).then(result=>{
                // let data = decode(result.data)
                let data = result.data
                return data

            });

            commit('VENDOR_SUBMIT_SUCCESS', true);
          
            return response

        }catch (e) {
            console.log(e)
            commit('VENDOR_ERROR', true);
        }finally {
           
            commit('VENDOR_LOADING', false);
        }
    },



  
            
    async fetchVendorRFQ ({ commit }) {
        commit('VENDOR_LOADING', true);
        commit('VENDOR_ERROR', false);
        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            }
        };
        try{
            let result = await axios.get(V1API.vendor_rfq_list, config);
            let response = result.data

            commit('SET_VENDOR_RFQ', response.data);

        } catch(error) {
            commit('VENDOR_ERROR', true);
        }
        finally {
            commit('VENDOR_LOADING', false);
        }
      
    },


    // Vendor Projects

    async fetchProjectHasVendor ({ commit},payload) {
        commit('VENDOR_LOADING', true);
        commit('VENDOR_ERROR', false);
        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            }
        };
        try{
            let result = await axios.get(V1API.project_list_by_vendor+payload, config);
            let response = result.data

            commit('SET_VENDOR_PROJECTS', response.data);

        } catch(error) {
            commit('VENDOR_ERROR', true);
        }
        finally {
            commit('VENDOR_LOADING', false);
        }
        
    },

    
    async fetchVendorProjectDetails ({ commit },payload) {
        commit('VENDOR_LOADING', true);
        commit('VENDOR_ERROR', false);

        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            }
        };

        try{
            let result = await axios.get(V1API.vendor_project_details+payload, config);
            let response = result.data

            commit('SET_VENDOR_PROJECT_DETAILS', response.data);

        } catch(error) {
            commit('VENDOR_ERROR', true);
        }
        finally {
            commit('VENDOR_LOADING', false);
        }
        
    },


    async fetchVendorPurchaseOrderbyProject ({ commit },payload) {
        commit('VENDOR_LOADING', true);
        commit('VENDOR_ERROR', false);

        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            }
        };

        try{
            let result = await axios.get(V1API.vendor_purchase_order_by_project+payload+'/'+'?vendorid=90', config);
            let response = result.data

            commit('SET_VENDOR_PO_BY_PROJECT', response.data);

        } catch(error) {
            commit('VENDOR_ERROR', true);
        }
        finally {
            commit('VENDOR_LOADING', false);
        }
       
    },


    async fetchVendorBillbyProject ({ commit },payload) {
        commit('VENDOR_LOADING', true);
        commit('VENDOR_ERROR', false);

        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            }
        };

        try{
            let result = await axios.get(V1API.vendor_bill_by_project+payload+'/'+'?vendorid=90', config);
            let response = result.data

            commit('SET_VENDOR_BILL_BY_PROJECT', response.data);

        } catch(error) {
            commit('VENDOR_ERROR', true);
        }
        finally {
            commit('VENDOR_LOADING', false);
        }
        
    },


    async fetchVendorPurchaseOrder ({ commit },payload) {
        commit('VENDOR_LOADING', true);
        commit('VENDOR_ERROR', false);

        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            }
        };

        try{
            let result = await axios.get(V1API.po_list_by_vendor, config);
            let response = result.data

            commit('SET_VENDOR_PURCHASE_ORDER_LIST', response.data);

        } catch(error) {
            commit('VENDOR_ERROR', true);
        }
        finally {
            commit('VENDOR_LOADING', false);
        }
       
    },


    async fetchVendorBill ({ commit },payload) {
        commit('VENDOR_LOADING', true);
        commit('VENDOR_ERROR', false);

        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            }
        };

        try{
            let result = await axios.get(V1API.bill_list_by_vendor, config);
            let response = result.data

            commit('SET_VENDOR_BILL_LIST', response.data);

        } catch(error) {
            commit('VENDOR_ERROR', true);
        }
        finally {
            commit('VENDOR_LOADING', false);
        }
      
    },








    
    




    },



   


    



    getters: {}
}
