<template>
    <div class="table-container">

        <div class="table-responsive datatable-vue">
            <b-table
                show-empty
                stacked="md"
                @change="emitChange"
                :items="state.details"
                :fields="state.fields"
            >
                <template #cell(id)="row">
                    {{ row.index+1 }}
                </template>

                <template #cell(item_name)="row">
                    <input class="form-control" type="text" placeholder="Enter item" v-model="row.item.item_name">
                </template>

                <template #cell(unit_cost)="row">
                    <input @change="calculateAmount(row.item.id)" class="form-control" type="number" placeholder="Enter unit cost" v-model="row.item.unit_cost">
                </template>

                <template #cell(item_quantity)="row">
                    <input @change="calculateAmount(row.item.id)" class="form-control" type="number" placeholder="Enter item quantity" v-model="row.item.item_quantity">
                </template>

                <template #cell(amount)="row">
                    <input class="form-control" type="number" placeholder="0" disabled v-model="row.item.amount">
                </template>

                <template #cell(action)="row">
                    
                    <button @click="removeItem(row.item.id)" class="btn btn-primary" type="submit">Delete</button>
                </template>
            </b-table>
        </div>

        <div>
            <b-button variant="primary" @click="addItem">Add Item</b-button>
        </div>
    </div>
</template>
  
<script>
import { computed, defineComponent, reactive } from "vue";

export default defineComponent ({
    name: "item-table",

    setup(props, { emit }) {
        const state = reactive({
            currentId: computed(() => state.details.length),
            details: [
                {
                    id: 1,
                    item_name: null,
                    unit_cost: null,
                    item_quantity: null,
                    amount: null,
                },
            ],
            fields: [
                { key: 'id', label: '#' },
                { key: 'item_name', label: 'Item', sortable: false },
                { key: 'unit_cost', label: 'Unit Price', sortable: false },
                { key: 'item_quantity', label: 'Quantity', sortable: false },
                { key: 'amount', label: 'Amount', sortable: false },
                { key: 'action', label: 'Action', sortable: false }
            ],
        });

        // Calculate Amount
        const calculateAmount = (id) => {
            state.details.map((item) => {
                if (item.id === id) {
                    item.amount = item.unit_cost * item.item_quantity;
                    return item;
                }
                return item;
            });

            emitChange();
        };
    
        // Add item
        const addItem = () => {
            state.details.push({
                id: state.currentId + 1,
                item_name: null,
                unit_cost: null,
                item_quantity: null,
                amount: null,
            });
        };

        // Remove Item
        const removeItem = (id) => {
            const temp = state.details.filter((item) => item.id !== id);
            state.details = temp;
            calculateId();
            emitChange();
        };
    
        // Calculate ID
        const calculateId = () => {
            let id = 0;
            state.details.map((item) => {
                id++;
                item.id = id;
                return item;
            });
        };

        // Emit change
        const emitChange = () => {
            console.log(state.details)
            emit("itemsUpdated", state.details);
        };
    
        return {
            state,
            addItem,
            removeItem,
            calculateAmount,
            emitChange,
        };
    }
})
</script>
  
<style scoped>
.table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
</style>
  