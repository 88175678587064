<template>
<div>
    <Breadcrumbs main="HR" submain="My Workspace" title="My Assets" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="my_asset" :fields="tablefields" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(asset_name)="data">

                                        <span>
                                            <router-link :to="`/my-workspace/my-asset/details/${data.item.asset.id}`" class="link-style">
                                                {{data.item.asset.name}}
                                            </router-link>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },

    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'asset_name',
                    label: 'Asset',
                    sortable: true
                },

                {
                    key: 'asset.asset_id',
                    label: 'Asset ID',
                    sortable: true
                },

                {
                    key: 'allocation_date',
                    label: 'Allocation Date',
                    sortable: true
                }
            ],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    watch: {
        currentPage(newCurrentPage) {
            if (this.$route.params.page !== newCurrentPage.toString()) {
                this.$router.push(`/my-workspace/my-assets/${newCurrentPage}/${this.perPage}`);
                this.currentPage = newCurrentPage
                this.$store.dispatch("asset/fetchMyAsset", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage
                }).then(() => {
                    this.totalRows = this.count;
                });
            }

        },

        perPage(newperPage) {
            if (this.$route.params.perPage !== newperPage.toString()) {
                this.$router.push(`/my-workspace/my-assets/${this.currentPage}/${newperPage}`);
                this.perPage = newperPage
                this.$store.dispatch("asset/fetchMyAsset", {
                    perPage: newperPage,
                    currentPage: this.currentPage
                }).then(() => {
                    this.totalRows = this.count;
                });
            }

        },

    },

    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        ...mapState({
            my_asset: state => state.asset.get_my_asset,
            loading: state => state.asset.submitLoading,
            error: state => state.asset.submitError,

        }),

    },

    mounted() {
        this.$store.dispatch("asset/fetchMyAsset", {
            perPage: this.$route.params.perPage,
            currentPage: this.$route.params.page
        }).then(() => {
            this.totalRows = this.count;
            this.currentPage = parseInt(this.$route.params.page)
            this.perPage = parseInt(this.$route.params.perPage)

        });

    },

    methods: {
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    }
};
</script>
