<template>
<div>
    <Breadcrumbs main="HR" submain="Leave Management" title="Leave Create" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                        
                          
                            <div class="justify-content-center align-items-center" v-if="leave_remaining">
                                <div class="task-board row">
                                    <div class="d-flex flex-row ">
                                        <b-card style="width: 8rem; background-color: #fff0f0;" class="ml-4" v-for="(value, key) in leave_remaining" :key="key">
                                            <b-card-text style="display: flex; justify-content: center; align-items: center;">
                                                <div style="display: flex; flex-direction: column;">
                                                    <span style="text-align: center; font-weight: 500; font-size: 12px;" v-b-tooltip.hover :title="value.name">{{ value.name.substring(0, 11) }} </span>
                                                    <span style="text-align: center; font-weight: 600; font-size: 14px;"> {{ value.days }} days</span>
                                                </div>
                                            </b-card-text>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">
                                    <div class="form-group">   
                                        <label>Leave Request of</label>         
                                        <select style="height: 44px;" class="form-control digits" v-model="leave.refer_of">
                                            <option v-for="(type, index) in user_list" :key="index" :value="type.id">{{ type.user_name }}</option>    
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Leave Type</label>
                                        <!-- :value=type.id -->
                                        <select style="height: 44px;" class="form-control digits" v-model="leave.leave_type">
                                            <option v-for="(type, index) in leave_type" :key="index" :value="type">{{ type.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Referring To</label>
                                        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Subject</label>
                                        <!-- <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="leave.subject"></datepicker> -->
                                        <input type="text" v-model="leave.subject" class="form-control" placeholder="Enter subject" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Start date</label>
                                                <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="leave.start_date"></datepicker>
                                            </div>
                                        </div>
                                        <div class="col-md-6" v-if="leave.start_date">
                                            <div class="form-group">
                                                <label>End Date</label>
                                                <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="leave.end_date" :disabled-dates="disabledDates"></datepicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Reason</label>
                                        <b-form-textarea v-model="leave.reason" rows="3" placeholder="Enter your reason"></b-form-textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Submit</button>
                                        <router-link to="/my-workspace/apply-for-leave">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>

    <!-- Container-fluid Ends-->
</div>
</template>

    
<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";
import Multiselect from 'vue-multiselect';
import {
    mapState
} from 'vuex';

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect
    },

    data() {
        return {
            leave: {
                refer_of:'',
                start_date: null,
                end_date: null,
                subject: '',
                reason: '',
                leave_type: '',
                refer_to: [],
            },

            calculatedDate: null,
            dateRange: null,

            assignedMembers: [],
            // disabledDates: {
            //     from: new Date(this.RangeSeparatedDate.year, this.RangeSeparatedDate.month - 1, this.RangeSeparatedDate.day),
            // }

        };
    },
    watch: {
        'leave.leave_type': 'calculateEndDate',
        'leave.refer_of': 'leaveRemaining',

        'leave.start_date': function (newStartDate, oldStartDate) {
            this.calculateEndDate();
        },

        'leave.end_date': function (newEndDate, oldEndDate) {
            if (newEndDate > this.calculatedDate) {

                Swal.fire({
                    title: "Error!",
                    text: 'End Date Exceeds Allowable Duration.',
                    icon: "error"
                });

                this.leave.end_date = null;
            }
        }
    },

    methods: {
        leaveRemaining(){
            this.leave.start_date = null;
            this.leave.end_date = null;
            this.$store.dispatch("leave/fetchLeaveRemainingUser",this.leave.refer_of);
        },
        calculateEndDate() {

            if (this.leave.leave_type && this.leave.start_date) {
                const remainingLeave = this.leave_remaining[this.leave.leave_type.name];
                if (remainingLeave) {
                    const startDate = new Date(this.leave.start_date);
                    const endDate = new Date(startDate.getTime() + (remainingLeave.days * 24 * 60 * 60 * 1000));
                    this.calculatedDate = endDate;
                    this.dateRange = moment(String(this.calculatedDate)).format("YYYY-MM-DD")
                }
            }
        },

        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.leave.project_file = e.target.result;
            };
        },

        onSubmit(event) {
            if (this.leave.start_date !== null) {
                this.leave.start_date = moment(String(this.leave.start_date)).format("YYYY-MM-DD");
            }
            if (this.leave.end_date !== null) {
                this.leave.end_date = moment(String(this.leave.end_date)).format("YYYY-MM-DD");
            }

            const idArray = this.assignedMembers.map(item => this.leave.refer_to.push(
                item.id
            ));
            this.leave.leave_type = this.leave.leave_type.id

            this.$store.dispatch("leave/leaveApplyByAdmin", this.leave).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                        this.leave.refer_of = '',
                        this.leave.start_date = '',
                        this.leave.end_date = '',
                        this.leave.subject = '',
                        this.leave.reason = '',
                        this.leave.leave_type = ''
                        this.leave.refer_to = ''

                    setTimeout(() => {
                        this.$router.push('/hr/leave-management/leave-requests')
                    }, 3000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            event.preventDefault()

        }
    },

    computed: {
        ...mapState({
            leave_type: state => state.leave.leaveTypeList,
            user_list: state => state.employee.employee_list,
            leave_remaining: state => state.leave.leave_remaining_of_user
        }),

        RangeSeparatedDate() {
            if (this.dateRange) {
                const dateParts = this.dateRange.split('-');
                const year = dateParts[0];
                const month = dateParts[1];
                const day = dateParts[2];
                return {
                    year,
                    month,
                    day
                };
            } else {
                return {
                    year: null,
                    month: null,
                    day: null
                };
            }
        },

        disabledDates() {
            return {
                from: this.RangeSeparatedDate.year ? new Date(this.RangeSeparatedDate.year, this.RangeSeparatedDate.month - 1, parseInt(this.RangeSeparatedDate.day)) : null
            };

        }
    },

    mounted() {
        this.$store.dispatch("leave/fetchLeaveType");
        this.$store.dispatch("employee/fetchEmployeeList");
        
    }
};
</script>

    
<style scoped>
.task-board {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}

::-webkit-scrollbar {
    height: 5px;
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
