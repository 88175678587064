<template>
<div>
    <Breadcrumbs main="Job" title="Job Update" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Job Title</label>
                                        <input class="form-control" type="text" placeholder="Enter job title" v-model="temp_job.title">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Category</label>
                                        <select class="form-control digits" v-model="temp_job.category">
                                            <option v-for="(category, index) in job_category" :key="index" :value="category.id">{{ category.title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Job Position</label>
                                        <input class="form-control" type="text" placeholder="Enter job position" v-model="temp_job.position">
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Job Type</label>
                                        <select class="form-control digits" v-model="temp_job.job_type">
                                            <option v-for="(status, index) in job_types" :key="index">{{ status }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select class="form-control digits" v-model="temp_job.status">
                                            <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Vacancy</label>
                                        <input class="form-control" type="number" placeholder="Enter job vacancy" v-model="temp_job.vacancies">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Location</label>
                                        <input class="form-control" type="text" placeholder="Enter job location" v-model="temp_job.location">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Salary Start Range</label>
                                        <input class="form-control" type="number" placeholder="Enter salary start range" v-model="temp_job.salary_start" step="1000">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Salary End Range</label>
                                        <input class="form-control" type="text" placeholder="Enter salary end range" v-model="temp_job.salary_end" step="1000">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Post date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="temp_job.opening"></datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Deadline</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="temp_job.deadline"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="flex items-center">

                                        <img v-if="show_image" style="height: 60px; width: 100px;" :src="show_image">
                                        <input :class="temp_job.banner_image ? 'ml-2' : 'ml-2'" type="file" accept="image/*" @change="uploadFile">
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Job Details</label>
                                        <b-form-textarea id="textarea-auto-height" placeholder="Enter job details..." v-model="temp_job.description" rows="4" max-rows="13"></b-form-textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Update</button>
                                        <router-link to="/job/job/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from "moment";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {
    mapState
} from "vuex";
import {
    myHost
} from "../../../_helpers/host";
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data() {
        return {
            temp_job: {
                title: '',
                position: '',
                location: '',
                category: '',
                vacancies: '',
                salary_start: '',
                salary_end: '',
                job_type: '',
                status: '',
                opening: null,
                deadline: null,
                description: '',
                banner_image: null
            },
            host: myHost.host,
            show_image:null,

            statuses: ['OPEN', 'CLOSED'],
            job_types: ['FULL-TIME', 'PART-TIME', 'INTERN', 'REMOTE', 'CONTRACT']
        };
    },

    watch: {
        job_details(oldValue, newValue) {
            this.temp_job.title = this.job_details.title
            this.temp_job.position = this.job_details.position
            this.temp_job.location = this.job_details.location
            this.temp_job.vacancies = this.job_details.vacancies
            this.temp_job.salary_start = this.job_details.salary_start
            this.temp_job.salary_end = this.job_details.salary_end
            this.temp_job.job_type = this.job_details.job_type
            this.temp_job.status = this.job_details.status
            this.temp_job.opening = this.job_details.opening
            this.temp_job.deadline = this.job_details.deadline
            this.temp_job.description = this.job_details.description
            this.temp_job.banner_image = this.job_details.banner_image
            this.temp_job.category = this.job_details.category

            this.show_image = this.job_details.banner_image? this.host + this.job_details.banner_image : null

        }
    },
    computed: {
        ...mapState({
            job_details: state => state.job.job_details,
            job_category: state => state.job.job_categories,
        })
    },

    mounted() {
        this.$store.dispatch('job/fetchJobDetails', this.$route.params.id);
        this.$store.dispatch("job/fetchJobCategory");
    },

    methods: {
        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.temp_job.banner_image = e.target.result;
            };
        },

        onSubmit(event) {
            if (this.temp_job.opening !== null) {
                this.temp_job.opening = moment(String(this.temp_job.opening)).format("YYYY-MM-DD");
            }
            if (this.temp_job.deadline !== null) {
                this.temp_job.deadline = moment(String(this.temp_job.deadline)).format("YYYY-MM-DD");
            }

            this.$store.dispatch("job/updateJob", {
                id: this.$route.params.id,
                payload: this.temp_job,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                         this.temp_job.title = '',
                        this.temp_job.position = '',
                        this.temp_job.location = '',
                        this.temp_job.vacancies = '',
                        this.temp_job.salary_start = '',
                        this.temp_job.salary_end = '',
                        this.temp_job.job_type = '',
                        this.temp_job.status = '',
                        this.temp_job.opening = null,
                        this.temp_job.deadline = null,
                        this.temp_job.description = '',
                        this.temp_job.banner_image = null

                    setTimeout(() => {
                        this.$router.push('/job/job/list')
                    }, 3000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
