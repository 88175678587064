import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import compliance from './compliance';


const keyList = {
    smsUpdate: 'ONBOARD_UPDATE',
    smsDelete: 'ONBOARD_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {

        policy_reamins:false,
        compliance_remains:false,

        onBoardingType:[],
        onBoardCompliance:[],
        onBoardPolicy:[],
        complianceFormByEmployee:[],
        policyFormByEmployee:[],
        isSubmitted:null,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        complianceSubmissionCheck:{},
        policySubmissionCheck:{},
        policyisSubmitted:null,

        ...store
    },
    mutations: {
        ...mutations,

        SET_ON_BOARD_REMAINS(state,{policy_submit_remains,compliance_submit_remains}){
            state.policy_reamins = policy_submit_remains,
            state.compliance_remains = compliance_submit_remains
        },
        SET_ONBOARDING_TYPE(state, type) {
            state.onBoardingType = type;
          
        },
        SET_COMPLIANCE_SUBMISSION_CHECK(state, {data,submitted}) {
            state.complianceSubmissionCheck = data;
            state.isSubmitted = submitted;
          
        },


        SET_POLICY_SUBMISSION_CHECK(state, {data,submitted}) {
            state.policySubmissionCheck = data;
            state.policyisSubmitted = submitted;
          
        },
        SET_ONBOARD_COMPLIANCE(state, compliance) {
            state.onBoardCompliance = compliance;
        },
        SET_ONBOARD_POLICY(state, policy) {
            state.onBoardPolicy = policy;
        },
        SET_COMPLIANCE_FORM_BY_EMPLOYEE(state, form) {
            state.complianceFormByEmployee = form;
        },

        SET_POLICY_FORM_BY_EMPLOYEE(state, form) {
            state.policyFormByEmployee = form;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },


    },

    actions: {
        
        
        async fetchonBoardRemains({commit},payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.on_board_submission_remains, config);
                const {policy_submit_remains,compliance_submit_remains} = result.data

                commit('SET_ON_BOARD_REMAINS', {policy_submit_remains,compliance_submit_remains});

            } catch (error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async fetchPolicySubmissionCheck({commit},payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.on_board_policy_submission_check + payload, config);
                const {data,submitted} = result.data

                commit('SET_POLICY_SUBMISSION_CHECK', {data,submitted});

            } catch (error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },



        async fetchComplianceSubmissionCheck({commit},payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.on_board_compliance_submission_check + payload, config);
                const {data,submitted} = result.data

                commit('SET_COMPLIANCE_SUBMISSION_CHECK', {data,submitted});

            } catch (error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },



        async fetchPolicyFormByEmployee({commit},payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
 
                if(!payload){
                    let result = await axios.get(V1API.policy_form_list_by_employee, config);
                    let response = result.data.policy_forms_data
                    commit('SET_POLICY_FORM_BY_EMPLOYEE', response);
                }
                if(payload){
                    let result = await axios.get(V1API.policy_form_list_by_employee+'?userid='+payload, config);
                    let response = result.data.policy_forms_data
                    commit('SET_POLICY_FORM_BY_EMPLOYEE', response);
                }


            } catch (error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async fetchComplianceFormByEmployee({commit},payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                if(!payload){
                    let result = await axios.get(V1API.compliance_form_list_by_employee, config);
                    let response = result.data.compliance_forms_data
                    commit('SET_COMPLIANCE_FORM_BY_EMPLOYEE', response);
                }
                if(payload){
                    let result = await axios.get(V1API.compliance_form_list_by_employee+'?userid='+payload, config);
                    let response = result.data.compliance_forms_data
                    commit('SET_COMPLIANCE_FORM_BY_EMPLOYEE', response);
                }
             
            } catch (error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },



        async fetchOnBoardCompliance({commit}) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.on_board_compliance_list, config);
                let response = result.data.compliance_forms_data

                commit('SET_ONBOARD_COMPLIANCE', response);

            } catch (error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchOnBoardPolicy({commit}) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.on_board_policy_list, config);
                let response = result.data.policy_forms_data

                commit('SET_ONBOARD_POLICY', response);

            } catch (error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        





        async fetchOnBoardTypeList({commit}) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.on_boarding_list, config);
                let response = result.data.data

                commit('SET_ONBOARDING_TYPE', response);

            } catch (error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        



        
        async addonBoardPolicy({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },

            };
            try{

                let response = await axios.post(V1API.on_board_policy_create, 
                    {
                    onboarding_flow:payload.onboarding_flow, 
                    policy:payload.policy
                    }
                    , config).then(result=>{

                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchOnBoardPolicy');
               
                return response

            }catch (e) {

                commit('SUBMIT_ERROR', true);
            }finally {

                commit('SUBMIT_LOADING', false);
            }
        },


        async addonBoardCompliance({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },

            };
            try{

                let response = await axios.post(V1API.on_board_compliance_create, 
                    {
                    onboarding_flow:payload.onboarding_flow, 
                    compliance:payload.compliance
                    }
                    , config).then(result=>{

                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchOnBoardCompliance');
                return response

            }catch (e) {

                commit('SUBMIT_ERROR', true);
            }finally {

                commit('SUBMIT_LOADING', false);
            }
        },




        async deleteonBoardCompliance ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.on_board_compliance_delete + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchOnBoardCompliance');

            return response
        },

        async deleteonBoardPolicy ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.on_board_policy_delete + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchOnBoardPolicy');

            return response
        },




        






    },

    getters: {}
}
