import { render, staticRenderFns } from "./leave-create.vue?vue&type=template&id=406e0031&scoped=true&"
import script from "./leave-create.vue?vue&type=script&lang=js&"
export * from "./leave-create.vue?vue&type=script&lang=js&"
import style0 from "./leave-create.vue?vue&type=style&index=0&id=406e0031&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "406e0031",
  null
  
)

export default component.exports