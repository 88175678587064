import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import random from "vue-rate-it/glyphs/random";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        task_category: [],
        task_list: [],
        comments: [],
        sub_task_list: [],
        project_detail: {},
        task_detail: {},

        taskLoading: false,
        taskError: false,

        submitLoading: false,
        submitError: false,

        taskCommentLoading:false,
        taskCommentError:false,


        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_TASK_CATEGORY (state, task_category) {
            state.task_category = task_category;
        },

        SET_TASK_LIST (state, list) {
            state.task_list = list;
        },

        SET_TASK_DETAILS (state, list) {
            state.task_detail = list;
        },

        SET_COMMENTS (state, list) {
            state.comments = list;
        },

        SET_SUB_TASK_LIST (state, list) {
            state.sub_task_list = list;
        },

        TASK_LOADING (state, loading) {
            state.taskLoading = loading;
        },
        TASK_ERROR (state, hasError) {
            state.taskError = hasError;
        },

        TASK_COMMENT_LOADING(state, loading) {
            state.taskCommentLoading = loading;
        },
        TASK_COMMENT_ERROR (state, hasError) {
            state.taskCommentError = hasError;
        },


        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

       

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async fetchTaskCategory ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_task_category + payload, config);
                let response = result.data
                // console.log(response.data.tasks)
                response.data.labels.map((label)=>{
                     response.data.tasks.push(
                         {
                            complete:false,
                            description :null,
                            end_date:null,
                            id:Math.random(),
                            label:label,
                            parent:0,
                            priority:"1",
                            progress:null,
                            project:1,
                            start_date :null,
                            status:label,
                            teammates:[],
                            title:'Add New'
                        }
                    )
                })
                response.data.labels.push("Add New")
                // response.data.tasks.push({title: "Add New"})
                commit('SET_TASK_CATEGORY', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
           
        },

        async addTaskCategory ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.create_task_category, {
                    name: payload.name,
                    project: payload.project

                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchTaskCategory', payload.project)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async editTaskLabel ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.update_task_label, {
                    old_label: payload.old_label,
                    new_label: payload.new_label,
                    project_id: payload.project_id

                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchTaskCategory', payload.project_id)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        
        async deleteLabel ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.delete_task_label, {
                    name: payload.name,
                    project_id: payload.project_id

                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchTaskCategory', payload.project_id)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async toggleTaskPriority ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.get(V1API.toggle_task_priority + payload.id + '/' + payload.priority, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchTaskCategory', payload.project)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async toggleSubTaskStatus ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.get(V1API.toggle_task_complete_status + payload.id, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchSubTaskList', payload.parent)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async toggleSubTaskCompletefromProject ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.get(V1API.toggle_subtask_complete_status + payload.id, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchSubTaskList', payload.parent)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateTask ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.patch(V1API.update_task + payload.id, payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchTaskDetails', payload.id)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addTask ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.create_task_with_label,payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchTaskCategory', payload.project)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addSubTask ({commit, dispatch}, payload) {
            commit('TASK_LOADING', true);
            commit('TASK_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.create_task, payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchSubTaskList', payload.parent)
                return response

            }catch (e) {
                commit('TASK_ERROR', true);
            }finally {
                commit('TASK_LOADING', false);
            }
        },

        async updateSubTask ({commit, dispatch}, payload) {
            commit('TASK_LOADING', true);
            commit('TASK_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.patch(V1API.update_task + payload.sub.id, payload.sub, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchSubTaskList', payload.parent)
                return response

            }catch (e) {
                commit('TASK_ERROR', true);
            }finally {
                commit('TASK_LOADING', false);
            }
        },

        async deleteSubTask ({commit, dispatch}, payload) {
            commit('TASK_LOADING', true);
            commit('TASK_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.delete(V1API.delete_task + payload.id, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchSubTaskList', payload.parent)
                return response

            }catch (e) {
                commit('TASK_ERROR', true);
            }finally {
                commit('TASK_LOADING', false);
            }
        }, 

        async deleteTask ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.delete(V1API.delete_task + payload.id, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchTaskCategory', payload.project_id)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchSubTaskList ({commit}, payload) {
            commit('TASK_LOADING', true);
            commit('TASK_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.get(V1API.get_sub_task_list + payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SET_SUB_TASK_LIST', response.data);

            }catch (e) {
                commit('TASK_ERROR', true);
            }finally {
                commit('TASK_LOADING', false);
            }
        },

        async fetchTaskDetails ({commit}, payload) {
            commit('TASK_LOADING', true);
            commit('TASK_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.get(V1API.get_task_details + payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SET_TASK_DETAILS', response.data);

                return response

            }catch (e) {
                commit('TASK_ERROR', true);
            }finally {
                commit('TASK_LOADING', false);
            }
        },

        async updateTaskLabel ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.update_task_with_label, {
                    pk:payload.id,
                    label:payload.label,
                    project_id:payload.project

                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchTaskCategory', payload.project)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async assignEmployee({commit,dispatch}, payload) {
            commit('TASK_LOADING', true);
            commit('TASK_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.assign_task_employee, {
                    task_id: payload.task_id,
                    subtask_id: payload.subtask_id,
                    member_id: payload.member_id
                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchSubTaskList', payload.task_id)
                dispatch ('fetchTaskDetails', payload.task_id)
                return response

            }catch (e) {
                commit('TASK_ERROR', true);
            }finally {
                commit('TASK_LOADING', false);
            }
        },

        async deleteAssignedMember ({commit, dispatch}, payload) {
            commit('TASK_LOADING', true);
            commit('TASK_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.delete(V1API.delete_assigned_employee + payload.id, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchTaskDetails', payload.task)
                return response

            }catch (e) {
                commit('TASK_ERROR', true);
            }finally {
                commit('TASK_LOADING', false);
            }
        },

        // Comments
        async fetchComments ({commit}, payload) {
            commit('TASK_COMMENT_LOADING', true);
            commit('TASK_COMMENT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.get(V1API.get_comments + payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SET_COMMENTS', response.data);

            }catch (e) {
                commit('TASK_COMMENT_ERROR', true);
            }finally {
                commit('TASK_COMMENT_LOADING', false);
            }
        },

        async addComment ({commit, dispatch}, payload) {
            commit('TASK_COMMENT_LOADING', true);
            commit('TASK_COMMENT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.create_comment, {
                    task_id: payload.task_id,
                    comment: payload.comment

                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchComments', payload.task_id)
                return response

            }catch (e) {
                commit('TASK_COMMENT_ERROR', true);
            }finally {
                commit('TASK_COMMENT_LOADING', false);
            }
        },
    },

    getters: {}
}
