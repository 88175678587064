<template>
<div>
    <div class="background d-flex justify-content-center align-items-center">
        <h4 class="text-center">Tender Details</h4>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <!-- <div class="card-header">
                        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;">
                            <div style="display: flex; align-items: center;">
                                <h5>Tender Details</h5>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div> -->
                    <div class="card-body">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-sm-8">
                                <div slot="with-padding">
                                    <div class="form theme-form">
                                        <div class="d-flex justify-content-between mb-4">
                                            <div>
                                                <b-badge variant="success" v-if="details.rfp_category !== null">{{ details.rfp_category.title }}</b-badge>
                                            </div>
                                            <div>
                                                <b-badge pill variant="danger">{{ details.deadline }}</b-badge>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <h6>Title</h6>
                                                    <div v-html="details.title"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <h6>Details</h6>
                                                    <div v-html="details.details"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="d-flex justify-content-between ">
                                            <h6 class="mt-4 mb-3">Supporting Documents</h6>
                                        </div>

                                        <div class="row">
                                            <div class="col">
                                                <b-table show-empty sticky-header :items="details.rfp_attach" :fields="Attachment" head-variant="light">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>

                                                    <template #cell(title)="data">
                                                        <a :href="host+data.item.rfp_attachment" target="_blank">{{ data.item.rfp_attachment }}</a>
                                                    </template>
                                                </b-table>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="mt-4" v-if="details.generated_form !== null">
                                        <b-button variant="dark" style="width: 125px;" @click="goToSubmitPage(details.id)">Submit</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>

<!-- <b-form-textarea id="textarea-rows" placeholder="Tall textarea" rows="5" v-model="details.details" disabled></b-form-textarea> -->
</template>

<script>
import {
    mapState
} from "vuex";
import Datepicker from 'vuejs-datepicker';
import {
    myHost
} from "../../../_helpers/host";
import 'sweetalert2/src/sweetalert2.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    props: ["id", "download"],

    components: {
        Datepicker
    },

    data() {
        return {
            editor: ClassicEditor,
            host: myHost.host,

            loading: true,
            downloadPdf: false,
            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                }
            ]
        };
    },

    methods: {
        goToSubmitPage(id) {
            const isLoggedIn = !!(localStorage.getItem('user'));
            if (isLoggedIn) {
                this.$router.push(`/procurement/rfp/submit-form/${id}`)
            } else {
                this.$router.push({
                    path: '/auth/login',
                    query: {
                        redirect: `/procurement/rfp/submit-form/${id}`
                    }
                })
            }
        },
        printPdf(section) {
            this.downloadPdf = true;
            // window.print();

            var printContents = document.getElementById(section).innerHTML;
            var originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;

            window.print();

            document.body.innerHTML = originalContents;
        },

        parseDate(date) {
            return new Date(Date.parse(date)).toLocaleDateString("en-US", {
                year: "numeric",
                day: "numeric",
                month: "short",
            });
        },

        editRFP(id) {
            this.$router.push({
                path: `/procurement/rfp/edit/${id}`
            });
        }
    },

    computed: {
        ...mapState({
            details: state => state.rfp.rfp_detail,
        }),
        pdfUrl() {
            const attachment = this.details.rfp_attach.rfp_attachment;
            return this.host + attachment;
        }
    },

    mounted() {
        this.$store.dispatch("rfp/fetchRFPDetails", this.$route.params.id);
    },

    watch: {
        details(oldValue, newValue) {}

        // quotation_by_id(oldValue, newValue) {
        //     console.log(this.quotation_by_id);
        //     this.rfpData = this.quotation_by_id;
        // }
    }
};
</script>

<style scoped>
.container-fluid {
    padding: 40px 60px;
}

.background {
    background-image: url("../../../assets/images/dashboard/bg-1.jpg");
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 390px;
    background-position: center center,

}

.content-container {
    background-color: white;
    max-width: 1280px;
    padding: 20px;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin-bottom: 32px;
}

/* @media print {
            html,
            body {
                width: 210mm;
                height: 297mm;
            }

            .content-container {
                margin: 0;
                width: initial;
                min-height: initial;
            }
        } */

.button-container {
    width: 100%;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.rfp-container {
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.details-width {
    width: 60%;
}

.status-width {
    width: 40%;
}

@media print {
    .button-container {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 1299px) {
    .rfp-container {
        gap: 20px;
    }
}

@media screen and (max-width: 849px) {

    .rfp-container {
        display: flex;
        flex-direction: column;
    }

    .details-width {
        width: 100%;
    }

    .status-width {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .container-fluid {
        padding: 10px 20px;
    }
}
</style>
