var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-xl-7 b-center bg-size p-0",staticStyle:{"background-size":"cover","background-position":"center center","display":"block"},style:({ backgroundImage: 'url('+ require('../../assets/images/login/1.jpg') +')' })},[_c('img',{staticClass:"bg-img-cover bg-center",staticStyle:{"display":"none"},attrs:{"src":require("../../assets/images/login/1.jpg"),"alt":"looginpage"}})]),_c('div',{staticClass:"col-xl-5 p-0"},[_c('div',{staticClass:"login-card"},[_c('div',[_vm._m(0),_c('div',{staticClass:"login-main login-form-card"},[_c('form',{staticClass:"theme-form"},[_c('h4',[_vm._v("Create your account")]),_c('p',[_vm._v("Enter your personal details to create account")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('h6',{staticClass:"text-muted mt-4 or"},[_vm._v("Or signup with")]),_c('div',{staticClass:"social mt-4"},[_c('div',{staticClass:"btn-showcase"},[_vm._m(5),_c('a',{staticClass:"btn btn-light"},[_c('feather',{staticClass:"txt-twitter",attrs:{"type":"twitter"}}),_vm._v("twitter")],1),_c('a',{staticClass:"btn btn-light"},[_c('feather',{staticClass:"txt-fb",attrs:{"type":"facebook"}}),_vm._v("facebook")],1)])]),_c('p',{staticClass:"mt-4 mb-0"},[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"ml-2",attrs:{"tag":"a","to":"/auth/login"}},[_vm._v(" Login ")])],1)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{staticClass:"logo"},[_c('img',{staticClass:"img-fluid for-light",attrs:{"src":require("../../assets/images/logo/login.png"),"alt":"looginpage"}}),_c('img',{staticClass:"img-fluid for-dark",attrs:{"src":require("../../assets/images/logo/logo_dark.png"),"alt":"looginpage"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label pt-0"},[_vm._v("Your Name")]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-6"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","required":"","placeholder":"First name"}})]),_c('div',{staticClass:"col-6"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","required":"","placeholder":"Last name"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Email Address")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","required":"","placeholder":"Test@gmail.com"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Password")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","name":"login[password]","required":"","placeholder":"*********"}}),_c('div',{staticClass:"show-hide"},[_c('span',{staticClass:"show"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group mb-0"},[_c('div',{staticClass:"checkbox p-0"},[_c('input',{attrs:{"id":"checkbox1","type":"checkbox"}}),_c('label',{staticClass:"text-muted",attrs:{"for":"checkbox1"}},[_vm._v("Agree with"),_c('a',{staticClass:"ml-2",attrs:{"href":"#"}},[_vm._v("Privacy Policy")])])]),_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit"}},[_vm._v(" Create Account ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn btn-light"},[_c('i',{staticClass:"fa fa-google txt-linkedin"}),_vm._v(" Google ")])
}]

export { render, staticRenderFns }