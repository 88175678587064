<template>
<div>
    <Breadcrumbs main="HR" title="Employee" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header" style="max-width: fit-content">
                        <router-link to="/hr/organization/employee/create">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i>
                                <h7>Add New Employee</h7>
                            </button>
                        </router-link>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="employee" :fields="tablefields" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(username)="data">
                                        <span style="display: flex; flex-direction: column; gap: 8px;">
                                            <router-link :to="`/hr/organization/employee/details/${data.item.id}`" class="link-style">{{data.item.user_name}}</router-link>
                                            <small><span style="font-size: medium;">
                                                    <b-badge pill variant="dark">{{data.item.username}}</b-badge>
                                                </span></small>

                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">

                                            <div>
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteDesignation(data.item.id)" title="Delete"></feather>
                                            </div>

                                            <div @click="editEmployee(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                            <div @click="EmergencyDetails(data.item)">
                                                <feather type="alert-octagon" size="20px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Emergency Details" v-b-modal.modal-prevent-closing-emergency></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this Employee?</div>
    </b-modal>



    <!-- Emergency Detail Modal -->
    <b-modal id="modal-prevent-closing-emergency" ref="modal" title="Emergency Contact Information" hide-footer>
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
           
            <div class="row">
                <div class="col-sm-6">
                    <b-form-group label="Relation" label-for="name-input">
                        <b-form-input v-model="emergency_details.relation" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                    <b-form-group label="Name" label-for="name-input">
                        <b-form-input v-model="emergency_details.name" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <b-form-group label="Phone" label-for="name-input">
                        <b-form-input v-model="emergency_details.contact_number" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                    <b-form-group label="Email" label-for="name-input">
                        <b-form-input v-model="emergency_details.email" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <b-form-group label="Address" label-for="name-input">
                <b-form-textarea id="textarea-rows" placeholder="" rows="4" v-model="emergency_details.address" disabled></b-form-textarea>
            </b-form-group>


        </form>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'username',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'additional_info.department.name',
                    label: 'Department',
                    sortable: true
                },
                {
                    key: 'additional_info.designation.title',
                    label: 'Designation',
                    sortable: true
                },
                {
                    key: 'additional_info.joining_date',
                    label: 'Joining Date',
                    sortable: true
                },
                {
                    key: 'additional_info.address',
                    label: 'Address',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            designation: {
                title: '',
                department: '',

            },

            edit_designation: {
                id: '',
                title: '',
                department: '',

            },

            emergency_details: {
                id: '',
                name:'',
                contact_number:'',
                email:'',
                relation:'',
                address:'',
            },

            delete_value: null,

            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {}
        };
    },

    computed: {
        ...mapState({
            employee: state => state.employee.employee_list_pg,
            count: state => state.employee.employee_list_pg_count,
            department_list: state => state.department.departments,
            designation_list: state => state.designation.designation,
            loading: state => state.employee.submitLoading,
            error: state => state.employee.submitError,

        }),
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },

    },

    watch: {
        currentPage(newCurrentPage) {
            this.$router.replace({
                path: '/hr/organization/employee',
                query: {
                    page: newCurrentPage,
                    perPage: this.perPage
                },
            });
            this.currentPage = newCurrentPage
            this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                perPage: this.perPage,
                currentPage: newCurrentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        },
        perPage(newperPage) {
            this.$router.replace({
                path: '/hr/organization/employee',
                query: {
                    page: this.currentPage,
                    perPage: newperPage
                },
            });
            this.perPage = newperPage
            this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                perPage: newperPage,
                currentPage: this.currentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        },
    },

    mounted() {
        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("designation/fetchDesignations")

        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage,
                },
            });

            this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                perPage: this.perPage,
                currentPage: this.currentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage,
                },
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage

            this.$store.dispatch("employee/fetchEmployeeList_withpg", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,

            }).then(() => {
                this.totalRows = this.count;
            });

        }
    },

    methods: {

        EmergencyDetails(type) {
            this.emergency_details.id = type.id
            this.emergency_details.name = type.additional_info.emergency_contact.emergency_name?type.additional_info.emergency_contact.emergency_name : null
            this.emergency_details.contact_number = type.additional_info.emergency_contact.emergency_phone?type.additional_info.emergency_contact.emergency_phone : null
            this.emergency_details.relation = type.additional_info.emergency_contact.emergency_relation?type.additional_info.emergency_contact.emergency_relation : null
            this.emergency_details.email = type.additional_info.emergency_contact.emergency_email? type.additional_info.emergency_contact.emergency_email : null
            this.emergency_details.address = type.additional_info.emergency_contact.emergency_address?type.additional_info.emergency_contact.emergency_address : null
           

        },

        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        // resetModal() {
        //     this.designation.title = ''
        //     this.designation.department = ''
        // },

        // handleOk(bvModalEvent) {
        //     bvModalEvent.preventDefault()
        //     this.handleSubmit()

        // },

        // handleSubmit() {

        //     this.$store.dispatch("designation/addDesignation", this.designation).then(response => {

        //         if (response.code == 200) {
        //             Swal.fire({
        //                 title: "Success!",
        //                 text: response.response,
        //                 icon: "success"
        //             });

        //             this.designation.title = ''
        //             this.designation.department = ''

        //         } else {
        //             Swal.fire({
        //                 title: "Error!",
        //                 text: response.response,
        //                 icon: "error"
        //             });
        //         }
        //     })

        //     this.$nextTick(() => {
        //         this.$bvModal.hide('modal-prevent-closing')
        //     })
        // },

        editEmployee(type) {

            this.$router.push(`/hr/organization/employee/edit/${type.id}`)

        },

        deleteDesignation(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("employee/deleteEmployee", {
                item: this.delete_value,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
