<template>
    <div>
        <Breadcrumbs main="Job" title="Jobs" />

        <!-- Container-fluid starts -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <button class="btn btn-primary" type="submit">
                                <router-link to="/job/job/create" style="display: flex; gap: 12px; align-items: center; color: white;">
                                    <i class="fa fa-light fa-plus"></i> 
                                    <h7>Add New Job</h7>
                                </router-link>
                            </button>
                        </div>

                        <div class="card-body"> 
                            <div class="d-flex flex-column flex-sm-row" style="margin-bottom: 36px;">
                                <div class="mr-3 mr-md-4 field-width">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search" v-model="search_text" aria-label="Search" aria-describedby="basic-addon2">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="basic-addon2"><b-icon icon="search"></b-icon></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex mt-3 mt-sm-0">
                                    <div class="mr-3 mr-md-4 field-width">
                                        <b-form-select v-model="selected_job" :options="statuses"></b-form-select>
                                    </div>
                                    <button type="button" class="btn btn-primary">Reset</button>
                                </div>
                            </div>

                            <div class="job-view">
                                <div v-for="job in jobs" :key="job.index">
                                    <div style="height: fit-content; display: flex; flex-direction: column; background-color: rgb(243 244 246); border-radius: 0.75rem; filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));">
                                       
                                        <div style="position: relative;" class="w-100">
                                            <img :src="job.image" style="width: 100%; height: 250px; border-top-left-radius: 0.75rem; border-top-right-radius: 0.75rem; object-fit: cover;">
                                        </div>

                                        <div style="position: absolute; top: 8px; left: 8px;">
                                            <b-badge pill variant="success">{{ job.status }}</b-badge>
                                        </div>
                                      
                                        <div class="m-3">
                                            <div style="width: 100%; display: flex; justify-content: space-between;">
                                                <router-link :to="`/job/job/detail/${job.name}`">
                                                    <h5 style="font-size: 16px; font-weight: 600; width: 80%;">{{ job.job_title }}</h5>
                                                </router-link>

                                                <p style="width: 20%; text-align: right;">({{ job.applied }}) Applied</p>
                                            </div>

                                            <div style="margin-top: 5px;">
                                                <h5 style="font-size: 15px;">{{ job.job_position }}</h5>
                                                <h6 style="font-size: 15px;">Vacancy: {{ job.vacancy }}</h6>
                                            </div>

                                            <div style="margin-top: 5px; display: flex; justify-content: space-between;">
                                                <div style="display: flex; flex-direction: column;">
                                                    <h7 style="font-size: 13px;">Post Date: {{ job.post_date }}</h7>
                                                    <h7 style="font-size: 13px;">Deadline: {{ job.expiry_date }}</h7>
                                                </div>
                                                <div style="display: flex; gap: 12px; justify-items: end; align-items: end;">
                                                    <b-icon icon="pencil-square" style="color: green;" font-scale="1.3"></b-icon>
                                                    <b-icon icon="trash" style="color: red;" font-scale="1.3"></b-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var primary = localStorage.getItem('primary_color') || '#FF0006';
var secondary = localStorage.getItem('secondary_color') || '#818284';
  
export default {
    data() {
        return {
            jobs: [
                {
                    id: 1,
                    job_title: 'Busniess Development - Senior, International Sales | Full-Time',
                    job_position: 'Business Development',
                    post_date: '17 May, 2023',
                    expiry_date: '17 April,2023',
                    vacancy: 2,
                    image: "https://placehold.co/600x400",
                    status:'Active',
                    applied: 17
                },
                {
                    id: 2,
                    job_title: 'Busniess Development - Senior, International Sales | Full-Time',
                    job_position: 'Business Development',
                    post_date: '17 May, 2023',
                    expiry_date: '17 April,2023',
                    image: "https://placehold.co/600x400",
                    vacancy: 5,
                    status:'Active',
                    applied: 112
                },
                {
                    id: 3,
                    job_title: 'Busniess Development - Senior, International Sales | Full-Time',
                    job_position: 'Business Development',
                    post_date: '17 May, 2023',
                    expiry_date: '17 April,2023',
                    vacancy: 8,
                    image: "https://placehold.co/600x400",
                    status:'Active',
                    applied: 34
                },
                {
                    id: 4,
                    job_title: 'Busniess Development - Senior, International Sales | Full-Time',
                    job_position: 'Business Development',
                    post_date: '17 May, 2023',
                    expiry_date: '17 April,2023',
                    image: "https://placehold.co/600x400",
                    vacancy: 3,
                    status:'Active',
                    applied: 82,
                },
                {
                    id: 5,
                    job_title: 'Busniess Development - Senior, International Sales | Full-Time',
                    job_position: 'Business Development',
                    post_date: '17 May, 2023',
                    vacancy: 2,
                    expiry_date: '17 April,2023',
                    image: "https://placehold.co/800x450",
                    status:'Active',
                    applied: 31,
                }
            ],
    
            search_text: '',
            selected_job: null,

            statuses: [
                { value: null, text: 'Select a Status' },
                { value: 'a', text: 'Active' },
                { value: 'b', text: 'Inactive' }
            ]
        };
    },
};
</script>

<style scoped>
.job-view {
    display: grid; 
    grid-template-columns: repeat(3, minmax(0, 1fr)); 
    gap: 25px;
}

.field-width {
    width: 260px;
}

@media screen and (max-width: 1536px) {
    .job-view {
        display: grid; 
        grid-template-columns: repeat(2, minmax(0, 1fr)); 
        gap: 25px;
    }
}

@media screen and (max-width: 750px) {
    .job-view {
        display: grid; 
        grid-template-columns: repeat(1, minmax(0, 1fr)); 
        gap: 25px;
    }

    .field-width {
        width: 210px;
    }
}

@media screen and (max-width: 645px) {
    .field-width {
        width: 180px;
    }
}

@media screen and (max-width: 575px) {
    .field-width {
        width: 100%;
    }
}

@media screen and (max-width: 430px) {
    .job-view {
        display: grid; 
        grid-template-columns: repeat(1, minmax(0, 1fr)); 
        gap: 20px;
    }
}
</style>