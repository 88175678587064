<template>
<div>
    <!-- <Breadcrumbs main="Vendor" title="RFQ" /> -->
    <!-- Container-fluid starts-->
    <div class="background d-flex justify-content-center align-items-center">
        <h4 class="text-center">Tenders</h4>
    </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-md-4 sidebar">
                <div class="card sticky-sidebar">
                    <div class="card-body">
                        <b-input-group class="">
                            <b-form-input v-model="query" placeholder="Type to Search" @keyup="findTenders"></b-form-input>
                            <b-input-group-append>
                                <b-button :disabled="!query" @click="clearSearch = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>

                        <h5 class="mt-4">Category</h5>

                        <div class="mt-4">
                            <div class="pb-2" v-for="(category, index) in rfp_category_list" :key="index">
                                <div class="d-flex align-items-center" style="gap:5px">
                                    <input class="category-radio cursor-pointer d-flex align-items-center" type="radio" name="radio" :value="category.id" :id="category.id"  :checked="selectedCategory === category.id"  @click="handleCategoryClick(category.id)">
                                    <h7>{{ category.title }}</h7>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-8">
                <div class="row">
                    <div v-for="(detail, index) in filteredList" :key="detail.id" class="col-xl-6 col-lg-6">
                        <b-card>
                            <div class="d-flex justify-content-between">
                                <h5>{{ detail.title }}</h5>
                                <b-badge pill variant="dark" v-if="detail.rfp_category !== null" style="height: fit-content; width: fit-content;">{{detail.rfp_category.title}}</b-badge>
                            </div>
                            <b-card-text class="mt-4">
                                <h7>Deadline: {{detail.deadline}}</h7>
                            </b-card-text>
                            <router-link :to="`/tender/${detail.id}`">
                                <b-button variant="dark">Details</b-button>
                            </router-link>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- style="height: 100vh; overflow-y: auto;" -->

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            filter: null,
            query: null,
            selectedCategory: null,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'rfp_id',
                    label: 'RFP ID',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'rfp_category',
                    label: 'Category',
                    sortable: true
                },
                {
                    key: 'deadline',
                    label: 'Deadline',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },

                {
                    key: 'form_submit',
                    label: 'Form',
                    sortable: true,
                    class: 'text-center',
                },

            ],

            filter: null,
            rfp_category_id: '',
            delete_value: null,
            rfp_update_id: '',

            statuses: ['COMPLETED', 'WORKING', 'APPROVED'],
            selectedStatus: '',

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            items_list: state => state.rfp.rfp,
            item_by_category: state => state.rfp.rfp_by_category,
            item_by_search: state => state.rfp.rfp_by_search,

            rfp_category_list: state => state.rfp.rfp_category,
        }),

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        filteredList() {

            if (this.selectedCategory !== null) {
                if (this.query) {
                    return this.item_by_search;
                }
                return this.item_by_category
            }
            // else if(this.query){
            //     return this.item_by_search;
            // }
            else {
                if (this.query) {
                    return this.item_by_search;
                }
                return this.items_list;
            }

        },
    },

    mounted() {
        this.$store.dispatch("rfp/fetchRFPCategory");
        this.$store.dispatch("rfp/fetchRFP");

    },

    methods: {

        handleCategoryClick(id) {
            if (this.selectedCategory === id) {
                this.selectedCategory = null;

            } else {
                this.selectedCategory = id;
                this.sortByCategory(this.selectedCategory);
            }
            
        },

        findTenders() {
            this.$store.dispatch('rfp/fetchRFPbySearch', this.query);
        },

        getStatus(status, id) {
            this.rfp_update_id = id
            this.selectedStatus = status
        },

        sortByCategory(id) {
          
            this.$store.dispatch("rfp/fetchRFPbyCategory", id).then(() => {
                this.items_list = this.item_by_category
                console.log(this.item_by_category)
            });

        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteRFP(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("rfp/deleteRFP", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        activeStatus(id) {

            this.$store.dispatch("rfp/toggleRFPStatus", id).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.udate_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        handleStatusOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleStatusSubmit()
        },

        handleStatusSubmit() {

            alert(JSON.stringify(this.rfp_update_id))
            alert(JSON.stringify(this.selectedStatus))
            this.$store.dispatch("rfp/updateRfpStatus", {
                id: this.rfp_update_id,
                status: this.selectedStatus

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-status')
            })
        },

    }
};
</script>

<style scoped>

.sticky-sidebar {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 20px; /* Adjust the top offset as needed */
}
.container-fluid {
    padding: 40px 60px;
}

.background {
    background-image: url("../../../assets/images/dashboard/bg-1.jpg");
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 390px;
    background-position: center center,

}

.category-radio {
    appearance: none;
    background-color: #fff;
    width: 15px;
    height: 15px;
    border: 2px solid #ccc;
    border-radius: 2px;
    display: inline-grid;
    place-content: center;
}

.category-radio::before {
    content: "";
    width: 10px;
    height: 10px;
    transform: scale(0);
    transform-origin: bottom left;
    background-color: #fff;
    clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

.category-radio:checked::before {
    transform: scale(1);
}

.category-radio:checked {
    background-color: #12685d;
    border: 2px solid #12685d;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


@media screen and (max-width: 600px ) {
    .container-fluid {
        padding: 10px 20px;
    }
}
</style>
