<template>
<div>
    <Breadcrumbs main="HR" submain="Asset Management" title="Asset Allocations" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="asset_allocation" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(name)="data">
                                        <span>
                                            <router-link :to="`/hr/asset-management/asset/details/${data.item.asset.id}`" class="link-style">
                                                {{ data.item.asset.name }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(asset_user)="data">
                                        <span>
                                            {{ data.item.asset_user.first_name + ' ' + data.item.asset_user.last_name }}
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteAsset(data.item.id)" title="Delete"></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
    <!-- Asset Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this asset allocation?</div>
    </b-modal>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {
    mapState
} from 'vuex';

export default {
    components: {
        Multiselect
    },

    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'asset_user',
                    label: 'Employee',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Asset',
                    sortable: true
                },
                {
                    key: 'asset.asset_id',
                    label: 'Asset ID',
                    sortable: true
                },
                {
                    key: 'allocation_date',
                    label: 'Allocation Date',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            filter: null,
            delete_value: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        ...mapState({
            asset_allocation: state => state.asset.asset_allocation_list,
            loading: state => state.asset.submitLoading,
            error: state => state.asset.submitError,

        })
    },

    mounted() {
        // Set the initial number of items
        this.$store.dispatch("asset/fetchAssetAllocationList");
        this.totalRows = this.items.length;

    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        deleteAsset(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("asset/deleteAssetAllocation", {
                id: this.delete_value,
                from_allocation: true,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
