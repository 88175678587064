<template>
<div>
    <div>
        <Breadcrumbs main="HR" submain="My Workspace" title="My Updates" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row flex-md-row-reverse" v-if="!loading && !error">
            
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); margin-top: 8px; gap: 8px;">
                                <div>
                                    <span style="font-size: medium; width: 100%;">
                                        <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="danger" @click="filterByRefType('LEAVE')">
                                            <span>LEAVE</span>
                                        </b-badge>
                                    </span>
                                </div>
                                <div>
                                    <span style="font-size: medium; width: 100%;">
                                        <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="info" @click="filterByRefType('TICKET')">
                                            <span>TICKET</span>
                                        </b-badge>
                                    </span>
                                </div>
                                <div>
                                    <span style="font-size: medium; width: 100%;">
                                        <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="warning" @click="filterByRefType('BUDGET')">
                                            <span>BUDGET</span>
                                        </b-badge>
                                    </span>
                                </div>
                                <div>
                                    <span style="font-size: medium; width: 100%;">
                                        <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="primary" @click="filterByRefType('GRIEVANCE')">
                                            <span>GRIEVANCE</span>
                                        </b-badge>
                                    </span>
                                </div>
                                <div>
                                    <span style="font-size: medium; width: 100%;">
                                        <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="primary" @click="filterByRefType('EOD')">
                                            <span>EOD</span>
                                        </b-badge>
                                    </span>
                                </div>
                                <div>
                                    <span style="font-size: medium; width: 100%;">
                                        <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="secondary" @click="filterByRefType('REQUISITION')">
                                            <span>REQUISITION</span>
                                        </b-badge>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 my-2">
                    <div class="card" v-for="myUpdate in visibleUpdates" :key="myUpdate.id" :class="{ 'read': !myUpdate.read }">
                        <div class="card-body" @click="readMsg(myUpdate)" style="cursor: pointer;">
                            <div style="display: flex; flex-direction: row; justify-content: space-between">
                                <div>
                                    <h7>{{ myUpdate.activity }}</h7>
                                </div>
                                <div>
                                    <span v-if="myUpdate.ref_type == 'LEAVE'">
                                        <b-badge pill variant="danger">{{ myUpdate.ref_type}}</b-badge>
                                    </span>
                                    <span v-if="myUpdate.ref_type == 'TICKET'">
                                        <b-badge pill variant="info">{{ myUpdate.ref_type}}</b-badge>
                                    </span>
                                    <span v-if="myUpdate.ref_type == 'BUDGET'">
                                        <b-badge pill variant="warning">{{ myUpdate.ref_type}}</b-badge>
                                    </span>
                                    <span v-if="myUpdate.ref_type == 'GRIEVANCE'">
                                        <b-badge pill variant="primary">{{ myUpdate.ref_type}}</b-badge>
                                    </span>
                                    <span v-if="myUpdate.ref_type == 'EOD'">
                                        <b-badge pill variant="dark">{{ myUpdate.ref_type}}</b-badge>
                                    </span>
                                    <span v-if="myUpdate.ref_type == 'REQUISITION'">
                                        <b-badge pill variant="secondary">{{ myUpdate.ref_type}}</b-badge>
                                    </span>

                                </div>
                            </div>
                            <div>
                                <h7>{{ updateTime(myUpdate.timestamp) }}</h7>
                                <!-- <h7>{{ myUpdate.timestamp }}</h7> -->

                            </div>

                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-content-center" v-if="showLoadMoreButton()" style="cursor: pointer;">
                            <button class="btn btn-primary " @click="loadMore()">Load More</button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row" v-else>
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">

                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- Container-fluid Ends-->
    </div>

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },

    data() {
        return {
            updatedTime: null,
            visibleMyUpdates: 5,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'activity',
                    label: 'Activity',
                    sortable: true
                },
                {
                    key: 'timestamp',
                    label: 'Time Stamp',
                    sortable: true
                },
                {
                    key: 'ref_type',
                    label: 'Type',
                    sortable: true
                },

            ],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        sortOptions() {

            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        ...mapState({
            updates_list: state => state.myUpdates.my_updates,
            loading: state => state.myUpdates.submitLoading,
            error: state => state.myUpdates.submitError,
            eod_reply: state => state.eod.eod_reply,

        }),

        visibleUpdates() {
            if (!this.filter) {
                return this.updates_list.slice(0, this.visibleMyUpdates);
            } else {
                return this.updates_list.filter(update => update.ref_type === this.filter).slice(0, this.visibleMyUpdates);
            }
        },

    },

    mounted() {
        this.$store.dispatch("myUpdates/fetchMyUpdates", {
            ref_type: this.filter
        }).then(() => {
            this.items = this.updates_list
            this.totalRows = this.items.length;
        });

       
    },

    beforeDestroy() {
        clearInterval(this.timer); // Clear the timer when the component is destroyed to prevent memory leaks
    },
    methods: {
        updateTime(timestamp) {
            const date = new Date(timestamp);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;

            // Format time as HH:MM:SS AM/PM
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;

            const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;

            return `${formattedDate } ${formattedTime}`;
        },
        filterByRefType(refType) {
            this.$store.dispatch("myUpdates/fetchMyUpdates", {
                ref_type: refType
            }).then(() => {
                this.items = this.updates_list
                this.totalRows = this.items.length;
            });
            this.visibleMyUpdates = 5;
            this.filter = refType;
        },

        loadMore() {
            this.visibleMyUpdates += 5;
        },

        showLoadMoreButton() {
            return this.visibleUpdates.length < this.updates_list.length;
        },

        readMsg(data) {
            if (data.ref_type == 'LEAVE') {
                this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                    if (response.code == 200) {
                        this.$router.push(`/my-workspace/leave-details/${data.type_id}`);
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }
            if (data.ref_type == 'TICKET') {
                this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                    if (response.code == 200) {
                        this.$router.push(`/my-workspace/ticket-details/${data.type_id}`);
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }
            if (data.ref_type == 'BUDGET') {
                this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                    if (response.code == 200) {
                        this.$router.push(`/my-workspace/budget-details/${data.type_id}`);
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }
            if (data.ref_type == 'GRIEVANCE') {
                this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                    if (response.code == 200) {
                        this.$router.push(`/my-workspace/grievance-details/${data.type_id}`);
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }
            if (data.ref_type == 'EOD') {
                this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                    if (response.code == 200) {
                        this.$router.push(`/eod/details/${data.type_id}`);
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }
            if (data.ref_type == 'REQUISITION') {
                this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                    if (response.code == 200) {
                        this.$router.push(`/my-workspace/requisition-details/${data.type_id}`);
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }

        },
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    }
};
</script>

<style scoped>
.read {
    background-color: #fff0f0c7;
}
</style>
