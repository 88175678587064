<template>
<div>
    <Breadcrumbs main="Job" title="Job Details" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5">
                <div class="card p-3" style="width: 100%;">
                    <div class="task-board-task-scroll">
                        <div style="display: flex; flex-direction: column; height: 100%; overflow-y: auto; padding-right: 12px;">
                            <div>
                                <div style="display:flex; flex-direction: column; align-items: center; justify-content: center;" class="w-full">
                                    <img style="width: 100%; border-radius: 0.75rem; object-fit: cover; background-size: cover;" :src="host+job.banner_image" class="image-height">
                                </div>

                                <div style="margin-top: 25px; display:flex; flex-direction: column; align-items: start; justify-content: left; text-align: center;">
                                    <h5 style="text-align: left;">{{ job.title }}</h5>
                                    <h5 class="mt-2">
                                        <b-badge pill variant="success">{{ job.position }}</b-badge>
                                    </h5>
                                    <div class="mt-1 d-flex">
                                        <h5>
                                            <b-badge pill variant="primary">Vacancy: {{ job.vacancies }}</b-badge>
                                        </h5>
                                        <h5 style="margin-left: 12px;">
                                            <b-badge pill variant="info">Applied: {{ job.applied }}</b-badge>
                                        </h5>
                                    </div>
                                </div>

                                <div style="margin-top: 15px; display:flex; flex-direction: column; align-items: start; justify-content: left;; text-align: center; font-size: medium;">
                                    <h7>Post Date: {{ job.opening }}</h7>
                                    <h7>Deadline: <span style="color: red;">{{ job.deadline }}</span></h7>
                                </div>
                            </div>

                            <div style="margin-top: 20px;">
                                <div v-if="showDescription">
                                    <h7 v-if="job.description.length < 450">{{ job.description }}</h7>
                                    <h7 v-else>{{ job.description.substring(0, 450) }}</h7>
                                </div>
                                <div v-else class="pb-4 lg:pb-6">
                                    <div v-html="job.description"></div>
                                </div>
                                <div v-if="job.description.length > 450">
                                    <b-button v-if="showDescription" variant="primary" size="sm" @click="displayDescription" style="margin-top: 20px;">See More</b-button>
                                    <b-button v-else variant="primary" size="sm" @click="closeDescription">See Less</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="card p-3" style="width: 100%;">
                    <div class="label-small col-sm-12 col-md-6 mb-4">
                        <div style="display: flex; gap: 10px;">
                            <input class="form-control" type="text" placeholder="Enter label" v-model="label">
                            <button class="btn btn-success" @click="addLabel()">Add</button>
                        </div>
                        <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); margin-top: 8px; gap: 8px;">
                            <div v-for="label in labels" :key="label.index" style="width: 100%;">
                                <span style="font-size: medium; width: 100%;">
                                    <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="dark">
                                        <span style="width: 80%; padding: 4px; text-align: left;" @click="()=>{
                                                              filter=label.name
                                                             }"> {{ label.name }} </span>
                                        <span @click="deleteLabel(label.id)" style="width: 20%; padding: 4px;" v-b-tooltip.hover title="Delete">🗙</span>
                                    </b-badge>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="task-board-task-scroll">
                        <div style="display: flex; flex-direction: column; gap: 15px; height: 100%; overflow-y: auto; padding-right: 12px;">
                            <div class="applicant-view">
                                <div v-for="applicant in applicants" :key="applicant.index">
                                    <div style="height: 390px; display: flex; flex-direction: column;  text-align: center; background-color: rgb(243 244 246); border-radius: 0.75rem; filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));">
                                        <div style="display: flex; justify-content: space-between; align-items: center; overflow: hidden; box-sizing: border-box; padding: 10px;">
                                            <div style="display: flex; align-items: center;">
                                                <b-badge pill variant="success">{{ applicant.status }}</b-badge>
                                            </div>
                                            <div v-if="applicant.label.length>0">
                                                <div style="display: flex; flex-wrap: wrap; gap: 5px;justify-content: end;">
                                                    <div v-for="label in applicant.label" :key="label.id">
                                                        <b-badge pill variant="info" v-b-modal.modal-center-add @click="add(applicant)">{{ label.name }}</b-badge>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <b-badge pill variant="dark" v-b-modal.modal-center-add @click="add(applicant)">Add Label</b-badge>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center">
                                            <img style="height: 100px; width: 100px; border-radius: 100%;" :src="applicant.image">
                                        </div>
                                        <div style="margin-top: 15px;">
                                            <h5>{{ applicant.user.first_name }} {{ applicant.user.last_name }}</h5>
                                            <h7>{{ applicant.user.email }} </h7>
                                        </div>
                                        <div style="position: absolute; bottom: 25px; width: 100%;">
                                            <router-link :to="`/job/canditate/detail/${applicant.id}`">
                                                <b-button variant="primary">View Details</b-button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Add Labels -->
    <b-modal id="modal-center-add" centered title="Add Label" @ok="handleLabelOK">
        <div class="row">
            <div class="col-sm-12">

                <h6 v-if="addedLabels.length>0">Already Added</h6>
                <div class="row">
                    <div class="text-center col-sm-6 w-full" v-for="label in addedLabels" :key="label.id" v-b-tooltip.hover @click="deleteAssignedLabel(label.id)" title="Delete">
                        <div class="w-100 mb-2 badge-style">
                            <h7>{{ label.name}}</h7>
                            <b-icon icon="x" style="color: white;"></b-icon>
                        </div>
                    </div>
                </div>

                <div class="form-group mt-2">
                    <label>Label</label>
                    <select class="form-control digits" v-model="label_id">
                        <option v-for="(type, index) in labels" :key="index" :value="type.id">{{ type.name }}</option>
                    </select>
                </div>
            </div>
        </div>
    </b-modal>
</div>
</template>

<script>
var primary = localStorage.getItem('primary_color') || '#FF0006';
var secondary = localStorage.getItem('secondary_color') || '#818284';

import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    myHost
} from "../../../_helpers/host";
export default {
    data() {
        return {
            selected: 'a',

            options: [{
                    value: 'a',
                    text: 'First Option'
                },
                {
                    value: 'b',
                    text: 'Selected Option'
                },
                {
                    value: 'c',
                    text: 'Third Option'
                },
            ],
            label: '',
            label_id: null,
            applicant_id: '',
            addedLabels: [],
            selectedLabel: null,

            host: myHost.host,

            search_text: '',
            selected_job: null,
            notes: '',
            tag_a_manager: null,

            managers: [{
                    value: null,
                    text: 'Tag a Manager'
                },
                {
                    value: 'a',
                    text: 'A'
                },
                {
                    value: 'b',
                    text: 'B'
                }
            ],

            showDescription: true
        };
    },

    methods: {
        add(data) {
            this.applicant_id = data.id
            this.addedLabels = data.label
        },
        displayDescription() {
            this.showDescription = false
        },

        closeDescription() {
            this.showDescription = true
        },

        addLabel() {
            if (this.label !== '') {
                this.$store.dispatch("job/addApplicantLabel", this.label).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.label = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }
        },
        deleteLabel(id) {
            this.$store.dispatch("job/deleteApplicantLabel", id).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
        },

        handleLabelOK() {
            this.$store.dispatch("job/addLabelToApplicant", {
                applicant_id: this.applicant_id,
                label_id: this.label_id,
                job_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.label_id = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

        },
        deleteAssignedLabel(id) {
            this.$store.dispatch("job/deleteLabelFromApplicant", {
                applicant_id: this.applicant_id,
                label_id: id,
                job_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.label_id = null
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center-add')
            })

        },
    },
    computed: {
        ...mapState({
            job: state => state.job.job_details,
            applicants: state => state.job.applicant_list_by_job,
            labels: state => state.job.applicant_label_list,
        }),
    },
    mounted() {
        this.$store.dispatch("job/fetchJobDetails", this.$route.params.id);
        this.$store.dispatch("job/fetchApplicantListByJob", this.$route.params.id);
        this.$store.dispatch("job/fetchApplicantLabels")
    },
}
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.image-height {
    height: 310px;
}

.applicant-view {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 25px;
}

.task-board-task-scroll {
    position: relative;
    height: 75.5vh;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media screen and (max-width: 1680px) {
    .image-height {
        height: 270px;
    }

    .applicant-view {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}

@media screen and (max-width: 991px) {
    .image-height {
        height: 290px;
    }

    .applicant-view {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 20px;
    }

    .task-board-task-scroll {
        position: relative;
        height: 100%;
    }
}

@media screen and (max-width: 730px) {
    .applicant-view {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}

@media screen and (max-width: 645px) {
    .image-height {
        height: 260px;
    }
}

@media screen and (max-width: 430px) {
    .image-height {
        height: 240px;
    }

    .applicant-view {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 20px;
    }
}
</style>
