import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        project: [],
        project_detail: {},
        my_project:[],
        client_by_project:[],
        vendor_by_project:[],
        budget_by_project:[],
        quotation_by_project:[],
        invoice_by_project:[],
        requisition_by_project:[],
        purchase_order_by_project:[],
        bill_list_by_project:[],
        rfp_list_by_project:[],
        count:null,
        myTask:[],
        projectMember:[],
        project_by_search:[],
        my_project_by_search:[],
        taskMember:[],
        myTaskDeadline:[],

        submitLoading: false,
        submitError: false,

        detailLoading:false,
        detailError:false,

        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_PROJECT (state, project) {
            state.project = project;
        },
        SET_MY_PROJECT (state, {data,count}) {
            state.my_project = data;
            state.count = count
        },

        SET_MY_TASK (state, project) {
            state.myTask = project;
        },

        SET_MY_TASK_DEADLINE(state, project) {
            state.myTaskDeadline = project;
        },
        SET_CLIENT_BY_PROJECT(state, client) {
            state.client_by_project = client;
        },
        SET_VENDOR_BY_PROJECT(state, vendor) {
            state.vendor_by_project = vendor;
        },

        SET_PROJECT_DETAIL (state, project) {
            state.project_detail = project;
        },



        SET_PROJECT_MEMBER(state, member) {
            state.projectMember = member;
        },
        SET_TASK_MEMBER(state, member) {
            state.taskMember = member;
        },


        SET_BUDGET_BY_PROJECT (state, budget) {
            state.budget_by_project = budget;
        },
        SET_QUOTATION_BY_PROJECT (state, quotation) {
            state.quotation_by_project = quotation;
        },
        SET_REQUISITION_BY_PROJECT(state, requisition) {
            state.requisition_by_project = requisition;
        },

        SET_PURCHASE_ORDER_BY_PROJECT(state, po) {
            state.purchase_order_by_project = po;
        },

        SET_INVOICE_BY_PROJECT (state, invoice) {
            state.invoice_by_project = invoice;
        },

        SET_BILL_LIST_BY_PROJECT(state, bill) {
            state.bill_list_by_project = bill;
        },

        SET_PROJECT_BY_SEARCH(state, project) {
            state.project_by_search = project;
        },
    
        SET_MY_PROJECT_BY_SEARCH(state, project) {
            state.my_project_by_search = project;
        },

        SET_RFP_LIST_BY_PROJECT(state, rfp) {
            state.rfp_list_by_project = rfp;
        },

        PROJECT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        PROJECT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        PROJECT_DETAIL_LOADING (state, loading) {
            state.detailLoading = loading;
        },

        PROJECT_DETAIL_ERROR (state, hasError) {
            state.detailError = hasError;
        },




        PROJECT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {


        
        async fetchProjectbySearch({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                if(payload.project_status == 'ALL'){
                    let result = await axios.get(V1API.project_search+'?search_term='+payload.search_term,config);
                    let response = result.data
                    commit('SET_PROJECT_BY_SEARCH', response.data);
                }
                else{
                    let result = await axios.get(V1API.project_search+'?project_status='+payload.project_status+'&search_term='+payload.search_term,config);
                    let response = result.data
                    commit('SET_PROJECT_BY_SEARCH', response.data);
                }
               
            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }

        },


        async fetchMyProjectbySearch({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                if(payload.project_status == 'ALL'){
                    let result = await axios.get(V1API.my_project_search+'?search_term='+payload.search_term,config);
                    let response = result.data
                    commit('SET_MY_PROJECT_BY_SEARCH', response.data);
                }
                else{
                    let result = await axios.get(V1API.my_project_search+'?project_status='+payload.project_status+'&search_term='+payload.search_term,config);
                    let response = result.data
                    commit('SET_MY_PROJECT_BY_SEARCH', response.data);
                }
               
            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }

        },

        
        async toggleAreaHub({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.projectUpdate,
                async () => {
                    response= await axios.get(V1API.area_has_hub+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchProject');

                }
            )
            return response
        },

        async deleteProject ({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.projectDelete,
                async () => {
                    response= await axios.delete(V1API.area_delete+'/'+payload.area_id).then(result=>{
                        let data=decode(result.data)
                        return data.message
                    });
                    dispatch('fetchProject');
                }
            )

            return response
        },

        async fetchProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{

                if(!payload){
                    let result = await axios.get(V1API.get_project, config);
                    let response = result.data
                    commit('SET_PROJECT', response.data);
                }
                if(payload){
                    let result = await axios.get(V1API.get_project+'?project_status='+payload, config);
                    let response = result.data
                    commit('SET_PROJECT', response.data);
                }
                

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }finally {
                commit('PROJECT_LOADING', false);
            }

        
        },

        async fetchClientByProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.client_list_by_project + payload, config);
                let response = result.data

                commit('SET_CLIENT_BY_PROJECT', response.data);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },






        async fetchMyProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                if(!payload){
                    let result = await axios.get(V1API.get_my_project, config);
                    const {data,count} = result.data
                    commit('SET_MY_PROJECT', {data,count});
                }
                if(payload){
                    let result = await axios.get(V1API.get_my_project+'?project_status='+payload, config);
                    const {data,count} = result.data
                    commit('SET_MY_PROJECT', {data,count});
                }

               

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },

        async fetchMyTask ({ commit }) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_my_task, config);
                let response = result.data

                commit('SET_MY_TASK', response.data);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
            
        },

        async fetchMyTaskDeadlinToday ({ commit }) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_my_task_deadline, config);
                let response = result.data

                commit('SET_MY_TASK_DEADLINE', response.data);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },



        async toggleSubTaskComplete ({commit, dispatch}, payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.get(V1API.toggle_subtask_complete_status + payload.id, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('PROJECT_SUCCESS', true);
                dispatch ('fetchMyTask')
                return response

            }catch (e) {
                commit('PROJECT_ERROR', true);
            }finally {
                commit('PROJECT_LOADING', false);
            }
        }, 






        async togglTaskComplete ({commit, dispatch}, payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.get(V1API.toggle_task_complete_status + payload.id, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('PROJECT_SUCCESS', true);
                dispatch ('fetchMyTask')
                return response

            }catch (e) {
                commit('PROJECT_ERROR', true);
            }finally {
                commit('PROJECT_LOADING', false);
            }
        },



        async deleteProject ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_project + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchProject')
            
            return response
        },


        async checkMyPassword({commit,dispatch}, payload) {
         
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.check_my_password, {password:payload.password}, config).then(result=>{
                    let data = result.data
                    return data
                });
                commit('PROJECT_SUCCESS', true);
               
                return response

            }catch (e) {
                
                commit('PROJECT_ERROR', true);
            }finally {
                commit('PROJECT_LOADING', false);
            }
        },





        async fetchProjectDetails ({ commit }, payload) {
            commit('PROJECT_DETAIL_LOADING', true);
            commit('PROJECT_DETIAL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_project_detail + payload, config);
                let response = result.data

                commit('SET_PROJECT_DETAIL', response.data);

            } catch(error) {
                commit('PROJECT_DETIAL_ERROR', true);
            }
            finally {
                commit('PROJECT_DETAIL_LOADING', false);
            }
        },

        async deleteAttachmentFromProjects ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_attachment + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchProjectDetails',payload.project_id)
            
            return response
        },

        async deleteTeammateFromProjects ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_teammate_from_projects + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchProjectDetails',payload.detail_id)
            
            return response
        },
        async deleteClientFromProjects ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_client_from_project + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            // dispatch ('fetchProjectDetails',payload.detail_id)
            dispatch('fetchClientByProject',payload.detail_id)
            
            return response
        },
        async deleteVendorFromProjects ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_vendor_from_project + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            // dispatch ('fetchProjectDetails',payload.detail_id)
            dispatch('fetchVendorByProject',payload.detail_id)
            
            return response
        },
        


        async assignProjectEmployee({commit,dispatch}, payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.assign_project_employee, {
                    project_id:payload.project_id,
                    member_id:payload.member_id

                }, config).then(result=>{
                    let data = result.data
                    return data
                });
                commit('PROJECT_SUCCESS', true);
                dispatch ('fetchProjectDetails', payload.project_id)
                return response

            }catch (e) {
                
                commit('PROJECT_ERROR', true);
            }finally {
             
                commit('PROJECT_LOADING', false);
            }
        },

        async fetchAreaByState({ commit }, payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);
            try{

                let result = await axios.get(V1API.area_by_state_get+'/'+ payload.state_id);
                let response=decode(result.data)
                commit('SET_PROJECT', response.data);
            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            commit('PROJECT_LOADING', false);
        },

        async addProject ({commit, dispatch}, payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload);
                // let magic = encode(payloadData)

                let response = await axios.post(V1API.create_project, payloadData, config).then(result=>{
                    // let data = decode(result.data)
                    let data = result.data
                    return data

                });

                commit('PROJECT_SUCCESS', true);
                dispatch ('fetchProject')
                return response

            }catch (e) {
                // console.log(e)
                commit('PROJECT_ERROR', true);
            }finally {
                // console.log('jgdfjg - 2')
                commit('PROJECT_LOADING', false);
            }
        },


        async addProjectAttachment ({commit, dispatch}, payload) {
            commit('PROJECT_DETAIL_LOADING', true);
            commit('PROJECT_DETAIL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };
            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.attachment);
        
                let response = await axios.post(V1API.create_project_attachment, payloadData, config).then(result=>{
                  
                    let data = result.data
                    return data

                });

                commit('PROJECT_SUCCESS', true);
                dispatch("fetchProjectDetails",payload.project_id)
                return response

            }catch (e) {
               
                commit('PROJECT_DETAIL_ERROR', true);
            }finally {
             
                commit('PROJECT_DETAIL_LOADING', false);
            }
        },

        async updateProject ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  },
                  "Content-Type": "multipart/form-data"
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
               
                async () => {

                    let data= new FormData()
                    let payloadData = buildFormData(data, payload.payload);

                    response= await axios.patch(V1API.update_project+payload.id,payloadData,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchProject');

                }
            )
            return response
        },


        async updateProjectPriority({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.update_project_priority+ payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data

                })
                dispatch("fetchProjectDetails",payload.id)
                return response
            } catch (e) {
                
                commit('PROJECT_ERROR', true);
            } finally {

                commit('PROJECT_LOADING', false);
            }
        },

        async ProjectAddClient ({commit, dispatch}, payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_client_to_project , {
                    project_id: payload.project_id,
                    client_id: payload.client_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });
                dispatch('fetchClientByProject',payload.project_id)
                dispatch ('fetchProjectDetails', payload.project_id)

                return response

            }catch (e) {
                commit('PROJECT_ERROR', true);
            }finally {
                commit('PROJECT_LOADING', false);
            }
        },

        async fetchVendorByProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.vendor_list_by_project + payload, config);
                let response = result.data

                commit('SET_VENDOR_BY_PROJECT', response.data);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },

        async ProjectAddVendor ({commit, dispatch}, payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_vendor_to_project , {
                    project_id: payload.project_id,
                    vendor_id: payload.vendor_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });
                dispatch('fetchVendorByProject',payload.project_id)
                // dispatch ('fetchProjectDetails', payload.project_id)

                return response

            }catch (e) {
                commit('PROJECT_ERROR', true);
            }finally {
                commit('PROJECT_LOADING', false);
            }
        },






        // Budget by project
        async fetchBudgetByProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.budget_by_project + payload, config);
                let response = result.data

                commit('SET_BUDGET_BY_PROJECT', response.data);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },
        async fetchQuotationByProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.quotation_by_project + payload, config);
                let response = result.data

                commit('SET_QUOTATION_BY_PROJECT', response.data);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },

        async fetchInvoiceByProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.invoice_by_project + payload, config);
                let response = result.data

                commit('SET_INVOICE_BY_PROJECT', response.data);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },

        
        async fetchRequisitionByProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.requisition_by_project + payload, config);
                let response = result.data

                commit('SET_REQUISITION_BY_PROJECT', response.data);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },
        async fetchPurchaseOrderByProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.purchase_order_by_project + payload, config);
                let response = result.data

                commit('SET_PURCHASE_ORDER_BY_PROJECT', response.data);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },

        async fetchBillByProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.bill_list_by_project + payload, config);
                let response = result.data

                commit('SET_BILL_LIST_BY_PROJECT', response.data);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },

        async fetchRFPByProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.rfp_list_by_project + payload, config);
                let response = result.data

                commit('SET_RFP_LIST_BY_PROJECT', response.data);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },


        async fetchProjectMemberList ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.project_member_list +payload, config);
                let response = result.data.data

                commit('SET_PROJECT_MEMBER', response);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },


        async fetchTaskMemberList ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.project_task_member_list +payload, config);
                let response = result.data.data

                commit('SET_TASK_MEMBER', response);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {
                commit('PROJECT_LOADING', false);
            }
        },

        
        

       

    },

    getters: {}
}
