var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane fade",class:{ 'active show': _vm.customizer == 'settings' },attrs:{"id":"c-pills-home"}},[_c('h6',[_vm._v("Layout Type")]),_c('ul',{staticClass:"main-layout layout-grid"},[_c('li',{class:{ active: _vm.layoutType == 'ltr' },attrs:{"data-attr":"ltr"},on:{"click":function($event){return _vm.customizeLayoutType('ltr')}}},[_vm._m(0),_vm._m(1)]),_c('li',{class:{ active: _vm.layoutType == 'rtl' },attrs:{"data-attr":"rtl"},on:{"click":function($event){return _vm.customizeLayoutType('rtl')}}},[_vm._m(2),_vm._m(3)]),_c('li',{staticClass:"box-layout px-3",class:{ active: _vm.layoutType == 'box-layout' },style:(_vm.boxlayout ? '' : 'display: none;'),attrs:{"data-attr":"box"},on:{"click":function($event){return _vm.customizeLayoutType('box-layout')}}},[_vm._m(4),_vm._m(5)])]),_c('h6',{},[_vm._v("Sidebar settings")]),_c('ul',{staticClass:"sidebar-setting layout-grid"},[_c('li',{class:{
        active: _vm.layout.settings.sidebar_setting == 'default-sidebar',
      },attrs:{"data-attr":"default-sidebar"},on:{"click":function($event){return _vm.customizeSidebarSetting('default-sidebar')}}},[_vm._m(6),_vm._m(7)]),_c('li',{class:{
        active: _vm.layout.settings.sidebar_setting == 'border-sidebar',
      },attrs:{"data-attr":"border-sidebar"},on:{"click":function($event){return _vm.customizeSidebarSetting('border-sidebar')}}},[_vm._m(8),_vm._m(9)]),_c('li',{class:{
        active: _vm.layout.settings.sidebar_setting == 'iconcolor-sidebar',
      },attrs:{"data-attr":"iconcolor-sidebar"},on:{"click":function($event){return _vm.customizeSidebarSetting('iconcolor-sidebar')}}},[_vm._m(10),_vm._m(11)])]),_c('h6',{},[_vm._v("Unlimited Color")]),_c('ul',{staticClass:"layout-grid unlimited-color-layout"},[_c('input',{attrs:{"id":"ColorPicker1","type":"color","value":"#7366ff","name":"Background"}}),_c('input',{attrs:{"id":"ColorPicker2","type":"color","value":"#f73164","name":"Background"}}),_c('button',{staticClass:"color-apply-btn btn btn-primary color-apply-btn",attrs:{"type":"button"},on:{"click":_vm.customizeColor}},[_vm._v(" Apply ")]),_c('button',{staticClass:"color-apply-btn btn btn-primary color-reset-btn ml-2",attrs:{"type":"button"},on:{"click":_vm.resetColor}},[_vm._v(" Reset ")])]),_c('h6',{},[_vm._v("Mix Layout")]),_c('ul',{staticClass:"layout-grid customizer-mix"},[_c('li',{staticClass:"color-layout",class:{ active: _vm.mixLayout == 'light-only' },attrs:{"data-attr":"light-only"},on:{"click":function($event){return _vm.customizeMixLayout('light-only')}}},[_vm._m(12),_vm._m(13)]),_c('li',{staticClass:"color-layout",class:{ active: _vm.mixLayout == 'dark-sidebar' },attrs:{"data-attr":"dark-sidebar"},on:{"click":function($event){return _vm.customizeMixLayout('dark-sidebar')}}},[_vm._m(14),_vm._m(15)]),_c('li',{staticClass:"color-layout",class:{ active: _vm.mixLayout == 'dark-only' },attrs:{"data-attr":"dark-only"},on:{"click":function($event){return _vm.customizeMixLayout('dark-only')}}},[_vm._m(16),_vm._m(17)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header bg-light"},[_c('ul',[_c('li'),_c('li'),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('ul',[_c('li',{staticClass:"bg-light sidebar"}),_c('li',{staticClass:"bg-light body"},[_c('span',{staticClass:"badge badge-primary"},[_vm._v("LTR")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header bg-light"},[_c('ul',[_c('li'),_c('li'),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('ul',[_c('li',{staticClass:"bg-light body"},[_c('span',{staticClass:"badge badge-primary"},[_vm._v("RTL")])]),_c('li',{staticClass:"bg-light sidebar"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header bg-light"},[_c('ul',[_c('li'),_c('li'),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('ul',[_c('li',{staticClass:"bg-light sidebar"}),_c('li',{staticClass:"bg-light body"},[_c('span',{staticClass:"badge badge-primary"},[_vm._v("Box")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header bg-light"},[_c('ul',[_c('li'),_c('li'),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body bg-light"},[_c('span',{staticClass:"badge badge-primary"},[_vm._v("Default")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header bg-light"},[_c('ul',[_c('li'),_c('li'),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body bg-light"},[_c('span',{staticClass:"badge badge-primary"},[_vm._v("Border")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header bg-light"},[_c('ul',[_c('li'),_c('li'),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body bg-light"},[_c('span',{staticClass:"badge badge-primary"},[_vm._v("icon Color")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header bg-light"},[_c('ul',[_c('li'),_c('li'),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('ul',[_c('li',{staticClass:"bg-light sidebar"}),_c('li',{staticClass:"bg-light body"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header bg-light"},[_c('ul',[_c('li'),_c('li'),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('ul',[_c('li',{staticClass:"bg-dark sidebar"}),_c('li',{staticClass:"bg-light body"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header bg-dark"},[_c('ul',[_c('li'),_c('li'),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('ul',[_c('li',{staticClass:"bg-dark sidebar"}),_c('li',{staticClass:"bg-dark body"})])])
}]

export { render, staticRenderFns }