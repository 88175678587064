import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        estimateBudgets: [],
        budgetRequests: [],
        ticket_reply:[],
        budget_request_detail:{},
     

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_ESTIMATED_BUDGETS (state, item) {
            state.estimateBudgets = item;
        },
        SET_REQUESTED_BUDGETS (state, item) {
            state.budgetRequests = item;
        },
        SET_TICKET_REPLY(state,reply){
            state.ticket_reply = reply;
        },
        SET_BUDGET_REQUEST_DETAIL(state,detail){
            state.budget_request_detail = detail;
        },

  
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateBudget ({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_budget_category+"/" + payload.id, payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchBudget');
            return response
        },

        async deleteBudget ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_budget_category+"/" + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchBudget');

            return response
        },

        async fetchBudgetReply ({ commit }, payload) {
            commit('SUBMIT_REPLY_LOADING', true);
            commit('SUBMIT_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_budget_reply + payload, config);
                let response = result.data
                commit('SET_TICKET_REPLY', response.data);

            } catch(error) {
                commit('SUBMIT_REPLY_ERROR', true);
            }
            finally {
                commit('SUBMIT_REPLY_LOADING', false);
            }
        },
        async fetchBudgetRequestDetail ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.budget_request_detail + payload, config);
                let response = result.data
                commit('SET_BUDGET_REQUEST_DETAIL', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async addBudgetReply ({commit, dispatch}, payload) {
            commit('SUBMIT_REPLY_LOADING', true);
            commit('SUBMIT_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.budget_reply_add, {

                    budget_request: payload.budget_request,
                    reply:payload.reply,
                }, config).then(result=>{
                    let data = result.data
                    return data

                });
                dispatch('fetchBudgetRequests');
                commit('SUBMIT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('SUBMIT_REPLY_ERROR', true);
            }finally {
                commit('SUBMIT_REPLY_LOADING', false);
            }
        },



        async fetchBudget ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_budget_category, config);
                let response = result.data

                commit('SET_ESTIMATED_BUDGETS', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async BudgetAddRefer ({commit, dispatch}, payload) {
            commit('SUBMIT_REPLY_LOADING', true);
            commit('SUBMIT_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_refer_to_budget + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });
                
                dispatch('fetchBudgetRequests')
                dispatch('fetchBudgetRequestDetail',payload.id)
               
            
                return response

            }catch (e) {
                commit('SUBMIT_REPLY_ERROR', true);
            }finally {
                commit('SUBMIT_REPLY_LOADING', false);
            }
        },
        async BudgetAddDelete ({commit, dispatch}, payload) {
            commit('SUBMIT_REPLY_LOADING', true);
            commit('SUBMIT_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.delete_refer_from_budget + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });
                dispatch('fetchBudgetRequests')
                dispatch('fetchBudgetRequestDetail',payload.id)
                
                return response

            }catch (e) {
                commit('SUBMIT_REPLY_ERROR', true);
            }finally {
                commit('SUBMIT_REPLY_LOADING', false);
            }
        },





        async fetchBudgetRequests ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_budget_requests, config);
                let response = result.data

                commit('SET_REQUESTED_BUDGETS', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addBudget ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_budget_category, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchBudget')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async requestBudget ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.request_budget, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchBudget')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async requestBudgetEdit ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.patch(V1API.request_budget_update+'/'+ payload.id,payload.data ,config).then(result=>{
                    let data = result.data
                    return data

                });

                
                dispatch ('fetchBudgetRequestDetail',payload.id);
                dispatch ('fetchBudgetRequests');
                commit('SUBMIT_SUCCESS', true);
                
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        }
    },

    getters: {}
}
