<template>
<div>
    <Breadcrumbs main="Procurement" title="RFP" />

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div class="d-flex justify-content-between mb-4">
                                <div>
                                    <b-badge variant="success">{{ details.rfp_category.title }}</b-badge>
                                </div>
                                <div>
                                    <span v-if="details.active == true">
                                        <b-badge pill variant="success">Active</b-badge>
                                    </span>
                                    <span v-if="details.active == false">
                                        <b-badge pill variant="danger">Inactive</b-badge>
                                    </span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4">
                                    <b-form-group label="RFP ID" label-for="name-input">
                                        <b-form-input v-model="details.rfp_id" placeholder="" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-sm-4">
                                    <b-form-group label="Title" label-for="name-input">
                                        <b-form-input v-model="details.title" placeholder="" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-sm-4">
                                    <b-form-group label="Deadline" label-for="name-input">
                                        <datepicker placeholder="Select start date" input-class="datepicker-here form-control digits" v-model="details.deadline" disabled></datepicker>
                                    </b-form-group>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <b-form-group label="Status" label-for="name-input">
                                        <b-form-input v-model="details.status" placeholder="" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-sm-6">
                                    <b-form-group label="Priority" label-for="name-input">
                                        <b-form-input v-model="details.priority" placeholder="" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <label>Short Description</label>
                                    <!-- <b-form-textarea id="textarea-rows" placeholder="Tall textarea" rows="5" v-model="details.details" disabled></b-form-textarea> -->
                                    <ckeditor :editor="editor" placeholder="Enter short description..." v-model="details.details"></ckeditor>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="d-flex justify-content-between ">
                                <h6 class="mt-4 mb-3">Supporting Documents</h6>
                            </div>

                            <b-table sticky-header :items="details.rfp_attach" :fields="Attachment" head-variant="light">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template #cell(title)="data">
                                    <a :href="host+data.item.rfp_attachment" target="_blank">{{ data.item.rfp_attachment }}</a>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from "vuex";
import Datepicker from 'vuejs-datepicker';
import { myHost } from "../../../_helpers/host";
import 'sweetalert2/src/sweetalert2.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    props: ["id", "download"],

    components: {
        Datepicker
    },

    data() {
        return {
            editor: ClassicEditor,
            host: myHost.host,

            loading: true,
            downloadPdf: false,
            Attachment: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                }
            ]
        };
    },

    methods: {
        printPdf(section) {
            this.downloadPdf = true;
            // window.print();

            var printContents = document.getElementById(section).innerHTML;
            var originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;

            window.print();

            document.body.innerHTML = originalContents;
        },

        parseDate(date) {
            return new Date(Date.parse(date)).toLocaleDateString("en-US", {
                year: "numeric",
                day: "numeric",
                month: "short",
            });
        },

        editRFP(id) {
            this.$router.push({
                path: `/procurement/rfp/edit/${id}`
            });
        }
    },

    computed: {
        ...mapState({
            details: state => state.rfp.rfp_detail,
        }),
        pdfUrl() {
            const attachment = this.details.rfp_attach.rfp_attachment;
            return this.host + attachment;
        }
    },

    mounted() {
        this.$store.dispatch("rfp/fetchRFPDetails", this.$route.params.id);
    },

    watch: {
        details(oldValue, newValue) {}

        // quotation_by_id(oldValue, newValue) {
        //     console.log(this.quotation_by_id);
        //     this.rfpData = this.quotation_by_id;
        // }
    }
};
</script>

<style scoped>
.content-container {
    background-color: white;
    max-width: 1280px;
    padding: 20px;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin-bottom: 32px;
}

/* @media print {
        html,
        body {
            width: 210mm;
            height: 297mm;
        }

        .content-container {
            margin: 0;
            width: initial;
            min-height: initial;
        }
    } */

.button-container {
    width: 100%;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.rfp-container {
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.details-width {
    width: 60%;
}

.status-width {
    width: 40%;
}

@media print {
    .button-container {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 1299px) {
    .rfp-container {
        gap: 20px;
    }
}

@media screen and (max-width: 849px) {
    .rfp-container {
        display: flex;
        flex-direction: column;
    }

    .details-width {
        width: 100%;
    }

    .status-width {
        width: 100%;
    }
}
</style>
