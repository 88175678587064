<template>
<div>
    <Breadcrumbs main="HR" submain="Payroll" title="Designation - Payroll" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                        <button class="btn btn-primary" gross_salary="submit" v-b-modal.modal-prevent-closing-xl style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Designation - Payroll</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Search..."></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!payrollLoading && !payrollError">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(department)="data">
                                        <p v-if="data.item.designation">{{ data.item.designation.department.name }}</p>
                                    </template>

                                    <template #cell(designation)="data">
                                        <p v-if="data.item.designation">{{ data.item.designation.title }}</p>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover name="Delete" v-b-modal.modal-center @click="deleteItems(data.item.id)"></feather>
                                            <div @click="editItem(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover name="Edit" v-b-modal.modal-prevent-closing-edit-xl></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Designation - Payroll Create Modal -->
    <b-modal id="modal-prevent-closing-xl" size="xl" ref="modal" title="Add Designation - Payroll" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <div class="form-inside-container">
                <div class="header-width">
                    <b-form-group label="Department" label-for="name-input">
                        <b-form-select v-model="designation_payroll.department" @change="getDesignationByDepartment">
                            <b-form-select-option v-for="option in departments" :value="option.id">{{ option.name }}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <div class="header-width">
                    <b-form-group label="Designation" label-for="name-input">
                        <b-form-select v-model="designation_payroll.designation">
                            <b-form-select-option v-for="option in designations" :value="option.id">{{ option.title }}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </div>

            <div class="form-inside-container">
                <div class="header-width item-padding">
                    <h6>Inclusions (+)</h6>
                    <div>
                        <div style="display: flex; flex-direction: column; gap: 10px;">
                            <div v-for="(inc, index) in create_inclusions" :key="index">
                                <div style="display: flex; align-items: center; width: 100%;">
                                    <div style="display: flex; flex-direction: row; gap: 10px; width: 90%;">
                                        <b-form-select v-model="inc.item">
                                            <b-form-select-option v-for="option in inclusion_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                        </b-form-select>
                                        <b-form-input v-model="inc.amount" type="number" placeholder="Enter amount"></b-form-input>
                                    </div>

                                    <div style="width: 50px; margin-left: 10px; display: flex; gap: 4px; align-items: center;">
                                        <!-- Add Svg Icon -->
                                        <svg @click="addField(inc.id, create_inclusions)" width="24" height="24" class="cursor-pointer">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                        </svg>

                                        <!-- Remove Svg Icon -->
                                        <svg v-if="create_inclusions.length > 1 && !inc.id" @click="removeField(index, create_inclusions)" width="24" height="24" class="cursor-pointer">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>

                                        <!-- Delete Svg Icon -->
                                        <svg v-if="create_inclusions.length > 1 && inc.id" @click="deleteInclusion(inc.id)" width="24" height="24" class="cursor-pointer">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="header-width item-padding">
                    <h6> Deductions (-)</h6>
                    <div>
                        <div style="display: flex; flex-direction: column; gap: 10px;">
                            <div v-for="(deduction, index) in create_deductions" :key="index">
                                <div style="display: flex; align-items: center; width: 100%;">
                                    <div style="display: flex; flex-direction: row; gap: 10px; width: 90%;">
                                        <b-form-select v-model="deduction.item">
                                            <b-form-select-option v-for="option in deduction_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                        </b-form-select>
                                    </div>

                                    <div style="width: 50px; margin-left: 10px; display: flex; gap: 4px; align-items: center;">
                                        <!-- Add Svg Icon -->
                                        <svg @click="addDedField(deduction.id, create_deductions)" width="24" height="24" class="cursor-pointer">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                        </svg>

                                        <!-- Remove Svg Icon -->
                                        <svg v-if="create_deductions.length > 1 && !deduction.id" @click="removeDedField(index, create_deductions)" width="24" height="24" class="cursor-pointer">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>

                                        <!-- Delete Svg Icon -->
                                        <svg v-if="create_deductions.length > 1 && deduction.id" @click="deleteDeduction(deduction.id)" width="24" height="24" class="cursor-pointer">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="display: flex; align-items: center; margin-top: 20px;">
                <h6 style="margin-top: 7px;">Gross Salary</h6>
                <b-form-input v-model="create_gross_salary" type="number" disabled style="width: 300px; margin-left: 16px;"></b-form-input>
            </div>
        </form>
    </b-modal>

    <!-- Designation - Payroll Edit Modal -->
    <b-modal id="modal-prevent-closing-edit-xl" size="xl" ref="modal" title="Edit Designation - Payroll" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <div class="form-inside-container">
                <div class="header-width">
                    <b-form-group label="Department" label-for="name-input">
                        <b-form-input v-model="edit_designation_payroll.department" disabled></b-form-input>
                    </b-form-group>
                </div>

                <div class="header-width">
                    <b-form-group label="Designation" label-for="name-input">
                        <b-form-input v-model="edit_designation_payroll.designation" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <div class="form-inside-container">
                <div class="header-width item-padding">
                    <h6>Inclusions (+)</h6>
                    <div>
                        <div style="display: flex; flex-direction: column; gap: 10px;">
                            <div v-for="(inc, index) in edit_inclusions" :key="index">
                                <div style="display: flex; align-items: center; width: 100%;">
                                    <div style="display: flex; flex-direction: row; gap: 10px; width: 90%;">
                                        <b-form-select v-model="inc.item">
                                            <b-form-select-option v-for="option in inclusion_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                        </b-form-select>
                                        <b-form-input v-model="inc.amount" type="number" placeholder="Enter amount"></b-form-input>
                                    </div>

                                    <div style="width: 50px; margin-left: 10px; display: flex; gap: 4px; align-items: center;">
                                        <!-- Add Svg Icon -->
                                        <svg @click="addField(inc.id, edit_inclusions)" width="24" height="24" class="cursor-pointer">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                        </svg>

                                        <!-- Remove Svg Icon -->
                                        <svg v-if="edit_inclusions.length > 1 && !inc.id" @click="removeField(index, edit_inclusions)" width="24" height="24" class="cursor-pointer">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>

                                        <!-- Delete Svg Icon -->
                                        <svg v-if="edit_inclusions.length > 1 && inc.id" @click="deleteInclusion(inc.id)" width="24" height="24" class="cursor-pointer">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="header-width item-padding">
                    <h6> Deductions (-)</h6>
                    <div>
                        <div style="display: flex; flex-direction: column; gap: 10px;">
                            <div v-for="(deduction, index) in edit_deductions" :key="index">
                                <div style="display: flex; align-items: center; width: 100%;">
                                    <div style="display: flex; flex-direction: row; gap: 10px; width: 90%;">
                                        <b-form-select v-model="deduction.item">
                                            <b-form-select-option v-for="option in deduction_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                        </b-form-select>
                                    </div>

                                    <div style="width: 50px; margin-left: 10px; display: flex; gap: 4px; align-items: center;">
                                        <!-- Add Svg Icon -->
                                        <svg @click="addDedField(deduction.id, edit_deductions)" width="24" height="24" class="cursor-pointer">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                        </svg>

                                        <!-- Remove Svg Icon -->
                                        <svg v-if="edit_deductions.length > 1 && !deduction.id" @click="removeDedField(index, edit_deductions)" width="24" height="24" class="cursor-pointer">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>

                                        <!-- Delete Svg Icon -->
                                        <svg v-if="edit_deductions.length > 1 && deduction.id" @click="deleteDeduction(deduction.id)" width="24" height="24" class="cursor-pointer">
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="display: flex; align-items: center; margin-top: 20px;">
                <h6 style="margin-top: 7px;">Gross Salary</h6>
                <b-form-input v-model="edit_gross_salary" type="number" disabled style="width: 300px; margin-left: 16px;"></b-form-input>
            </div>
        </form>
    </b-modal>

    <!-- Designation - Payroll delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this payroll?</div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    computed
} from 'vue';

export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'department',
                    label: 'Department',
                    sortable: true
                },
                {
                    key: 'designation',
                    label: 'Designation',
                    sortable: true
                },
                {
                    key: 'gross_salary',
                    label: 'Gross Salary',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            designation_payroll: {
                designation: '',
                department: '',
            },

            create_inclusions: [{
                item: '',
                amount: 0
            }],

            create_deductions: [{
                item: '',
                amount: 0
            }],

            create_gross_salary: computed(() => {
                let total = 0;

                if (this.create_inclusions.length >= 1) {
                    this.create_inclusions.map((item) => {
                        total = parseInt(total) + parseInt(item.amount);
                    });
                }
                return total;
            }),

            delete_value: null,

            edit_designation_payroll: {
                id: null,
                designation: '',
                department: '',
            },

            edit_inclusions: [{
                item: '',
                amount: 0
            }],

            edit_deductions: [{
                item: '',
                amount: 0
            }],

            edit_gross_salary: computed(() => {
                let total = 0;

                if (this.edit_inclusions.length >= 1) {
                    this.edit_inclusions.map((item) => {
                        total = parseInt(total) + parseInt(item.amount);
                    });
                }
                return total;
            }),

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            items: state => state.payroll.designation_payroll,
            departments: state => state.department.departments,
            designations: state => state.designation.designation_by_department,
            inclusion_items: state => state.payroll.inclusion_items,
            deduction_items: state => state.payroll.deduction_items,

            payrollLoading: state => state.payroll.payrollLoading,
            payrollError: state => state.payroll.payrollError,

        }),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {
        this.$store.dispatch("payroll/fetchDesignationPayroll").then(() => {
            this.totalRows = this.items.length;
        });

        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("payroll/fetchInclusivePayrollItems")
        this.$store.dispatch("payroll/fetchDeductivePayrollItems")

        // Set the initial number of items
        this.totalRows = this.items.length;
    },

    methods: {
        getDesignationByDepartment() {
            this.$store.dispatch("designation/fetchDesignationsbyDepartment", this.designation_payroll.department)
        },

        addField(value, fieldType) {
            fieldType.push({
                value: "",
                item: '',
                amount: 0
            });
        },

        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        deleteInclusion(id) {
            // this.$store.dispatch('profile/deleteEducationalInformation', id).then(response => {  
            //     if(response.data.code == 200) { 
            //         this.$toast.add({severity: 'success', summary: 'Success!', detail: response.data.response, closable: false, life: 3000})
            //     }
            //     else {
            //         this.$toast.add({severity: 'error', summary: 'Error!', detail: response.data.response, closable: false, life: 3000})
            //     }
            // })
        },

        addDedField(value, fieldType) {
            fieldType.push({
                value: "",
                item: '',
                amount: 0
            });
        },

        removeDedField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        deleteDeduction(id) {
            // this.$store.dispatch('profile/deleteEducationalInformation', id).then(response => {  
            //     if(response.data.code == 200) { 
            //         this.$toast.add({severity: 'success', summary: 'Success!', detail: response.data.response, closable: false, life: 3000})
            //     }
            //     else {
            //         this.$toast.add({severity: 'error', summary: 'Error!', detail: response.data.response, closable: false, life: 3000})
            //     }
            // })
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        // resetModal() {
        //     this.designation_payroll.department = ''
        //     this.designation_payroll.designation = ''
        //     this.create_gross_salary = 0
        //     this.create_inclusions = []
        //     this.create_deductions = []
        // },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.$store.dispatch("payroll/addDesignationPayroll", {
                designation_payroll: this.designation_payroll,
                gross_salary: this.create_gross_salary,
                inclusions: this.create_inclusions,
                deductions: this.create_deductions
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.designation_payroll.department = ''
                    this.designation_payroll.designation = ''
                    this.create_gross_salary = 0
                    this.create_inclusions = []
                    this.create_deductions = []

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-xl')
            })
        },

        editItem(gross_salary) {
            this.edit_designation_payroll.id = gross_salary.id
            this.edit_designation_payroll.department = gross_salary.designation.department.name
            this.edit_designation_payroll.designation = gross_salary.designation.title
            this.edit_inclusions = gross_salary.inclusion
            this.edit_deductions = gross_salary.deduction
            this.edit_gross_salary = gross_salary.gross_salary
        },

        // resetEditModal() {
        //     this.edit_designation_payroll.department = ''
        //     this.edit_designation_payroll.designation = ''
        //     this.edit_designation_payroll.gross_salary = 0
        //     this.edit_designation_payroll.inclusion.inclusions = []
        //     this.edit_designation_payroll.deduction.deductions = []
        // },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {
            this.$store.dispatch("payroll/updateDesignationPayroll", {
                id: this.edit_designation_payroll.id,
                gross_salary: this.edit_gross_salary,
                inclusions: this.edit_inclusions,
                deductions: this.edit_deductions
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_designation_payroll.id = null
                    this.edit_designation_payroll.department = ''
                    this.edit_designation_payroll.designation = ''
                    this.edit_inclusions = []
                    this.edit_deductions = []
                    this.edit_gross_salary = 0

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit-xl')
            })
        },

        deleteItems(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("payroll/deleteDesignationPayroll", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>

<style scoped>
.form-inside-container {
    display: flex;
    gap: 20px;
    width: 100%;
}

.header-width {
    width: 50%;
}

.item-padding {
    padding-left: 12px;
    padding-right: 12px;
}

.button-style {
    padding: 2px;
    width: 32px;
    font-size: larger;
    text-align: center;
    color: white;
    background-color: #222131;
    border-radius: 4px;
    cursor: pointer;
}

@media screen and (max-width: 991px) {
    .form-inside-container {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }

    .header-width {
        width: 100%;
    }

    .item-padding {
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>
