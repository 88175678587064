<template>
<div>
    <Breadcrumbs main="Job" title="Job Details" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5">
                <div class="card p-3" style="width: 100%;">
                    <div class="task-board-task-scroll">
                        <div style="display: flex; flex-direction: column; height: 100%; overflow-y: auto; padding-right: 12px;">
                            <div>
                                <div style="display:flex; flex-direction: column; align-items: center; justify-content: center;" class="w-full">
                                    <img style="width: 100%; border-radius: 0.75rem; object-fit: cover; background-size: cover;" :src="host+job.banner_image" class="image-height">
                                </div>

                                <div style="margin-top: 25px; display:flex; flex-direction: column; align-items: start; justify-content: left; text-align: center;">
                                    <h5 style="text-align: left;">{{ job.title }}</h5>
                                    <h5 class="mt-2">
                                        <b-badge pill variant="success">{{ job.position }}</b-badge>
                                    </h5>
                                    <div class="mt-1 d-flex">
                                        <h5>
                                            <b-badge pill variant="primary">Vacancy: {{ job.vacancies }}</b-badge>
                                        </h5>
                                        <h5 style="margin-left: 12px;">
                                            <b-badge pill variant="info">Applied: {{ job.applied }}</b-badge>
                                        </h5>
                                    </div>
                                </div>

                                <div style="margin-top: 15px; display:flex; flex-direction: column; align-items: start; justify-content: left;; text-align: center; font-size: medium;">
                                    <h7>Post Date: {{ job.opening }}</h7>
                                    <h7>Deadline: <span style="color: red;">{{ job.deadline }}</span></h7>
                                </div>
                            </div>

                            <div style="margin-top: 20px;">
                                <div v-if="showDescription">
                                    <h7 v-if="job.description.length < 450">{{ job.description }}</h7>
                                    <h7 v-else>{{ job.description.substring(0, 450) }}</h7>
                                </div>
                                <div v-else class="pb-4 lg:pb-6">
                                    <div v-html="job.description"></div>
                                </div>
                                <div v-if="job.description.length > 450">
                                    <b-button v-if="showDescription" variant="primary" size="sm" @click="displayDescription" style="margin-top: 20px;">See More</b-button>
                                    <b-button v-else variant="primary" size="sm" @click="closeDescription">See Less</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="card">
                    <div class="card-header">
                        <div>
                            <h5>Edit Form</h5>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div style="display: flex; flex-direction: column;">
                                        <div style="width: 50%; display: flex; flex-direction: column;">
                                            <h6>Form Name</h6>
                                            <b-form-input v-model="form_data.form_name" type="text" placeholder="Enter form name"></b-form-input>
                                        </div>

                                        <div class="mt-4" style="display: flex; gap: 16px;">
                                            <div style="width: 45%;">
                                                <h6>Label</h6>
                                            </div>

                                            <div style="width: 45%;">
                                                <h6>Type</h6>
                                            </div>

                                            <div style="width: 10%;">
                                                <h6>Action</h6>
                                            </div>
                                        </div>

                                        <div style="display: flex; gap: 16px; margin-bottom: 12px;" v-for="(fo, index) in form_data.submission_form" :key="index">
                                            <div style="width: 45%;">
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-input v-model="fo.data_label" type="text" placeholder="Enter label"></b-form-input>
                                                </div>
                                            </div>

                                            <div style="width: 45%;">
                                                <div>
                                                    <b-form-select v-model="fo.data_type">
                                                        <b-form-select-option v-for="option in options" :value="option.value">{{ option.title }}</b-form-select-option>
                                                    </b-form-select>
                                                </div>
                                            </div>

                                            <div style="width: 10%; display: flex; align-items: center;">
                                                <!-- Add Svg Icon -->
                                                <svg @click="addField(input, form_data.submission_form)" width="24" height="24" class="ml-2 cursor-pointer" style="cursor: pointer; margin-right: 4px;">
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                </svg>

                                                <!-- Remove Svg Icon -->
                                                <svg v-show="form_data.submission_form.length > 1" @click="removeField(index, form_data.submission_form)" width="24" height="24" style="cursor: pointer; margin-left: 4px;">
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div class="row mt-2">
                                            <div class="col">
                                                <div class="form-group mb-0">
                                                    <button @click="onSubmit" class="btn btn-success mr-3">Submit</button>
                                                    <router-link to="/job/job/list">
                                                        <button class="btn btn-danger" href="#">Go Back</button>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

    
<script>
var primary = localStorage.getItem('primary_color') || '#FF0006';
var secondary = localStorage.getItem('secondary_color') || '#818284';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    mapState
} from 'vuex';
import {
    myHost
} from "../../../_helpers/host";
export default {
    data() {
        return {
            selected: 'a',
            host:myHost.host,
            options: [{
                    value: 'a',
                    text: 'First Option'
                },
                {
                    value: 'b',
                    text: 'Selected Option'
                },
                {
                    value: 'c',
                    text: 'Third Option'
                },
            ],

            form_data: {
                job: null,
                form_name: "",

                submission_form: [{
                    data_label: null,
                    data_type: null
                }]
            },

            options: [{
                    title: 'Text Field',
                    value: 'textfield'
                },
                {
                    title: 'File Field',
                    value: 'filefield'
                },
                {
                    title: 'Link Field',
                    value: 'textfield'
                },
                {
                    title: 'Paragraph',
                    value: 'paragraph'
                }
            ],

            search_text: '',
            selected_job: null,
            notes: '',
            tag_a_manager: null,

            managers: [{
                    value: null,
                    text: 'Tag a Manager'
                },
                {
                    value: 'a',
                    text: 'A'
                },
                {
                    value: 'b',
                    text: 'B'
                }
            ],

            showDescription: true
        };
    },

    watch: {
        form(newValue, oldValue) {
            if (this.form !== null) {
                this.form_data.job = this.$route.params.id
                this.form_data.form_name = this.form[0].form_name
                this.form_data.submission_form = this.form[0].submission_form
            }
        }
    },

    methods: {
        displayDescription() {
            this.showDescription = false
        },

        closeDescription() {
            this.showDescription = true
        },
        addField(value, fieldType) {
            fieldType.push({
                value: ""
            });
        },

        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        onSubmit() {
            this.form_data.job = this.$route.params.id

            this.$store.dispatch("job/addForm", this.form_data).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    },
    computed: {
        ...mapState({
            job: state => state.job.job_details,
            form: state => state.job.form

        }),
    },
    mounted() {
        this.$store.dispatch("job/fetchJobDetails", this.$route.params.id)
        this.$store.dispatch("job/fetchForm", this.$route.params.id)
    },
}
</script>

    
<style scoped>
.image-height {
    height: 310px;
}

.applicant-view {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 25px;
}

.task-board-task-scroll {
    position: relative;
    height: 75.5vh;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media screen and (max-width: 1680px) {
    .image-height {
        height: 270px;
    }

    .applicant-view {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}

@media screen and (max-width: 991px) {
    .image-height {
        height: 290px;
    }

    .applicant-view {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 20px;
    }

    .task-board-task-scroll {
        position: relative;
        height: 100%;
    }
}

@media screen and (max-width: 730px) {
    .applicant-view {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}

@media screen and (max-width: 645px) {
    .image-height {
        height: 260px;
    }
}

@media screen and (max-width: 430px) {
    .image-height {
        height: 240px;
    }

    .applicant-view {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 20px;
    }
}
</style>
