<template>
  <div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Note Detail"/>

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-8">
                  <h5>Note: {{ noteDetails.title }}</h5><br>
<!--                  <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing-->
<!--                          style="display: flex; gap: 12px; align-items: center;" @click="resetModal()">-->
<!--                    <i class="fa fa-light fa-plus"></i>-->
<!--                    <h7>Add New Ledger</h7>-->
<!--                  </button>-->
                </div>

              </div>
            </div>

            <div class="card-body">
              <b-row>
                <b-col xl="6">

                  <b-input-group class="datatable-btn">
                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>

                </b-col>

                <b-col xl="6">
                  <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue">
                <b-table
                    show-empty
                    stacked="md"
                    sticky-header
                    :items="items"
                    :fields="tablefields"
                    :filter="filter"

                    @filtered="onFiltered"
                >

                  <template #cell(title)="data">
                    {{ data.item.title }} <br>
                    <router-link :to="{ path: '/finance-and-accounts/account/ledger/' +  data.item.lid  }">
                      <small style="font-weight: bold;">{{ data.item.lid }}</small>
                    </router-link>
                  </template>


                  <template #cell(debit)="data">
                    <span>{{ data.item.debit != 0 ? data.item.debit : "" }}</span>
                  </template>

                  <template #cell(credit)="data">
                    <span>{{ data.item.credit != 0 ? (data.item.credit * -1) : "" }}</span>
                  </template>
<!--                  <template #cell(action)="data">-->
<!--                                        <span style="display: flex; gap: 12px;">-->
<!--                                              <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(data.item.title)" title="Delete"></feather>-->
<!--                                             </span>-->
<!--                  </template>-->

                </b-table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <b-modal-->
<!--        id="modal-center"-->
<!--        centered-->
<!--        hide-header-->
<!--        @ok="handleDeleteOk"-->
<!--    >-->
<!--      <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this item?</div>-->
<!--    </b-modal>-->
<!--    <b-modal-->
<!--        id="modal-prevent-closing"-->
<!--        ref="modal"-->
<!--        size="lg"-->
<!--        title="Add Ledger"-->
<!--        @show="resetModal"-->
<!--        @hidden="resetModal"-->
<!--        @ok="handleOk"-->
<!--    >-->
<!--      <form ref="form" @submit.stop.prevent="handleSubmit">-->

<!--        <div class="row">-->
<!--          <div class="col-sm-4">-->
<!--            <div class="form-group">-->
<!--              <label>Category</label>-->
<!--              <b-form-select v-model="structure_item.category"-->
<!--                             @change="updateGroup(structure_item.category, true)">-->
<!--                <b-form-select-option v-for="option in accountCategories" :value="option.id">{{-->
<!--                    option.title-->
<!--                  }}-->
<!--                </b-form-select-option>-->
<!--              </b-form-select>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-4">-->
<!--            <div class="form-group">-->
<!--              <label>Group</label>-->
<!--              <input class='form-control' type="text" list="groups" v-model="structure_item.group"-->
<!--                     @change="updateLedgers(structure_item.group, true)"/>-->
<!--              &lt;!&ndash;                                              <input class='form-contro' type="text" list="ledgers" v-model="debit.ledger_name"/>&ndash;&gt;-->
<!--              <datalist id="groups">-->
<!--                <option v-for="option in groups" :value="option.title">{{ option.title }}</option>-->
<!--              </datalist>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-sm-4">-->
<!--            <div class="form-group">-->
<!--              <label>Ledger</label>-->
<!--              <input class='form-control' type="text" list="ledgers" v-model="structure_item.ledger"/>-->
<!--              <datalist id="ledgers">-->
<!--                <option v-for="option in ledgers" :value="option.title">{{ option.title }}</option>-->
<!--              </datalist>-->
<!--            </div>-->
<!--          </div>-->


<!--        </div>-->

<!--      </form>-->
<!--    </b-modal>-->

    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {mapState} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import balanceScale from "vue-rate-it/glyphs/balance-scale";

export default {
  components: {
    Datepicker
  },

  data() {
    return {
      tablefields: [
        // { key: 'index', label: '#', sortable:true },
        {key: 'title', label: 'Ledger title', sortable: true, class: 'text-start col-3'},
        // { key: 'lid', label: 'Ledger ID', sortable: true },
        {key: 'group.title', label: 'group', class: 'text-start col-2'},
        {key: 'group.pre_defined.title', label: 'Pre-Defined', class: 'text-start col-2', sortable: true},
        {key: 'debit', label: 'Debit', class: 'text-right'},
        {key: 'credit', label: 'Credit', class: 'text-right'},
        // {key: 'action', label: 'Action'}

      ],
      structure_item:
          {
            category: null,
            group: null,
            ledger: null,
            amount: null,
            is_debit: true
          },
      groups: [],
      ledgers: [],
      delete_value: "",
      delete_slug: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15]
    };
  },

  computed: {
    ...mapState('account', ["noteDetails", "submitError", "submitSuccess", "submitLoading"]),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    }
  },
  created() {
    console.log(this.$route.params.id)
    this.$store.dispatch('account/fetchAccountNoteDetails', this.$route.params.id).then(result => {
      this.items = this.noteDetails.ledger
      this.totalRows = this.items.length;
    })
    // this.$store.dispatch('account/fetchAccountCategory')
    // this.$store.dispatch('account/fetchAccountGroup').then(result => {
    //   this.groups = this.accountGroups
    //
    // })
    // this.$store.dispatch('account/fetchAccountLedger').then(result => {
    //   this.ledgers = this.accountLedgers
    //
    // })

  },
  watch: {
    // accountCategories(oldValue, newValue) {
    //
    //   // this.options = this.accountCategories
    //
    // },
    noteDetails(oldValue, newValue) {

      this.items = this.noteDetails.ledger
      this.totalRows = this.items.length

    },
    // accountLedgers(oldValue, newValue) {
    //
    //   // this.options = this.accountCategories
    //
    // },
    //
    // accountGroups(oldValue, newValue) {
    //
    //   // this.items = this.accountGroups
    //
    // },


  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },

  methods: {
    // updateGroup(id) {
    //   console.log("category_id", id)
    //
    //   this.$store.dispatch('account/fetchAccountGroupByCategory', id).then(result => {
    //
    //     this.groups = this.accountGroups
    //
    //   })
    //
    // },
    // updateLedgers(id) {
    //   console.log(id)
    //   this.$store.dispatch('account/fetchAccountLedgerByGroup', id).then(result => {
    //
    //     this.ledgers = this.accountLedgers
    //
    //   })
    //
    // },
    // resetModal(){
    //   this.structure_item =
    //       {
    //         category: null,
    //         group: null,
    //         ledger: null,
    //         amount: null,
    //         is_debit: true
    //       }
    // },
    balanceScale() {
      return balanceScale
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // handleOk(bvModalEvent) {
    //   bvModalEvent.preventDefault()
    //   this.handleSubmit()
    // },
    // handleSubmit() {
    //   this.$store.dispatch("account/addLedgerToNote", {
    //     pk: this.$route.params.id,
    //     ledger_title: this.structure_item.ledger
    //   }).then(response => {
    //     if (response.code == 200) {
    //       this.structure_item =
    //           {
    //             category: null,
    //             group: null,
    //             ledger: null,
    //             amount: null,
    //             is_debit: true
    //           },
    //           Swal.fire({
    //             title: "Success!",
    //             text: response.response,
    //             icon: "success"
    //           });
    //     } else {
    //       Swal.fire({
    //         title: "Error!",
    //         text: response.response,
    //         icon: "error"
    //       });
    //     }
    //
    //   })
    // },
    // deleteItem(title) {
    //   console.log(title)
    //   this.delete_value = title
    //   this.delete_slug = this.$route.params.id
    // },
    //
    // handleDeleteOk() {
    //   this.$store.dispatch("account/deleteLedgerFromNote", {
    //     pk: this.delete_slug,
    //     ledger_title: this.delete_value
    //   }).then(response => {
    //     if (response.code == 200) {
    //       Swal.fire({
    //         title: "Success!",
    //         text: response.response,
    //         icon: "success"
    //       });
    //
    //       this.delete_value = null
    //
    //     } else {
    //       Swal.fire({
    //         title: "Error!",
    //         text: response.response,
    //         icon: "error"
    //       });
    //     }
    //   })
    // }
  },


};
</script>
