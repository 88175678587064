<template>
<div>
    <Breadcrumbs main="Candidate" title="Careers" />

    <!-- Container-fluid starts -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">
                        <div class="d-flex flex-column flex-sm-row" style="margin-bottom: 36px;">
                            <div class="mr-3 mr-md-4 field-width">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search" v-model="search_text" aria-label="Search" aria-describedby="basic-addon2">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">
                                            <b-icon icon="search"></b-icon>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex mt-3 mt-sm-0">
                                <div class="mr-3 mr-md-4 field-width">
                                    <b-form-select v-model="selected_job" :options="statuses"></b-form-select>
                                </div>
                                <button type="button" class="btn btn-primary">Reset</button>
                            </div>
                        </div>

                        <div class="job-view">
                            <div v-for="job in jobs" :key="job.index">
                                <router-link :to="`/job/job/apply/${job.id}`">
                                    <div style="height: fit-content; display: flex; flex-direction: column; background-color: #f9fafb; border-radius: 0.75rem; filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));">

                                        <div style="position: relative;" class="w-100">
                                            <img :src="host + job.banner_image" style="width: 100%; height: 250px; border-top-left-radius: 0.75rem; border-top-right-radius: 0.75rem; object-fit: cover;">
                                        </div>

                                        <div style="position: absolute; top: 8px; left: 8px;">
                                            <b-badge pill variant="success">{{ job.status }}</b-badge>
                                        </div>

                                        <div class="m-3">
                                            <div style="width:100%; display: flex; justify-content: space-between;">
                                                <!-- <router-link :to="`/job/job/apply/${job.id}`" style="font-size: 16px; font-weight: 600; width: 100%;"> -->
                                                <h5>{{ job.title }}</h5>
                                                <!-- </router-link> -->

                                                <!-- <p style="width: 20%; text-align: right;">({{ job.applied }}) Applied</p> -->
                                            </div>

                                            <div style="margin-top: 5px;">
                                                <h5 style="font-size: 15px;">{{ job.position }}</h5>
                                                <h6 style="font-size: 15px;">Vacancy: {{ job.vacancies }}</h6>
                                            </div>

                                            <div style="margin-top: 5px; display: flex; justify-content: space-between;">
                                                <div style="display: flex; flex-direction: column;">
                                                    <h7 style="font-size: 13px;">Post Date: {{ job.opening }}</h7>
                                                    <h7 style="font-size: 13px;">Deadline: {{ job.deadline }}</h7>
                                                </div>
                                                <!-- <div style="display: flex; gap: 12px; justify-items: end; align-items: end;">
                                                    <router-link :to="`/job/job/edit/${job.id}`">
                                                        <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                    </router-link>
                                                    <div style="cursor: pointer;">
                                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteJob(job.id)" title="Delete"></feather>

                                                    </div>
                                                </div> -->

                                            </div>

                                        </div>
                                    </div>
                                </router-link>
                            </div>
                           
                        </div>

                        <!-- delete Modal -->
                        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
                            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this Job?</div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

    
<script>
var primary = localStorage.getItem('primary_color') || '#FF0006';
var secondary = localStorage.getItem('secondary_color') || '#818284';
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {
    myHost
} from "../../../_helpers/host";
export default {
    data() {
        return {
            host: myHost.host,
            search_text: '',
            selected_job: null,

            statuses: [{
                    value: null,
                    text: 'Select a Status'
                },
                {
                    value: 'a',
                    text: 'Active'
                },
                {
                    value: 'b',
                    text: 'Inactive'
                }
            ],
            temp_job_id: '',

        };

    },
    computed: {
        ...mapState({
            jobs: state => state.job.job_list,
        }),
    },

    mounted() {
        this.$store.dispatch("job/fetchJob");

    },
    methods: {
        deleteJob(id) {
            this.temp_job_id = id
        },
        handleDeleteOk() {
            this.$store.dispatch("job/deleteJob", this.temp_job_id).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center')
            })
        }

    }
};
</script>

    
<style scoped>
.job-view {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 25px;
}

@media screen and (max-width: 1536px) {
    .job-view {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 25px;
    }
}

@media screen and (max-width: 750px) {
    .job-view {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 25px;
    }

}

@media screen and (max-width: 430px) {
    .job-view {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 20px;
    }
}

.header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 460px) {
    .header-mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: start;
    }
}
</style>
