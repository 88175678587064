<template>
    <div>
        <Breadcrumbs main="Job" title="Question Set Create" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form">

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Group Name</label>
                                            <input class="form-control" type="text" placeholder="Enter group name" v-model="question_set.group_name">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Question Type</label>
                                            <input class="form-control" type="text" placeholder="Enter question type" v-model="question_set.question_type">
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Designation</label>
                                            <input class="form-control" type="text" placeholder="Enter designation *" v-model="question_set.designation">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Phone Number</label>
                                            <input class="form-control" type="text" placeholder="Enter phone number *" v-model="question_set.phone">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input class="form-control" type="text" placeholder="Enter email address *" v-model="question_set.email">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Client Status</label>
                                            <select class="form-control digits" v-model="question_set.status">
                                                <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mb-0">
                                            <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                            <router-link to="/job/question-set">
                                                <button class="btn btn-danger" href="#">Go Back</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data(){
        return{
            question_set: {
                group_name: '',
                question_type: ''
            }
        };
    },

    methods: {
        onSubmit(event) {
            event.preventDefault()
            alert(JSON.stringify(this.question_set))
        }
    }
};
</script>
