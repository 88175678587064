<template>
    <div>
        <Breadcrumbs main="Finance & Accounts" title="Bank Account Create" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Beneficiery Name</label>
                                            <input class="form-control" type="text" placeholder="Enter beneficiery name" v-model="bank.beneficiery_name">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Account Title</label>
                                            <input class="form-control" type="text" placeholder="Enter account title" v-model="bank.account_title">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Account Number</label>
                                            <input class="form-control" type="text" placeholder="Enter account number" v-model="bank.account_number">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Account Type</label>
                                            <input class="form-control" type="text" placeholder="Enter account type" v-model="bank.account_type">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Bank Name</label>
                                            <input class="form-control" type="text" placeholder="Enter bank name" v-model="bank.bank_name">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Branch Name & Address</label>
                                            <input class="form-control" type="text" placeholder="Enter branch address" v-model="bank.branch_detail">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Branch Routing Number</label>
                                            <input class="form-control" type="text" placeholder="Enter branch routing number" v-model="bank.branch_routing_number">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Swift Code</label>
                                            <input class="form-control" type="text" placeholder="Enter swift code" v-model="bank.swift_number">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Beneficiery E-mail Address</label>
                                            <input class="form-control" type="text" placeholder="Enter beneficiery email address" v-model="bank.beneficiery_email">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Active Status</label>
                                            <select class="form-control digits" v-model="bank.status">
                                                <option v-for="(status, index) in statuses" :key="index" :value="status.value">{{ status.title }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mb-0">
                                            <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                            <router-link to="/finance-and-accounts/banking-info">
                                                <button class="btn btn-danger" href="#">Go Back</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data(){
        return{
            bank: {
                beneficiery_name: '',
                account_title: '',
                account_number: '',
                account_type: '',
                bank_name: '',
                branch_detail: '',
                branch_routing_number: '',
                swift_number: '',
                beneficiery_email: '',
                status: true
            },

            statuses: [
                {
                    title: 'Active',
                    value: true
                }, 
                {
                    title: 'Inactive',
                    value: false
                }
            ]
        };
    },

    methods: {
        onSubmit() {
            this.$store.dispatch("bank/addBankAccount", this.bank).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.bank.beneficiery_name = '',
                    this.bank.account_title = '',
                    this.bank.account_number = '',
                    this.bank.account_type = '',
                    this.bank.bank_name = '',
                    this.bank.branch_detail = '',
                    this.bank.branch_routing_number = '',
                    this.bank.swift_number = '',
                    this.bank.beneficiery_email = '',
                    this.bank.status = true

                    setTimeout(() => {
                        this.$router.push('/finance-and-accounts/banking-info') 
                    }, 2000)
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
