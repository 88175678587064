import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'DASHBOARD_UPDATE',
    smsDelete : 'DASHBOARD_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {

        myWorkspaceCount:[],
        operationAssetCount:{},
        operationProjectCount:{},
        operationLeadClientCount:{},
        activity_summary:[],
        operationVendorCount:{},
        operationEODCount:{},
        operationLeaveCount:{},
        completion_ratio:{},
        operationGrievanceCount:{},
        operationTicketCount:{},
        operationRequisitionCount:{},
        operationOrganizationCount:{},
        calendar_data:[],


        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,

        SET_CALENDAR_DATA(state, data) {
            state.calendar_data = data;
        },

        SET_COMPLETION_RATIO(state, ratio) {
            state.completion_ratio = ratio;
        },
        SET_SUBTASK_PER_DAY(state, subtaskDay) {
            state.completion_subtask_day = subtaskDay;
        },

        SET_ACTIVITY_SUMMARY(state, summary) {
            state.activity_summary = summary;
        },



      
        SET_MY_WORKSPACE_COUNT (state, count) {
            state.myWorkspaceCount = count;
        },

        SET_OPERATION_ASSET_COUNT (state, count) {
            state.operationAssetCount = count;
        },
        SET_OPERATION_PROJECT_COUNT (state, count) {
            state.operationProjectCount = count;
        },

        SET_OPERATION_LEAD_CLIENT_COUNT (state, count) {
            state.operationLeadClientCount = count;
        },

        SET_OPERATION_EOD_COUNT (state, count) {
            state.operationEODCount = count;
        },

        SET_OPERATION_VENDOR_COUNT(state, count) {
            state.operationVendorCount = count;
        },

        SET_OPERATION_LEAVE_COUNT(state, count) {
            state.operationLeaveCount = count;
        },


        SET_OPERATION_GRIEVANCE_COUNT(state, count) {
            state.operationGrievanceCount = count;
        },


        SET_OPERATION_TICKET_COUNT(state, count) {
            state.operationTicketCount = count;
        },

        SET_OPERATION_REQUISITION_COUNT(state, count) {
            state.operationRequisitionCount = count;
        },

        SET_OPERATION_ORGANIZATION_COUNT(state, count) {
            state.operationOrganizationCount = count;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {

        

        async fetchCalendarData ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_calendar_data, config);
                let response = result.data.data
              
                commit('SET_CALENDAR_DATA', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        

        async fetchActivitySummary ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_activity_summary, config);
                let response = result.data.data
              
                commit('SET_ACTIVITY_SUMMARY', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

          async fetchCompletionSubTask ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_subtask_per_day, config);
                let response = result.data.completed_subtasks_per_day
              
                commit('SET_SUBTASK_PER_DAY', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchCompletionRatio ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_subtask_completed_percentage, config);
                let response = result.data.data
              
                commit('SET_COMPLETION_RATIO', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    
        async fetchMyWorkspaceCount ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.my_workspace_count, config);
                let response = result.data.data
              
                commit('SET_MY_WORKSPACE_COUNT', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchOperationAssetCount ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.operation_asset_count, config);
                let response = result.data.data
              
                commit('SET_OPERATION_ASSET_COUNT', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async fetchOperationProjectCount ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.operation_project_count, config);
                let response = result.data.data
              
                commit('SET_OPERATION_PROJECT_COUNT', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchOperationLeadClientCount ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.operation_lead_client_count, config);
                let response = result.data.data
              
                commit('SET_OPERATION_LEAD_CLIENT_COUNT', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchOperationVendorCount ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.operation_vendor_contact_vendorCount, config);
                let response = result.data.data
              
                commit('SET_OPERATION_VENDOR_COUNT', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async fetchOperationEODCount ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.operation_eod_count, config);
                let response = result.data.data
              
                commit('SET_OPERATION_EOD_COUNT', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async fetchOperationLeaveCount ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.operation_leave_count, config);
                let response = result.data.data
              
                commit('SET_OPERATION_LEAVE_COUNT', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },



        async fetchOperationGrievanceCount ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.operation_grievance_count, config);
                let response = result.data.data
              
                commit('SET_OPERATION_GRIEVANCE_COUNT', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async fetchOperationTicketCount ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.operation_ticket_count, config);
                let response = result.data.data
              
                commit('SET_OPERATION_TICKET_COUNT', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async fetchOperationRequsitionCount ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.operation_requisition_count, config);
                let response = result.data.data
              
                commit('SET_OPERATION_REQUISITION_COUNT', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async fetchOperationOrganizationCount ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.operation_organization_count, config);
                let response = result.data.data
              
                commit('SET_OPERATION_ORGANIZATION_COUNT', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },



        

        

        


  
    },

    getters: {}
}
