<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Budget" title="Budget Requests" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue" v-if="!loading && !error">
                            <b-table show-empty stacked="md" :items="items" :fields="requestfields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ (currentPage - 1) * perPage + data.index + 1 }}
                                </template>

                                <template #cell(approved_amount)="data">
                                    <span v-if="data.item.approved_amount==null">Not Yet Approved</span>
                                    <span v-else>{{ data.item.approved_amount }}</span>
                                </template>
                                <template #cell(budget_category)="data">
                                    {{data.item.budget_category.title}}
                                </template>

                                <template #cell(requested_by)="data">
                                    {{ data.item.requested_by.first_name }}
                                </template>
                                <template #cell(refering_to)="data">
                                    <div v-if="data.item.refering_to !== null && data.item.refering_to.length > 0">
                                        <div v-for="user in data.item.refering_to" :key="user.id">
                                            <p>{{ user.first_name }}</p>
                                        </div>
                                    </div>
                                </template>

                                <template #cell(status)="row">
                                    <span v-if="row.value == 'APPROVED'">
                                        <b-badge pill variant="success">{{ row.value }}</b-badge>
                                    </span>
                                    <span v-if="row.value == 'REJECTED'">
                                        <b-badge pill variant="danger">{{ row.value }}</b-badge>
                                    </span>
                                    <span v-if="row.value == 'PENDING'">
                                        <b-badge pill variant="primary">{{ row.value }}</b-badge>
                                    </span>
                                </template>

                                <template #cell(action)="data">
                                    <span style="display: flex; gap: 12px;">
                                        <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(data.item)" title="Reply"></feather>

                                        <feather @click="editRequestedBudget(data.item)" type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Budget Approve" v-b-modal.modal-prevent-closing-budget-edit></feather>
                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete"></feather>
                                    </span>
                                </template>
                            </b-table>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
    <!-- Status Update Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Update Leave Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>
    <!-- Edit Requested Budget -->
    <b-modal id="modal-prevent-closing-budget-edit" ref="modal" title="Approve Budget" @show="resetBudgetModal" @hidden="resetBudgetModal" @ok="handleRequestOk">
        <form ref="form" @submit.stop.prevent="handleRequestSubmit">
            <b-form-group label="Budget Category" label-for="name-input" invalid-feedback="Budget category is required">
                <b-form-input id="name-input" v-model="edit_budget.category" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Details" label-for="name-input" invalid-feedback="Details is required">
                <b-form-textarea id="textarea-auto-height" v-model="edit_budget.detail_note" placeholder="Enter budget details" rows="3" max-rows="8" disabled></b-form-textarea>
            </b-form-group>
            <b-form-group label="Requested Amount" label-for="name-input" invalid-feedback="Budget amount is required">
                <b-form-input id="name-input" type="number" v-model="edit_budget.budget_amount" placeholder="Enter requested amount" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Approved Amount" label-for="name-input" invalid-feedback="Budget amount is required">
                <b-form-input id="name-input" type="number" v-model="edit_budget.approved_amount" placeholder="Enter approved amount"></b-form-input>
            </b-form-group>
            <b-form-group label="Status" label-for="name-input" invalid-feedback="Status is required">
                <b-form-select v-model="edit_budget.status" :options="options"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Reply Create Modal -->

    <b-modal id="modal-scrollable" ref="modal" title="Budget Replies" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleOk">

        <h6>Already Refered</h6>
        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="user in this.my_reply.refer_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ user.first_name}}</h7>
                    <b-icon icon="x" style="color: white;"></b-icon>
                </div>
            </div>
        </div>
        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>

        <div style="margin-top: 30px;" v-if="ticket_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in ticket_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleBudgetReplySubmit()">
                <button @click="handleBudgetReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>
    <!-- Leave Allocate Delete Modal -->
    <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this refere?</div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Multiselect from 'vue-multiselect';
import {
    myHost
} from "../../../_helpers/host";

export default {
    components: {
        Multiselect
    },
    data() {
        return {
            host: myHost.host,
            requestfields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'budget_category',
                    label: 'Budget Category',
                    sortable: true
                },

                {
                    key: 'budget_amount',
                    label: 'Requested Amout',
                    sortable: true
                },
                {
                    key: 'approved_amount',
                    label: 'Approved Amout',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'requested_by',
                    label: 'Requested by',
                    sortable: true
                },
                {
                    key: 'refering_to',
                    label: 'Referring To',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            requests: [],

            edit_budget: {
                budget_category: '',
                category: '',
                detail_note: '',
                request_note: '',
                budget_amount: null,
                approved_amount: null,
                status: ''
            },

            options: [{
                    value: 'PENDING',
                    text: 'Pending'
                },
                {
                    value: 'APPROVED',
                    text: 'Approved'
                },
                {
                    value: 'REJECTED',
                    text: 'Rejected'
                }
            ],

            filter: null,
            assignedMembers: [],
            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            temp_id: '',

            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15]
        };
    },
    watch: {
        // budgetRequests(oldValue, newValue) {

        //     this.requests = this.budgetRequests

        // },
        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        }
    },
    computed: {
        // ...mapState('budget', ["budgetRequests", "budgetError", "budgetLoading", "submitError", "submitSuccess", "submitLoading"]),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        ...mapState({
            budget_Requests: state => state.budget.budgetRequests,
            loading: state => state.budget.submitLoading,
            error: state => state.budget.submitError,
            user_list: state => state.employee.employee_list,
            ticket_reply: state => state.budget.ticket_reply,

        }),
    },
    created() {
        // this.$store.dispatch('budget/fetchBudgetRequests').then(result => {
        //     this.requests = this.budgetRequests
        // })

    },

    mounted() {
        // Set the initial number of items
        this.$store.dispatch('budget/fetchBudgetRequests').then(() => {
            this.items = this.budget_Requests
            this.totalRows = this.items.length;
        })
        this.$store.dispatch("employee/fetchEmployeeList");

    },

    methods: {
        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },
        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.my_reply.refer_to = type.refering_to
            this.$store.dispatch("budget/fetchBudgetReply", this.my_reply.id)
        },
        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("budget/BudgetAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })

        },
        handleAllocationDeleteOk() {
            this.$store.dispatch("budget/BudgetAddDelete", {
                id: this.my_reply.id,
                refer_id: this.temp_id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },
        deletetAllocation(id) {
            this.temp_id = id
        },
        handleBudgetReplySubmit() {

            this.$store.dispatch("budget/addBudgetReply", {
                budget_request: this.my_reply.id,
                reply: this.my_reply.reply
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.my_reply.id = '',
                        this.my_reply.reply = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        editRequestedBudget(item) {
            console.log(item.budget_category.title)
            this.edit_budget.id = item.id
            this.edit_budget.budget_category = item.budget_category.id
            this.edit_budget.category = item.budget_category.title
            this.edit_budget.detail_note = item.detail_note
            this.edit_budget.request_note = item.request_note
            this.edit_budget.budget_amount = item.budget_amount
            this.edit_budget.approved_amount = item.approved_amount
            this.edit_budget.status = item.status
        },

        handleRequestOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleRequestSubmit()
        },

        handleRequestSubmit() {
            this.$store.dispatch("budget/requestBudgetEdit", {

                id: this.edit_budget.id,
                data: this.edit_budget
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = [],
                        this.member = [],
                        setTimeout(() => {
                            this.$router.push('/finance-and-accounts/budget/budget-request/list')
                        }, 3000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-budget-edit')
            })
        },
    }
};
</script>

<style scoped>
.header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 460px) {
    .header-mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: start;
    }
}

.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
    word-break: break-all;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
