import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'DEPARTMENT_UPDATE',
    smsDelete : 'DEPARTMENT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        departments: [],
        department_detail: {},
        
        departmentLoading: false,
        departmentError: false,


        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_DEPARTMENT (state, department) {
            state.departments = department;
        },

        // SET_PROJECT_DETAIL (state, holiday) {
        //     state.holiday_detail = holiday;
        // },

        DEPARTMENT_LOADING (state, loading) {
            state.departmentLoading = loading;
        },
        DEPARTMENT_ERROR (state, loading) {
            state.departmentError = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateDepartment ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
               
                async () => {
                    response= await axios.patch(V1API.update_department+payload.id,
                        {name:payload.name}
                        ,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchDepartments');

                }
            )
            return response
        },
    

        
        async deleteDepartment ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_department + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchDepartments');

            return response
        },

        

        async fetchDepartments ({ commit }) {
            commit('DEPARTMENT_LOADING', true);
            commit('DEPARTMENT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_all_departments, config);
                let response = result.data.data
              
                commit('SET_DEPARTMENT', response);

            } catch(error) {
                commit('DEPARTMENT_ERROR', true);
            }
            commit('DEPARTMENT_LOADING', false);
        },





        async addDepartment ({commit, dispatch}, payload) {
            commit('DEPARTMENT_LOADING', true);
            commit('DEPARTMENT_ERROR', false);
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            try{
                let response = await axios.post(V1API.create_department, payload, config).then(result=>{
                    
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchDepartments')
                return response

            }catch (e) {
                
                commit('SUBMIT_ERROR', true);
            }finally {
              
                commit('DEPARTMENT_LOADING', false);
            }
        }
    },

    getters: {}
}
