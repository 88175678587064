<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img
                    class=" for-light"
                    src="../assets/images/logo/login.png"
                    alt="looginpage"
                    style="max-width: 20%"
                  />
                  <img
                    class="img-fluid for-dark"
                    src="../assets/images/logo/logo_dark.png"
                    alt="looginpage"
                    style="max-width: 20%"
                  />
                </a>
              </div>
              <div class="login-main login-form-card">
                <form class="theme-form">
                  <h4>Create your account</h4>
                  <p>Enter your personal details to create account</p>
                  <div class="form-group">
                    <label class="col-form-label pt-0">Your Name</label>
                    <div class="form-row">
                      <div class="col-6">
                        <input
                          class="form-control"
                          type="text"
                          required=""
                          placeholder="First name"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          class="form-control"
                          type="text"
                          required=""
                          placeholder="Last name"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label">Company Name</label>
                    <input
                      class="form-control"
                      type="text"
                      required=""
                      placeholder="Life Tech Ltd."
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input
                        class="form-control"
                        type="email"
                        required=""
                        placeholder="Test@gmail.com"
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div style="position: relative; width: 100%;">
                      <div style="position: absolute; top: 0px; bottom: 0px; right: 0px; padding-right: 28px; display: flex; align-items: center;">
                          <i style="position: absolute; display: flex; justify-content: center; cursor: pointer;" :class="passwordToggleIcon" @click="togglePasswordVisibility" />
                      </div>
                      <input
                        class="form-control"
                        :type="passwordFieldType"
                        name="login[password]"
                        required=""
                        placeholder="*********"
                      />
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox" />
                      <label class="text-muted" for="checkbox1"
                        >Agree with<a class="ml-2" href="#"
                          >Privacy Policy</a
                        ></label
                      >
                    </div>
                    <button class="btn btn-primary btn-block" type="submit">
                      Create Account
                    </button>
                  </div>
<!--                  <h6 class="text-muted mt-4 or">Or signup with</h6>-->
<!--                  <div class="social mt-4">-->
<!--                    <div class="btn-showcase">-->
<!--                      <a class="btn btn-light">-->
<!--                        <i class="fa fa-google txt-linkedin"></i>-->
<!--                        Google-->
<!--                      </a>-->
<!--                      <a class="btn btn-light">-->
<!--                        <feather type="twitter" class="txt-twitter"></feather-->
<!--                        >twitter</a-->
<!--                      >-->
<!--                      <a class="btn btn-light">-->
<!--                        <feather type="facebook" class="txt-fb"></feather-->
<!--                        >facebook</a-->
<!--                      >-->
<!--                    </div>-->
<!--                  </div>-->
                  <p class="mt-4 mb-0">
                    Already have an account?
                    <router-link class="ml-2" tag="a" to="/auth/login">
                      Login
                    </router-link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            passwordFieldType: 'password'
        };
    },

    computed: { 
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'fa fa-regular fa-eye' : 'fa fa-regular fa-eye-slash'
        }
    },

    methods: {
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        }
    }
};
</script>
