<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Requisition Create" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Title</label>
                                        <input class="form-control" type="text" placeholder="Enter requisition title" v-model="requisition.title" style="height: 44px;">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Refer To</label>
                                        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Requisition Type</label>
                                        <input style="height: 44px;" class="form-control" type="text" placeholder="Select or enter a type" list="requ_types" v-model="requisition.type" />
                                        <datalist id="requ_types">
                                            <option v-for="(r_item, index) in requ_types" :key="index">
                                                <span>{{ r_item.name }}</span>
                                            </option>
                                        </datalist>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Project</label>
                                        <select style="height: 44px;" class="form-control digits" v-model="requisition.project">
                                            <option v-for="(project, index) in projects" :key="index" :value="project.id">{{ project.title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Items</label>
                                        <ItemTable style="margin-bottom: 10px;" id="item-table" @itemsUpdated="updateItems" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Grand Total</label>
                                        <input style="height: 44px;" class="form-control" type="number" placeholder="Enter grand total" v-model="requisition.grand_total" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Note</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter note..." rows="3" v-model="requisition.note"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3" :disabled="isDisabled">Create</button>
                                        <router-link to="/finance-and-accounts/account/requisition/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Client Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add New Client" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <div class="form-group">
                <label>Name (Contact Person)</label>
                <input class="form-control" type="text" placeholder="Enter contact person's name" v-model="client.name">
            </div>

            <div class="form-group">
                <label>Company</label>
                <input class="form-control" type="text" placeholder="Enter company name" v-model="client.company">
            </div>

            <div class="form-group">
                <label>Designation</label>
                <input class="form-control" type="text" placeholder="Enter designation" v-model="client.designation">
            </div>

            <div class="form-group">
                <label>Phone Number</label>
                <input class="form-control" type="text" placeholder="Enter phone number" v-model="client.phone">
            </div>

            <div class="form-group">
                <label>Email</label>
                <input class="form-control" type="text" placeholder="Enter title address" v-model="client.title">
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import {
    computed
} from "vue";
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import ItemTable from "./item-table.vue";
import Multiselect from 'vue-multiselect';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        ItemTable,
        Multiselect
    },

    data() {
        return {
            isDisabled:false,
            requisition: {
                title: '',
                refering_to: [],
                details: [],
                type: null,
                project: null,
                total: computed(() => {
                    let total = 0;
                    if (this.requisition.details.length >= 1) {
                        this.requisition.details.map((item) => {
                            total = total + item.amount;
                        });
                    }
                    return total;
                }),
                grand_total: computed(() => {
                    if (this.requisition.total !== 0) {
                        const tax = this.requisition.vat_tax || 0;
                        const discount = this.requisition.discount || 0;
                        let gtotal =
                            this.requisition.total +
                            tax * (this.requisition.total / 100) -
                            discount * (this.requisition.total / 100);
                        return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                    }
                    return 0;
                }),
                // grand_total: 0,
                note: null
            },

            assignedMembers: [],

            client: {
                name: '',
                company: '',
                designation: '',
                phone: '',
                title: '',
                status: 'Active'
            }
        }
    },

    computed: {
        ...mapState({
            user_list: state => state.employee.employee_list,
            projects: state => state.project.project,
            requ_types: state => state.requisition.requisition_type
        })
    },

    mounted() {
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("project/fetchProject");
        this.$store.dispatch("requisition/fetchRequisitionType")
        
        if (this.$route.params.project == 'new') {
            this.requisition.project = ''
        } else {
            this.requisition.project = this.$route.params.project
        }
    },

    methods: {
        updateItems(data) {
            if (data) {
                this.requisition.details = data;
            }
        },

        onSubmit() {
            this.isDisabled = true,
            this.assignedMembers.map(item => this.requisition.refering_to.push(item.id));

            this.$store.dispatch("requisition/addRequisition", this.requisition).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.isDisabled = false,
                    this.requisition.title = ''
                    this.assignedMembers = []
                    this.requisition.details = []
                    this.requisition.project = null
                    this.requisition.type = null
                    this.requisition.note = null
                    this.requisition.total = 0
                    this.requisition.grand_total = 0

                    setTimeout(() => this.$router.push('/finance-and-accounts/account/requisition/list'), 2000)

                } else {
                    this.isDisabled = false,
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            })
        },

        resetModal() {
            this.name = ''
            this.nameState = null
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            // Push the name to submitted names
            alert(JSON.stringify(this.client))

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        }
    }
};
</script>
