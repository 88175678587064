import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        compliance: [],
        compliance_withpg:[],
        compliance_withpg_count:null,
        form: [],
        responses: [],
        compliance_details: {},
        compliance_form_list:[],


        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_COMPLIANCE (state, item) {
            state.compliance = item;
        },
        SET_COMPLIANCE_withpg (state, {results,pg_count}) {
            state.compliance_withpg = results;
            state.compliance_withpg_count = pg_count;
        },

    
        SET_COMPLIANCE_FORM_LIST (state, item) {
            state.compliance_form_list = item;
        },

        SET_COMPLIANCE_DETAILS (state, item) {
            state.compliance_details = item;
        },

        SET_COMPLIANCE_FORM (state, item) {
            state.form = item;
        },

        SET_COMPLIANCE_FORM_RESPONSES (state, item) {
            state.responses = item;
        },

      

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateCompliance ({dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_compliance + payload.id, payload.compliance, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchCompliance');
            return response
        },

        async deleteCompliance ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_compliance + payload.item, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchCompliance_withpg',{perPage:payload.perPage, currentPage:payload.page});

            return response
        },

        async fetchComplianceDetails ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.compliance_details + payload, config);
                let response = result.data

                commit('SET_COMPLIANCE_DETAILS', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchCompliance ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_compliance, config);
                let response = result.data

                commit('SET_COMPLIANCE', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchCompliance_withpg ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_compliance_withpg+'?count='+payload.perPage+'&page='+payload.currentPage, config);
                const {results,pg_count} = result.data

                commit('SET_COMPLIANCE_withpg',  {results,pg_count});
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },




        async fetchComplianceFormList ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_compliance_form_list, config);
                let response = result.data

                commit('SET_COMPLIANCE_FORM_LIST', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        



        async addCompliance ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_compliance, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchCompliance')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addForm ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_compliance_form, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch ('fetchPolicyDetails', payload.compliance)
                dispatch ('fetchForm', payload.compliance)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchForm ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_compliance_form + payload, config);
                let response = result.data

                commit('SET_COMPLIANCE_FORM', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async submitForm ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.submit_compliance_form, {
                    form_data: payload.form_data,
                    submitted_form: payload.form
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchForm', payload.compliance)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchFormResponses ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_compliance_form_responses + payload, config);
                let response = result.data

                commit('SET_COMPLIANCE_FORM_RESPONSES', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async deleteComlianceFormResonse ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_compliance_form_response + payload.response_id, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchFormResponses',payload.compliance_id);

            return response
        },
    },

    getters: {}
}
