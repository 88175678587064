import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'GRIVENCE_UPDATE',
    smsDelete : 'GRIVENCE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        grievance: [],
        grievance_count:null,
        asset_detail: {},
        get_my_asset:[],
        grievance_detail:{},
        get_user_list:[],
        ticket_reply:[],


        asset_allocation_list:[],

        submitLoading: false,
        submitError: false,

        submitReplyLoading:false,
        submitReplyError:false,

        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_GRIEVANCE (state, {results,pg_count}) {
            state.grievance = results;
            state.grievance_count = pg_count;
        },
        SET_GRIEVANCE_DETAILS (state, grievance) {
            state.grievance_detail = grievance;
        },
      
        
        SET_ASSET_DETAIL (state, asset) {
            state.asset_detail = asset;
        },
        SET_TICKET_REPLY(state,reply){
            state.ticket_reply = reply;
        },
        

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_REPLY_LOADING (state, loading) {
            state.submitReplyLoading = loading;
        },

        SUBMIT_REPLY_ERROR (state, hasError) {
            state.submitReplyError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },
        SET_USER_LIST (state, asset) {
            state.get_user_list = asset;
        },

    },

    actions: {
        async addGrievance ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },    
            };
            try{
              
                let response = await axios.post(V1API.create_grievance, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchGrievance')
                return response

            }catch (e) {
                
                commit('SUBMIT_ERROR', true);
            }finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },

        
        async fetchGrievance ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_grievance+'?count='+payload.perPage+'&page='+payload.currentPage, config);
                const {results,pg_count} = result.data
              
                commit('SET_GRIEVANCE', {results,pg_count});

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },
        async fetchGrievanceDetails ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.grievance_details + payload, config);
                let response = result.data.data
              
                commit('SET_GRIEVANCE_DETAILS', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },



        
        async fetchGrievanceReply ({ commit }, payload) {
            commit('SUBMIT_REPLY_LOADING', true);
            commit('SUBMIT_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_replies_of_grievance + payload, config);
                let response = result.data
                commit('SET_TICKET_REPLY', response.data);

            } catch(error) {
                commit('SUBMIT_REPLY_ERROR', true);
            }
            commit('SUBMIT_REPLY_LOADING', false);
        },

        
        async grievanceAddRefer ({commit, dispatch}, payload) {
            commit('SUBMIT_REPLY_LOADING', true);
            commit('SUBMIT_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_refer_to_grievance + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });
                if(payload.all){
                    dispatch ('fetchGrievance',{perPage:payload.perPage, currentPage:payload.page})
                }
             
                if(payload.details){
                    dispatch ('fetchGrievanceDetails',payload.id)
                }
                
             
            
                return response

            }catch (e) {
                commit('SUBMIT_REPLY_ERROR', true);
            }finally {
                commit('SUBMIT_REPLY_LOADING', false);
            }
        },
        async grievanceDeleteRefer ({commit, dispatch}, payload) {
            commit('SUBMIT_REPLY_LOADING', true);
            commit('SUBMIT_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.delete_refer_to_grievance + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });
                if(payload.all){
                    dispatch ('fetchGrievance',{perPage:payload.perPage, currentPage:payload.page})
                }
                if(payload.details){
                    dispatch ('fetchGrievanceDetails',payload.id)
                }
             
            
                return response

            }catch (e) {
                commit('SUBMIT_REPLY_ERROR', true);
            }finally {
                commit('SUBMIT_REPLY_LOADING', false);
            }
        },
        async addGrievanceReply ({commit, dispatch}, payload) {
            commit('SUBMIT_REPLY_LOADING', true);
            commit('SUBMIT_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_grievance_reply, {

                    grievance: payload.grievance,
                    reply:payload.reply,
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch ('fetchGrievance')
              
                return response

            }catch (e) {
                commit('SUBMIT_REPLY_ERROR', true);
            }finally {
                commit('SUBMIT_REPLY_LOADING', false);
            }
        },


        async updateGrievance ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,

          

                async () => {
                    response= await axios.patch(V1API.update_grievance+payload.id,{subject:payload.subject, details:payload.details, date:payload.date, status:payload.status, refering_to:payload.refering_to},config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchGrievance');

                }
            )
            return response
        },
        async updateGrievanceStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.update_grievance_status + "/" + payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data

                })
                
                dispatch("fetchGrievance",{perPage:payload.perPage, currentPage:payload.page})
                dispatch("fetchGrievanceDetails",payload.id)
                return response
            } catch (e) {

                commit('SUBMIT_ERROR', true);
            } finally {

                commit('SUBMIT_LOADING', false);
            }
        },
    

        
        async deleteGrievance ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            let response = await axios.delete(V1API.delete_grievance + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchGrievance',{perPage:payload.perPage, currentPage:payload.page});

            return response
        },

        








    },

    getters: {}
}
