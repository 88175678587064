<template>
<div>
    <Breadcrumbs main="Procurement" title="RFP Responses" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div>
                            <h5>Response</h5>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div style="display: flex; flex-direction: column;">
                                        <div class="row">
                                            <div class="col-md-8">

                                                <div v-for="(responses,index) in responses_list" :key="responses.id">

                                                    <div class="accordion" role="tablist">
                                                        <b-card no-body class="mb-1">
                                                            <b-card-header header-tag="header" class="p-1" role="tab">
                                                                <b-button block v-b-toggle="'accordion-' + index" variant="primary">
                                                                    <div style="display: flex; flex-direction: column; gap: 5px;">

                                                                        <h7>{{ responses.submitted_form.form_name }}</h7>

                                                                    </div>
                                                                </b-button>
                                                            </b-card-header>
                                                            <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel" visible>
                                                                <b-card-body>

                                                                    <div style="display: flex; align-items: center; justify-content: flex-end;">
                                                                        <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(responses)" title="Reply"></feather>
                                                                    </div>

                                                                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                        <div>
                                                                            <div v-for="(info, index) in responses.form_data" :key="index">
                                                                                <div style="display: flex; gap: 16px; margin-bottom: 5px;">
                                                                                    <span>{{ info.data_label }}:</span>
                                                                                    <h6 v-if="info.data_type == 'filefield'">
                                                                                        <a :href="host + info.data_value" target="_blank" class="text-info">{{ host + info.data_value }}</a>
                                                                                    </h6>
                                                                                    <h6 v-else>{{ info.data_value }}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <h6 v-if="responses.label.length>0" class="mt-4">Labels</h6>
                                                                    <div style="display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); margin-top: 8px; gap: 8px;">
                                                                        <div v-for="label in responses.label" :key="label.id" style="width: 100%;">
                                                                            <span style="font-size: medium; width: 100%;">
                                                                                <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="dark">
                                                                                    <span style="width: 80%; padding: 4px; text-align: left;"> {{ label.name }} </span>
                                                                                    <div style="display: flex; justify-content: center; align-items: center;">
                                                                                        <feather type="tag" size="42px" style="color: #ffffff;"></feather>
                                                                                    </div>
                                                                                </b-badge>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this response?</div>
    </b-modal>

    <b-modal id="modal-center-add" centered title="Add Label" @ok="handleLabelOK">
        <div class="row">
            <div class="col-sm-12">

                <h6 v-if="addedLabels.length>0">Already Added</h6>
                <div class="row">
                    <div class="text-center col-sm-6 w-full" v-for="label in addedLabels" :key="label.id" v-b-tooltip.hover @click="deleteAssignedLabel(label.id)" title="Delete">
                        <div class="w-100 mb-2 badge-style">
                            <h7>{{ label.name}}</h7>
                            <b-icon icon="x" style="color: white;"></b-icon>
                        </div>
                    </div>
                </div>

                <div class="form-group mt-2">
                    <label>Label</label>
                    <select class="form-control digits" v-model="label_id">
                        <option v-for="(type, index) in labels" :key="index" :value="type.id">{{ type.name }}</option>
                    </select>
                </div>
            </div>
        </div>
    </b-modal>

    <!-- Reply Modal -->
    <b-modal id="modal-scrollable" ref="modal" title="Submission Reply" hide-footer>

        <div v-if="rfp_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in rfp_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name" v-if="reply.replier_photo">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                            <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleReplySubmit()">
                <button @click="handleReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    myHost
} from "../../../../_helpers/host";
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data() {
        return {
            host: myHost.host,
            delete_value: '',

            response_id: '',
            label_id: null,
            addedLabels: [],
            selectedLabel: null,

            my_reply: {
                id: '',
                reply: '',

            },
        }
    },

    computed: {
        ...mapState({
            responses_list: state => state.rfp.rfp_form_response,
            rfp_reply: state => state.rfp.rfp_response_reply,

        }),

    },

    mounted() {
        this.$store.dispatch("rfp/fetchRFPResponseByForm", this.$route.params.id)

    },

    watch: {
        responses(newValue, oldValue) {}
    },
    methods: {
        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },
        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.$store.dispatch("rfp/fetchRFPResponseReply", this.my_reply.id)
        },

        handleReplySubmit() {
            this.$store.dispatch("rfp/addRFPResponseReply", {
                submission: this.my_reply.id,
                reply: this.my_reply.reply,
             
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                       
                        this.my_reply.reply = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

           
        },

    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}


/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
